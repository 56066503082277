<template>
    <div class="p-3 bs-4">
        <h4 class="mb-3">Qualification Info</h4>
        <div class="row">
            <div class="col-4">
                <label>Professional Qualification</label>
                <div class="form-control round-1 text-muted">{{ data.professional_qualifications.name }}</div>
            </div>
        </div>
        <h4 class="mt-3">Docs Attached</h4>
        <simple-table class="c-table" :data="attachments" :fields="fields" ref="table">
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <btn size="xs" text="View" @click="viewFile(rowData.file)"/>
                </div>
            </template>
        </simple-table>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../../../../data/urls';

export default {
    name: 'StaffQualificationInfo',
    props: { data: { type: Object } },
    data () {
        return {
            attachments: [],
            fields: [
                {
                    name: 'id',
                    sortField: 'id',
                    title: 'ID'
                }, {
                    name: 'name',
                    sortField: 'name',
                    title: 'Name'
                },
                {
                    name: '__slot:actions',
                    title: '',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right'
                }
            ]
        };
    },
    mounted () {
        this.loadAttachments();
    },
    methods: {
        viewFile (item) {
            window.open('/upload/app/' + item);
        },
        loadAttachments () {
            const that = this;
            axios.form(urls.admin.directorFunction.staff.attachment.list, { user: that.data.id }).then(function (response) {
                that.attachments = response.data.data;
            });
        }
    }
};
</script>
