<template>
    <main>
        <div class="container">
            <div class="row py-5 px-0">
                <div class="col-12 col-lg-8">
                    <h2 class="text-uppercase poppins-bold mb-4">Policies</h2>
                    <article class="mb-5">
                        Here you can download REACHE NW’s policies for members. These policies set out what we
                        expect from members and what
                        happens if a member does not follow a policy (the Performance Improvement Procedure).
                        <br><br>
                        Attendance: <a target="_blank"
                                       href="https://reache.xeoscript.com/pdf/reache-membership-attendance-policy-issued-version-4-aug-2018.doc"
                                       class="text-danger" download>Reache Membership Attendance
                        Policy Issued
                        Version 4 Aug 2018</a>
                        <br><br>
                        Volunteering: <a target="_blank" href="https://reache.xeoscript.com/pdf/volunteering-policy-3.docx"
                                         class="text-danger" download>Volunteering policy (3)</a>
                        <br><br>
                        Study Leave: <a target="_blank" href="https://reache.xeoscript.com/pdf/study-leave-policy.docx" class="text-danger" download>Study Leave Policy; Study Leave
                        Request Form</a>
                        <br><br><br>
                        Performance Improvement Procedure: <a target="_blank" href="https://reache.xeoscript.com/pdf/reache-north-west-performance-improvement-procedure-student-summary-july-2018.docx" class="text-danger" download>REACHE
                        North West Performance Improvement Procedure
                        Student Summary July 2018 </a>
                        <br><br>
                        If you have any questions about these policies, please make an enquiry for an appointment
                        with a tutor.

                    </article>
                </div>
                <div class="col-12 col-lg-4">
                    <location-and-info></location-and-info>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import LocationAndInfo from './components/support-pages/LocationAndInfo.vue';
// import JoiningForm from './components/support-pages/JoiningForm.vue';

export default {
    components: {
        LocationAndInfo
    }
};
</script>
