<template>
    <div class="p-3 mt-0 w-70r ml-4 bs-4">
        <div class="fl-te-c mb-3">
            <h4 class="mb-3">Basic Info</h4>
            <btn size="sm" text="Back" @click="$router.push('/director-function/user-management/')"/>
        </div>
        <div class="row">
            <div class="col">
                <label>Name</label>
                <div class="form-control round-1 text-muted">{{ data.name }}</div>
            </div>
            <div class="col">
                <label>E-Mail</label>
                <div class="form-control round-1 text-muted">{{ data.email }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>DOB</label>
                <div class="form-control round-1 text-muted">{{ data.date_of_birth }}</div>
            </div>
            <div class="col">
                <label>Address</label>
                <div class="form-control round-1 text-muted">{{ data.name }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>Country</label>
                <div class="form-control round-1 text-muted">{{ data.country_of_origin.name }}</div>
            </div>
            <div class="col">
                <label>Role</label>
                <div class="form-control round-1 text-muted" v-if="data.role===1">Admin</div>
                <div class="form-control round-1 text-muted" v-if="data.role===2">Staff</div>
                <div class="form-control round-1 text-muted" v-if="data.role===3">Managerial Staff</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>Gender</label>
                <div class="form-control round-1 text-muted">{{ data.gender }}</div>
            </div>
            <div class="col">
                <label>Age</label>
                <div class="form-control round-1 text-muted">{{ data.age }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <label>Phone</label>
                <div class="form-control round-1 text-muted">{{ data.phone }}</div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'UserBasicInfo',
    props: { data: { type: Object } }
};
</script>

<style scoped>

</style>
