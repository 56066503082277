<template>
    <div class="container py-6">
        <section class="row">
            <div class="col-lg-8 col-12 content-block">
                <div class="header">
                    <slot name="header"></slot>
                </div>
                <div class="text-body">
                    <slot name="body"></slot>
                </div>
            </div>
            <div class="col-lg-4 col-12 media-item fl-x fl-j-c fl-a-c">
                <slot name="media"></slot>
            </div>
        </section>
    </div>

</template>
<script>

export default {};

</script>

<style scoped lang="scss">
.header, .media-item {
    /*text-align: left;*/
}

.header {
    font-family: 'poppins-semibold', serif;

    h2 {
        font-size: 1.7rem;
    }
}

iframe {
    width: 100%;
    height: 350px;
}

@media (min-width: 992px) {
    iframe {
        width: 100%;
        height: 100%;
    }
}

</style>
