<template>
    <div class="p-3 bs-4">
        <h4 class="mb-3">Qualification Info</h4>
        <div class="row">
            <div class="col">
                <label>Country of primary medical qualification</label>
                <div class="form-control round-1 text-muted">{{ details.primary_medical_qualification_country_id.name }}</div>
            </div>
            <div class="col">
                <label>Professional Qualification</label>
                <div class="form-control round-1 text-muted">{{ details.professional_qualifications.name }}</div>
            </div>
            <div class="col">
                <label>Additional Educational Needs</label>
                <div class="form-control round-1 text-muted">{{ details.additional_educational_needs }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>Disability</label>
                <div v-if="details.disability===0" class="form-control round-1 text-muted">Yes</div>
                <div v-else class="form-control round-1 text-muted">No</div>
            </div>
            <div class="col">
                <label>Ethnicity</label>
                <div v-if="details.ethnicity===0" class="form-control round-1 text-muted">Yes</div>
                <div v-else class="form-control round-1 text-muted">No</div>
            </div>
            <div class="col">
                <label>Caring Responsibility</label>
                <div class="form-control round-1 text-muted">{{ details.caring_responsibilities }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: { details: { type: Object } },
    name: 'QualificationInfo'
};
</script>
