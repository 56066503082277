<template>
    <loading-animation v-if="loading1"/>
    <div v-else>
        <div class="fl-eq mb-4">
            <div class="card w-25r bs-4">
                <h4 class="mb-3">Profile</h4>
                <div style="min-height: 23rem; background-position: center center; background-size:cover;"
                     :style="image">
                </div>
                <label>Name</label>
                <div class="form-control round-1 text-muted">{{ details.name }}</div>
                <label>Email ID</label>
                <div class="form-control round-1 text-muted">{{ details.email }}</div>
            </div>
            <basic-info :details="details"/>
        </div>
        <qualification-info :details="details" class="mb-4"/>
        <reache-info v-if="details.is_approved===1 && details.is_declined===0" :details="details" class="mb-4"/>
        <s-form @submit="setModalValue" v-if="details.is_approved===0 && details.is_declined===0" ref="form">
            <reach-info-form ref="reacheForm" class="bs-4 p-3 mb-4"/>
            <div class="card mt-3 p-4 bs-4">
                <h4 class="mb-3">Class Allotment</h4>
                <div class="row">
                    <div class="col-4">
                        <validated-ajax-vue-select label="Batch" name="Batch" v-model="model.batch"
                                                   :rules="{required : true}" :url="batchOptions"
                                                   :disabled="loading"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 btn-group">
                        <btn size="sm" class="btn-basic-b" text="Accept"
                             :loading="loading" loading-text="Processing.."/>
                        <btn type="button" color="danger" size="sm" class="btn-basic-b" @click="declineUser" text="Decline"
                             :loading="loading" loading-text="Processing.."/>
                    </div>
                </div>
            </div>
        </s-form>
    </div>
</template>

<script>
import BasicInfo from './Components/ProfileDetailsComponent/BasicInfo';
import QualificationInfo from './Components/ProfileDetailsComponent/QualificationInfo';
import ReacheInfo from './Components/ProfileDetailsComponent/ReacheInfo';
import axios from 'secure-axios';
import urls from '../../../../data/urls';
import ReachInfoForm from './Components/userFormComponents/ReachInfo';

export default {
    name: 'DetailsComponent',
    components: { ReachInfoForm, ReacheInfo, QualificationInfo, BasicInfo },
    computed: {
        image () {
            return { backgroundImage: 'url(/upload/app/' + this.details.profile_image + ')' };
        }
    },
    data () {
        return {
            id: this.$route.params.id,
            loading: false,
            loading1: false,
            batchOptions: urls.vueSelect.batchOptions,
            details: '',
            model: {}
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods: {
        setModalValue () {
            const model1 = this.$refs.reacheForm.getModalValues();
            this.model = { ...this.model, ...model1 };
            this.approveUser();
        },
        async loadDetails () {
            const that = this;
            if (!that.id) {
                that.id = that.currentUser.id;
            }
            that.loading1 = true;
            const response = await axios.form(urls.admin.enrollmentFunction.pendingMembers.details, { id: that.id });
            if (response.data.error === false) {
                that.details = response.data.object;
                that.loading1 = false;
            }
            that.loading1 = false;
        },
        async approveUser () {
            const that = this;
            that.loading = true;
            that.model.id = that.id;
            that.model.is_approved = '1';
            const response = await axios.form(urls.member.accept, that.model);
            const json = response.data;
            if (json.error === false) {
                that.loading = false;
                that.formSuccess();
            } else {
                that.formError(json);
                that.loading = false;
            }
            that.loading = false;
        },
        async declineUser () {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.member.decline, { user_id: that.id });
            const json = response.data;
            if (json.success === true) {
                that.loading = false;
                that.declineSuccess();
            } else {
                that.declineFormError(json);
                that.loading = false;
            }
            that.loading = false;
        },
        declineSuccess () {
            this.$notify('User Declined Successfully', 'Success', {
                type: 'success'
            });
            this.$router.push('/enrollment-function/pending-member-management/');
        },
        declineFormError () {
            this.$notify('Something went wrong, Please try again later.', 'Error', {
                type: 'danger'
            });
        },
        formSuccess () {
            this.$notify('User Approved Successfully', 'Success', {
                type: 'success'
            });
            this.loadDetails();
        },
        formError (json) {
            this.loading = false;
            this.$refs.form.setErrors(json.errors);
            this.$notify('Please fix the issue.', 'Error', {
                type: 'danger'
            });
        }

    }
};
</script>

<style scoped>

</style>
