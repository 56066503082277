<template>
    <main>
        <div class="container">
            <div class="row py-5 px-0">
                <div class="col-12 col-lg-8">
                    <h2 class="text-uppercase poppins-bold mb-4">PLAB 1</h2>
                    <article class="mb-5">
                        The links below take you to a list of resources that may be useful in preparation for PLAB1.
                        This list was collated in January 2014 by REACHE North West members and staff and while people
                        have reported them as useful, not all the resources can be verified as reliable.
                        <br>
                        <ul class="pl-5 py-3 list-unstyled">
                            <li><a target="_blank" href="https://reache.xeoscript.com/pdf/plab1-resources.pdf"
                                   class="text-danger mr-3" download>PLAB1 Resources (PDF File) </a>
                                <a href="https://reache.xeoscript.com/pdf/plab1-resources.doc" target="_blank"
                                   class="text-danger" download>PLAB1
                                    Resources (Word Document)</a>
                            </li>
                        </ul>
                        For REACHE NW members, there is access to specially-developed Safe Prescribing online modules:
                        <a target="_blank" href="https://reache3.safeprescriber.org/login" class="text-danger">https://reache3.safeprescriber.org/login</a>
                        <br><br>
                        Here is some information about the PLAB1 with some examination tips – as always these things can
                        be personal so please develop your own strategies for success!
                        <ul class="pl-5 py-3  list-unstyled">
                            <li><a target="_blank"
                                   href="https://reache.xeoscript.com/pdf/plab1-examination-tips-2014.doc"
                                   class="text-danger mr-3" download>PLAB1 examination tips 2014</a>
                            </li>
                        </ul>
                        Below are some FREE, reputable learning resources. You will need to register in order to use
                        them, but each has a selection of good modules. You will need your BMA registration number to
                        access the BMJ Learning.
                        <br><br>
                        Medscape is a US web site. It has lots of good modules, but remember to check a site such as
                        <a target="_blank" href="https://www.nhs.uk/"  class="text-danger">NHS Choices</a> ,
                        <a href="https://pathways.nice.org.uk/" target="_blank">NICE</a> or
                        <a href="https://patient.info/" class="text-danger" target="_blank">Patient.co.uk</a> to see the UK specific guidelines for any topic you study.
                    </article>

                    <article class="mb-5">
                        <a target="_blank" href="https://www.medscape.org/">
                            <div class="lg-img-contain fl-y fl-j-c fl-a-s py-3">
                                <img src="../assets/img/medscape.jpg" alt="Medscape website" class="mb-3">
                                <span class="text-center">Medscape</span>
                            </div>
                        </a>
                        <a target="_blank" href="https://new-learning.bmj.com/">
                            <div class="lg-img-contain fl-y fl-j-c fl-a-s py-3">
                                <img src="../assets/img/bmj-learning.jpg" alt="BMJ Learning website website"
                                     class="mb-3">
                                <span class="text-center">BMJ Learning</span>
                            </div>
                        </a>

                    </article>
                    <article class="mb-5">
                        <div class="row">
                            <div class="col-12"><span class="poppins-semibold">Test your awareness</span> (YouTube
                                video): <br><br>
                            </div>
                            <div class="col-12">
                                <iframe src="https://www.youtube.com/embed/Ahg6qcgoay4"
                                        title="YouTube video player" frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen></iframe>
                            </div>
                            <div class="col-12">
                                <p>
                                    This is a reminder about the importance of examination technique for Single Best
                                    Answer
                                    examination questions! Don’t jump straight to the answer you think is correct
                                    without checking
                                    that it answers the question directly.
                                </p>
                            </div>
                        </div>

                    </article>

                    <share-this></share-this>
                </div>
                <div class="col-12 col-lg-4">
                    <location-and-info></location-and-info>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import LocationAndInfo from './components/support-pages/LocationAndInfo.vue';
// import JoiningForm from './components/support-pages/JoiningForm.vue';

export default {
    components: {
        LocationAndInfo
    }
};
</script>
<style lang="scss" scoped>
.word-icon-img {
    width: 60px;
}
iframe {
    width: 100%;
    height: 210px;
}

@media (min-width: 992px) {
    iframe {
        height: 380px;
    }
}
</style>
