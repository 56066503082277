<template>
    <div>
        <AdminHome/>
        <users-listing-component class="bs-4"/>
    </div>
</template>

<script>
import UsersListingComponent from './Components/UserListingComponent';
import AdminHome from '../../../Dashboards/CustomHomePages/AdminHome';

export default {
    name: 'UsersListingComponentViewPage',
    components: { AdminHome, UsersListingComponent }
};
</script>

<style scoped>

</style>
