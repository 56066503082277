<template>
    <div class="p-3 bs-4">
        <h4 class="mb-3">Reache Info</h4>
        <div class="row">
            <div class="col">
                <label>Time since arrival to UK and joining REACHE</label>
                <div class="form-control round-1 text-muted">{{ details.time_since_arrival_to_uk_and_joining_reache }}</div>
            </div>
            <div class="col">
                <label>Time from date of last practice to joining REACHE</label>
                <div class="form-control round-1 text-muted">{{ details.last_practice_duration_to_join_reache }}</div>
            </div>
            <div class="col">
                <label>Healthcare Profession</label>
                <div class="form-control round-1 text-muted">{{ details.health_care_profession }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>Breaks in professional practice</label>
                <div class="form-control round-1 text-muted">{{ details.breaks_in_professional_practice }}</div>
            </div>
            <div class="col">
                <label>How did you hear about REACHE</label>
                <div class="form-control round-1 text-muted">{{ details.hear_about_reach }}</div>
            </div>
            <div class="col">
                <label>Date of application to REACHE</label>
                <div class="form-control round-1 text-muted">{{ details.application_date_to_reache }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>Date of joining REACHE</label>
                <div class="form-control round-1 text-muted">{{ details.date_of_joining_reache }}</div>
            </div>
            <div class="col">
                <label>Date of induction to REACHE</label>
                <div class="form-control round-1 text-muted">{{ details.date_of_induction_to_reache }}</div>
            </div>
            <div class="col">
                <label>Jobs </label>
                <div v-if="details.jobs===0" class="form-control round-1 text-muted">Yes</div>
                <div v-else class="form-control round-1 text-muted">No</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>English level on entry</label>
                <div class="form-control round-1 text-muted">{{ details.english_level_on_entry }}</div>
            </div>
            <div class="col">
                <label>Date of GMC registration</label>
                <div class="form-control round-1 text-muted">{{ details.date_of_gmc_registration }}</div>
            </div>
            <div class="col">
                <label>Exit professional destination</label>
                <div class="form-control round-1 text-muted">{{ details.exit_professional_destination }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>Time taken to pass OET</label>
                <div class="form-control round-1 text-muted">{{ details.time_taken_to_pass_oet }}</div>
            </div>
            <div class="col">
                <label>Named REACHE English tutor</label>
                <div v-if="details.named_reache_english_tutor===0" class="form-control round-1 text-muted">Yes</div>
                <div v-else class="form-control round-1 text-muted">No</div>
            </div>
            <div class="col">
                <label>OET pass</label>
                <div v-if="details.oet_pass===0" class="form-control round-1 text-muted">Yes</div>
                <div v-else class="form-control round-1 text-muted">No</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>PLAB 1 pass</label>
                <div v-if="details.plab_1_pass===0" class="form-control round-1 text-muted">Yes</div>
                <div v-else class="form-control round-1 text-muted">No</div>
            </div>
            <div class="col">
                <label>Named REACHE Clinical supervisor</label>
                <div v-if="details.named_reache_clinical_supervisor===0" class="form-control round-1 text-muted">Yes</div>
                <div v-else class="form-control round-1 text-muted">No</div>
            </div>
            <div class="col">
                <label>Time taken to pass PLAB 1 with REACHE</label>
                <div class="form-control round-1 text-muted"> {{ details.time_taken_to_pass_plab_1_with_reache }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>Time taken to pass PLAB 2 with REACHE</label>
                <div class="form-control round-1 text-muted"> {{ details.time_take_to_pass_plab2_with_reache }}</div>
            </div>
            <div class="col">
                <label>PLAB 2</label>
                <div v-if="details.plab_2===0" class="form-control round-1 text-muted">Yes</div>
                <div v-else class="form-control round-1 text-muted">No</div>
            </div>
            <div class="col">
                <label>CPT ward</label>
                <div class="form-control round-1 text-muted">{{ details.cpt_ward }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>CPT named supervisor</label>
                <div v-if="details.cpt_named_supervisor===0" class="form-control round-1 text-muted">Yes</div>
                <div v-else class="form-control round-1 text-muted">No</div>
            </div>
            <div class="col">
                <label>CPT report completed by supervisor</label>
                <div class="form-control round-1 text-muted">{{ details.cpt_report_completed_by_supervisor }}</div>
            </div>
            <div class="col">
                <label>GMC registration achieved</label>
                <div v-if="details.gmc_registration_achieved===0" class="form-control round-1 text-muted">Yes</div>
                <div v-else class="form-control round-1 text-muted">No</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>Cause for concern</label>
                <div class="form-control round-1 text-muted">{{ details.cause_for_concern }}</div>
            </div>
            <div class="col">
                <label>Early exit</label>
                <div class="form-control round-1 text-muted">{{ details.early_exit }}</div>
            </div>
            <div class="col">
                <label>Exit interview completed</label>
                <div v-if="details.exit_interview_completed===0" class="form-control round-1 text-muted">Yes</div>
                <div v-else class="form-control round-1 text-muted">No</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>Name of person who did exit interview</label>
                <div class="form-control round-1 text-muted">{{ details.name_of_person_who_did_exit_interview }}</div>
            </div>
            <div class="col">
                <label>Performance review summaries</label>
                <div class="form-control round-1 text-muted">{{ details.performance_review_summaries }}</div>
            </div>
            <div class="col">
                <label>Dates of closed membership</label>
                <div class="form-control round-1 text-muted">{{ details.dates_of_closed_membership }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>Stage of training when membership closure occurred</label>
                <div class="form-control round-1 text-muted">{{ details.stage_of_training_when_membership_closure_occurred }}</div>
            </div>
            <div class="col">
                <label>Reason for membership closure/s</label>
                <div class="form-control round-1 text-muted">{{ details.reason_for_membership_closure }}</div>
            </div>
            <div class="col">
                <label>Batch</label>
                <div class="form-control round-1 text-muted">{{ details.batch.name }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: { details: { type: Object } },
    name: 'ReacheInfo'
};
</script>
