<template>
    <div>
        <div class="fl-te-c">
            <h5 class="mt-4 mb-4">Attachments</h5>
            <btn text="Add" type="button" @click="showAttachments" icon="fa fa-plus" size="sm"/>
        </div>
        <simple-table class="c-table" :data="attachments" :fields="fields" ref="table">
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <btn size="xs" text="View" @click="viewFile(rowData.file)"/>
                </div>
            </template>
        </simple-table>
        <modal no-close-on-backdrop class="c-modal no-close" title="Add Attachments" ref="addAttachmentsModal" width="30r" header-color="">
            <s-form @submit="submitAttachments" ref="form">
                <validated-input label="Name" name="Name" v-model="model.name"
                                 :rules="{required : true}"
                                 :disabled="loading"/>
                <validated-file-input label="File" name="File" v-model="model.file"
                                 :disabled="loading"/>

                    <div class="fl-x-bc mt-5 mb-3">
                        <btn size="sm" text="Save" :loading="loading" loading-text="Saving.."/>
                        <btn size="sm" class="ml-2 btn-basic-b" @click="$refs.addModal.close()" text="Cancel" type="button"/>
                    </div>

            </s-form>
        </modal>

        <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteSuccess">
            <p>You are about to delete the file. Are you sure?</p>
            <template #loading>
                <delete-animation/>
                Please wait while we delete the file.
            </template>
        </delete-modal>
    </div>
</template>

<script>

import axios from 'secure-axios';
import urls from '../../../../../../data/urls';

export default {
    name: 'QualificationAttachmentForm',
    props: { user: { type: String } },
    data () {
        return {
            attachments: '',
            loading: false,
            model: {},
            deleteUrl: urls.admin.directorFunction.staff.attachment.delete,
            deletingItem: {
                id: ''
            },
            fields: [
                {
                    name: 'id',
                    sortField: 'id',
                    title: 'ID'
                }, {
                    name: 'name',
                    sortField: 'name',
                    title: 'Name'
                },
                {
                    name: '__slot:actions',
                    title: '',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right'
                }
            ]
        };
    },
    methods: {
        viewFile (item) {
            window.open('/upload/app/' + item);
        },
        showAttachments () {
            this.$refs.addAttachmentsModal.show();
            this.loading = false;
            this.model = {};
        },
        formSuccess () {
            this.$refs.addAttachmentsModal.close();
            this.$notify('Added Successfully', 'Success', {
                type: 'success'
            });
            this.loadAttachments();
        },
        formError (json) {
            this.$refs.form.setErrors(json.errors);
            this.$notify('Please fix the issue.', 'Error', {
                type: 'danger'
            });
        },
        async submitAttachments () {
            const that = this;
            that.loading = true;
            that.model.user = that.user;
            const response = await axios.form(urls.admin.directorFunction.staff.attachment.add, that.model);
            const json = response.data;
            if (json.error === false) {
                that.loading = false;
                that.formSuccess();
            } else {
                that.loading = false;
                that.formError(json);
            }
            that.loading = false;
        },
        loadAttachments () {
            const that = this;
            axios.form(urls.admin.directorFunction.staff.attachment.list, { user: that.user }).then(function (response) {
                that.attachments = response.data.data;
            });
        },
        setDelete (item) {
            this.deletingItem.id = item.id;
            this.$refs.deleteModal.show();
        },
        deleteSuccess () {
            this.$refs.deleteModal.close();
            this.deletingItem.id = '';
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.loadAttachments();
        }
    }
};
</script>

<style>
.full-page {
    height: 100vh;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.image {
    background: url("../../../../../../assets/img/banner-blurred.png");
}

.link {
    cursor: pointer;
}
</style>
