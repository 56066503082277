<template>
    <main>
        <div class="container">
            <div class="row py-5 px-0">
                <div class="col-12 col-lg-8">
                    <h2 class="text-uppercase poppins-bold mb-4">PERSONAL WELLBEING</h2>
                    <article class="mb-3 mb-lg-5 bb-1 b-dark">
                        Free psychological support in Greater Manchester: <a href="https://www.selfhelpservices.org.uk/"
                                                                             class="text-danger">https://www.selfhelpservices.org.uk/</a>
                        <br><br>
                        <a href="https://www.selfhelpservices.org.uk/">
                            <div class="lg-img-contain  py-3">
                                <img src="../assets/img/selfhelp.jpg" alt="Mental Health support in Greater Manchester"
                                     class="mb-2">
                                <div>Mental Health support in Greater Manchester
                                </div>
                            </div>
                        </a>

                    </article>
                    <article class="mb-3 mb-lg-5 bb-1 b-dark">
                        You may some phone apps helpful. Salford Royal and ORCHA assess the quality of health apps:
                        <a href="https://salfordccg.orcha.co.uk/" target="_blank" class="text-danger">An App A Day</a>
                        <br><br>
                        <div class="lg-img-contain-big fl-x fl-j-s py-3">
                            <img src="../assets/img/apps.jpg" alt=""
                                 class="mb-2">
                        </div>

                    </article>
                    <article class="mb-3 mb-lg-5 bb-1 b-dark">
                        You can self-refer to some organisations for counselling and psychology therapy for depression
                        and anxiety. One example is the <a target="_blank"
                                                           href="https://www.thebiglifegroup.com/service/zion-centre/"
                                                           class="text-danger">Zion Centre in south/central
                        Manchester</a> where you can put your
                        name down for therapy without a referral from the GP. <br><br>

                        The Zion Centre also has an activity programme ( <a
                        href="https://reache.xeoscript.com/pdf/new-zion-and-klc-wgog-oct-2014.docx" class="text-danger"
                        download>Zion Centre
                        What’s On Guide</a>) and you can find
                        other services through the <a target="_blank"
                                                      href="https://www.nhs.uk/service-search/other-services/"
                                                      class="text-danger">NHS web site</a>.

                        Lots of self-help leaflets: <a target="_blank" href="https://www.ntw.nhs.uk/pic/selfhelp/"
                                                       class="text-danger">http://www.ntw.nhs.uk/pic/selfhelp/</a>

                        <div class="lg-img-contain  py-3">
                            <a href="https://www.ntw.nhs.uk/pic/selfhelp/" target="_blank">
                                <img src="../assets/img/ntw-snap.jpg" alt="Mental Health support in Greater Manchester"
                                     class="mb-2">
                            </a>

                            <div>ntw.nhs.uk self-help leaflets
                            </div>
                        </div>

                    </article>
                    <article class="mb-3 mb-lg-5 bb-1 b-dark">
                        Elefriends – a supportive online community for people with mental health or mood issues:
                        <div class="lg-img-contain  py-3">
                            <a href="https://sidebyside.mind.org.uk/" target="_blank"> <img
                                src="../assets/img/elefriends.jpg" alt="Mental Health support in Greater Manchester"
                                class="mb-2"></a>

                            <div>A supportive online community</div>
                        </div>
                    </article>
                    <article class="mb-3 mb-lg-5 bb-1 b-dark">
                        <a target="_blank"
                           href="https://www.lpmde.ac.uk/professional-development/elearning-support-and-self-review-modules/stress-mental-health-and-wellbeing-in-the-workplace"
                           class="text-danger pb-3">
                            Stress, Mental Health and Wellbeing in the Workplace
                        </a>
                        <br>
                        <a target="_blank"
                           href="https://www.lpmde.ac.uk/professional-development/elearning-support-and-self-review-modules/stress-mental-health-and-wellbeing-in-the-workplace"
                           class="text-danger">
                            <div class="lg-img-contain">
                                <a href="https://www.lpmde.ac.uk/professional-development/elearning-support-and-self-review-modules/stress-mental-health-and-wellbeing-in-the-workplace"
                                   target="_blank"><img
                                    src="../assets/img/stress-lon-dean.jpg" alt=""
                                    class="mb-2"></a>

                            </div>
                        </a>
                        <div class="text-left">
                            London Deanery video about stress in the workplace
                        </div>
                    </article>
                    <article class="mb-3 mb-lg-5 bb-1 b-dark">
                        Some websites with general wellbeing advice that may be helpful: <br><br>

                        <a href="http://www.mind.org.uk/information-support/tips-for-everyday-living/"
                           target="_blank" class="text-danger">http://www.mind.org.uk/information-support/tips-for-everyday-living/</a>
                        <br><br>
                        <a href="http://www.mind.org.uk/workplace/mental-health-at-work/taking-care-of-yourself/five-ways-to-wellbeing/"
                           target="_blank" class="text-danger">http://www.mind.org.uk/workplace/mental-health-at-work/taking-care-of-yourself/five-ways-to-wellbeing/</a>
                        <br><br>
                        <a href="http://www.nhs.uk/Conditions/stress-anxiety-depression/Pages/improve-mental-wellbeing.aspx"
                           target="_blank" class="text-danger">http://www.nhs.uk/Conditions/stress-anxiety-depression/Pages/improve-mental-wellbeing.aspx</a>
                        <br><br>
                        <a href="http://www.mentalhealth.org.uk/help-information/10-ways-to-look-after-your-mental-health/"
                           target="_blank" class="text-danger">http://www.mentalhealth.org.uk/help-information/10-ways-to-look-after-your-mental-health/</a>
                        <br><br>
                    </article>
                    <article class="mb-3 mb-lg-5">
                        <a target="_blank"
                           href="https://www.dropbox.com/sh/2ipbayvoj99e5je/AACFBXkedWlZloEPgqce4yxaa?dl=0"
                           class="text-danger">A series of mindfulness meditation soundtracks</a> and a list of
                        <a href="https://reache.xeoscript.com/pdf/mindfulness-info.docx" target="_blank"
                           class="text-danger">
                            mindfulness information resources
                        </a>
                        provided by Dr Louise Barber, clinical psychologist at Salford Royal NHS Foundation Trust.
                    </article>
                    <share-this></share-this>
                </div>
                <div class="col-12 col-lg-4">
                    <location-and-info></location-and-info>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import LocationAndInfo from './components/support-pages/LocationAndInfo.vue';

export default {
    components: {
        LocationAndInfo
    }
};
</script>
<style lang="scss" scoped>
//article::after {
//    content: '';
//    display: inline-block;
//    position: relative;
//    border-top: .1rem solid rgba(0, 0, 0, 0.26);
//    width: 90%;
//    margin-top: 2.5rem;
//    transform: translateY(-1rem);
//}

</style>
