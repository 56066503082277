<template>
    <custom-vue-table class="bs-4 m-3 c-table" title="Teaching Links" :fields="fields" :url="listUrl" :per-page="10"
                      ref="table">
        <template #buttons>
            <btn icon="fa fa-plus" size="sm" @click="showAddModal" text="Add"/>
        </template>
        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <btn size="xs" color="danger" text="Delete" @click="setDelete(rowData)"/>
                <btn size="xs" text="View" @click="viewDetails(rowData)"/>
            </div>
        </template>

        <template #footer>
            <modal class="c-modal no-close" title="Add Teaching Link" ref="addModal" width="40r" header-color="">
                <s-form @submit="submitClicked" ref="form">
                    <div class="row">
                        <div class="col">
                            <validated-vue-select label="Branch" name="Branch" v-model="model.branch"
                                                  :rules="{required : true}" :options="branchOptions"
                                                  :disabled="loading"/>
                        </div>
                        <div class="col">
                            <validated-ajax-vue-select label="Batch" name="Batch" v-model="model.batch"
                                                       :rules="{required : true}" :url="batchOptions"
                                                       :disabled="loading"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <validated-input label="Title" name="Title" v-model="model.title"
                                             :rules="{required : true}" :disabled="loading"/>
                        </div>
                        <div class="col">
                            <validated-input label="Teaching Link" name="Teaching Link" v-model="model.teaching_link"
                                             :rules="{required : true}" :disabled="loading"/>
                        </div>
                    </div>
                    <btn size="sm" text="Submit" :loading="loading" loading-text="Submitting.."/>
                </s-form>
            </modal>

            <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteSuccess">
                <p>You are about to delete the link. Are you sure?</p>
                <template #loading>
                    <delete-animation/>
                    Please wait while we delete the link.
                </template>
            </delete-modal>
        </template>

    </custom-vue-table>
</template>

<script>
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name: 'TeachingLinksListingComponent',
    data () {
        return {
            branchOptions: [
                { value: 'Clinical', label: 'Clinical' },
                { value: 'OET', label: 'OET' },
                { value: 'Volunteer', label: 'Volunteer' }
            ],
            batchOptions: urls.vueSelect.batchOptions,
            loading: false,
            listUrl: urls.staff.teachingLinks.list,
            deleteUrl: urls.staff.teachingLinks.delete,
            deletingItem: {
                id: ''
            },
            fields: [
                {
                    name: 'id',
                    sortField: 'id',
                    title: 'ID'
                }, {
                    name: 'title',
                    sortField: 'title',
                    title: 'Title'
                },
                {
                    name: 'branch',
                    sortField: 'branch',
                    title: 'Branch'
                },
                {
                    name: 'batch',
                    sortField: 'batch',
                    title: 'Batch'
                },
                {
                    name: 'created_date',
                    sortField: 'created_date',
                    title: 'Created Date'
                },
                {
                    name: '__slot:actions',
                    title: '',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right'
                }
            ],
            model: {
                name: ''
            }
        };
    },
    methods: {
        viewDetails (item) {
            // this.$router.push('/admin/policy/' + item.id + '/details/');
            window.open(item.teaching_link);
        },
        async submitClicked () {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.staff.teachingLinks.add, that.model);
            const json = response.data;
            if (json.error === false) {
                that.formSuccess();
                that.loading = false;
            } else {
                that.formError(json);
                that.loading = false;
            }
            that.loading = false;
        },
        formSuccess () {
            this.$refs.addModal.close();
            this.$notify('Saved Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        formError (json) {
            this.$refs.form.setErrors(json.errors);
            this.$notify('Please fix the issue.', 'Error', {
                type: 'danger'
            });
        },
        setDelete (item) {
            this.deletingItem.id = item.id;
            this.$refs.deleteModal.show();
        },
        showAddModal () {
            this.model = {};
            this.$refs.addModal.show();
        },
        deleteSuccess () {
            this.$refs.deleteModal.close();
            this.deletingItem.id = '';
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        }
    }
};
</script>

<style scoped>

</style>
