<template>
    <div>
        <h5 class="mb-4">Basic Info</h5>
        <div class="row">
            <div class="col">
                <validated-input label="Name" name="Name" v-model="model.name"
                                 :rules="{required : true}"
                                 :disabled="loading"/>
            </div>
            <div class="col">
                <validated-input type="email" label="Email" name="E-Mail" v-model="model.email"
                                 :rules="{required : true}" :disabled="loading"/>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <validated-date-picker label="Date of birth" name="Date of birth" v-model="model.date_of_birth"
                                       :disabled="loading" :rules="{required : true}"/>
            </div>
            <div class="col">
                <textarea-input label="Address" name="Address" v-model="model.address"
                                :disabled="loading" :rules="{required : true}"/>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <validated-vue-select label="Gender" name="Gender" v-model="model.gender"
                                      :rules="{required : true}" :disabled="loading"
                                      :options="genderOptions"/>
            </div>
            <div class="col">
                <validated-input label="Age" name="Age" v-model="model.age" :rules="{required : true}"
                                 :disabled="loading" type="number"/>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <validated-ajax-vue-select :url="countryOptions" label="Country Of Origin"
                                           name="Country of origin"
                                           v-model="model.country_of_origin" :rules="{required : true}"
                                           :disabled="loading"/>
            </div>
            <div class="col">
                <validated-vue-select label="Staff Role" name="Role" v-model="model.role"
                                      :rules="{required : true}" :disabled="loading"
                                      :options="staffOptions" @blur="roleChanged()"/>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <validated-input label="Phone" name="Phone" v-model="model.phone"
                                 :rules="{required : true}" type="number"
                                 :disabled="loading"/>
            </div>
            <div class="col">
                <validated-file-input label="Profile Image" name="Profile image" v-model="model.profile_image"
                                      :disabled="loading"/>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <validated-input label="Password" name="Password" v-model="model.password"
                                 :rules="{required : true}"
                                 :disabled="loading" type="password"/>
            </div>
            <div class="col">
                <validated-input label="Confirm Password" name="Confirm Password"
                                 v-model="model.confirm_password"
                                 :rules="{required : true}"
                                 :disabled="loading" type="password"/>
            </div>
        </div>
    </div>
</template>

<script>

import urls from '../../../../../../data/urls';

export default {
    name: 'BasicDetails',
    data () {
        return {
            loading: false,
            countryOptions: urls.vueSelect.countryOptions,
            qualificationsOptions: urls.vueSelect.qualificationsOptions,
            languageOptions: urls.vueSelect.languageOptions,
            religionOptions: urls.vueSelect.religionOptions,
            staffOptions: [
                { value: '1', label: 'Admin' },
                { value: '2', label: 'Managerial Staff' },
                { value: '3', label: 'Staff' }
            ],
            genderOptions: [
                { value: 'M', label: 'Male' },
                { value: 'F', label: 'Female' },
                { value: 'O', label: 'Other' }
            ],
            model: {}
        };
    },
    methods: {
        roleChanged () {
            this.$emit('role', this.model.role);
        },
        getModel () {
            this.$emit('model', this.model);
        }
    }
};
</script>

<style>
.full-page {
    height: 100vh;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.image {
    background: url("../../../../../../assets/img/banner-blurred.png");
}

.link {
    cursor: pointer;
}
</style>
