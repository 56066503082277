<template>
    <div>
        <h4>Reach Info</h4>
        <div class="row">
            <div class="col">
                <validated-vue-select label="Time since arrival to uk and joining REACHE"
                                      name="Time since arrival to UK and joining REACHE"
                                      v-model="model.time_since_arrival_to_uk_and_joining_reache"
                                      :rules="{required : true}" :disabled="loading"
                                      :options="durationOptions"/>
            </div>
            <div class="col">
                <validated-vue-select label="Last practice duration to join REACHE"
                                      name="Last practice duration to join REACHE"
                                      v-model="model.last_practice_duration_to_join_reache"
                                      :rules="{required : true}" :disabled="loading"
                                      :options="durationOptions"/>
            </div>
            <div class="col">
                <validated-vue-select label="Healthcare Profession" name="Healthcare profession"
                                      v-model="model.health_care_profession"
                                      :rules="{required : true}" :disabled="loading"
                                      :options="healthCareOptions"/>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <validated-vue-select label="Breaks in professional practice"
                                      name="Breaks in professional practice"
                                      v-model="model.breaks_in_professional_practice"
                                      :rules="{required : true}" :disabled="loading"
                                      :options="durationOptions"/>
            </div>
            <div class="col">
                <textarea-input label="How did you hear about REACHE"
                                name="Hear about REACHE"
                                v-model="model.hear_about_reach"
                                :disabled="loading" :rules="{required : true}"/>
            </div>
            <div class="col">
                <validated-date-picker label="Date of application to REACHE"
                                       name="Date of application to REACHE"
                                       v-model="model.application_date_to_reache"
                                       :disabled="loading" :rules="{required : true}"/>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <validated-date-picker label="Date of joining REACHE" name="Date of joining REACHE"
                                       v-model="model.date_of_joining_reache"
                                       :disabled="loading" :rules="{required : true}"/>
            </div>
            <div class="col">
                <validated-date-picker label="Date of induction to REACHE"
                                       name="Date of induction to REACHE"
                                       v-model="model.date_of_induction_to_reache"
                                       :disabled="loading" :rules="{required : true}"/>
            </div>
            <div class="col">
                <validated-vue-select label="Jobs" name="Jobs" v-model="model.jobs"
                                      :rules="{required : true}" :disabled="loading"
                                      :options="booleanOptions"/>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <validated-vue-select label="English level on entry" name="English level on entry"
                                      v-model="model.english_level_on_entry"
                                      :rules="{required : true}" :disabled="loading"
                                      :options="englishLevelEntryOptions"/>
            </div>
            <div class="col">
                <validated-vue-select label="Time taken to pass OET" name="Time taken to pass OET"
                                      v-model="model.time_taken_to_pass_oet"
                                      :rules="{required : true}" :disabled="loading"
                                      :options="durationOptions"/>
            </div>
            <div class="col">
                <validated-vue-select label="Named REACHE English tutor"
                                      name="Named REACHE English tutor"
                                      v-model="model.named_reache_english_tutor"
                                      :rules="{required : true}" :disabled="loading"
                                      :options="booleanOptions"/>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <validated-vue-select label="OET pass" name="OET pass" v-model="model.oet_pass"
                                      :rules="{required : true}" :disabled="loading"
                                      :options="booleanOptions"/>
            </div>
            <div class="col">
                <validated-vue-select label="PLAB 1 pass" name="Plab 1 pass"
                                      v-model="model.plab_1_pass"
                                      :rules="{required : true}" :disabled="loading"
                                      :options="booleanOptions"/>
            </div>
            <div class="col">
                <validated-vue-select label="Named REACHE Clinical supervisor"
                                      name="Named REACHE Clinical supervisor"
                                      v-model="model.named_reache_clinical_supervisor"
                                      :rules="{required : true}" :disabled="loading"
                                      :options="booleanOptions"/>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <validated-vue-select label="Time taken to pass PLAB 1 with REACHE"
                                      name="Time taken to pass PLAB 1 with REACHE"
                                      v-model="model.time_taken_to_pass_plab_1_with_reache"
                                      :rules="{required : true}" :disabled="loading"
                                      :options="durationOptions"/>
            </div>
            <div class="col">
                <validated-vue-select label="Time take to pass PLAB 2 with REACHE"
                                      name="Time taken to pass PLAB 2 with REACHE"
                                      v-model="model.time_take_to_pass_plab2_with_reache"
                                      :rules="{required : true}" :disabled="loading"
                                      :options="durationOptions"/>
            </div>
            <div class="col">
                <validated-vue-select label="PLAB 2" name="Plab 2" v-model="model.plab_2"
                                      :rules="{required : true}" :disabled="loading"
                                      :options="booleanOptions"/>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <validated-vue-select label="CPT named supervisor" name="CPT named supervisor"
                                      v-model="model.cpt_named_supervisor"
                                      :rules="{required : true}" :disabled="loading"
                                      :options="booleanOptions"/>
            </div>
            <div class="col">
                <validated-input label="CPT ward" name="CPT ward" v-model="model.cpt_ward"
                                 :rules="{required : true}"
                                 :disabled="loading"/>
            </div>
            <div class="col">
                <validated-input label="CPT report completed by supervisor"
                                 name="CPT report completed by supervisor"
                                 v-model="model.cpt_report_completed_by_supervisor"
                                 :rules="{required : true}"
                                 :disabled="loading"/>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <validated-vue-select label="GMC registration achieved"
                                      name="GMC registration achieved"
                                      v-model="model.gmc_registration_achieved"
                                      :rules="{required : true}" :disabled="loading"
                                      :options="booleanOptions"/>
            </div>
            <div class="col">
                <textarea-input label="Cause for concern" name="Cause for concern"
                                v-model="model.cause_for_concern"
                                :disabled="loading" :rules="{required : true}"/>
            </div>
            <div class="col">
                <textarea-input label="Early exit" name="Early exit"
                                v-model="model.early_exit"
                                :disabled="loading" :rules="{required : true}"/>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <validated-vue-select label="Exit interview completed"
                                      name="Exit interview completed"
                                      v-model="model.exit_interview_completed"
                                      :rules="{required : true}" :disabled="loading"
                                      :options="booleanOptions"/>
            </div>
            <div class="col">
                <validated-input label="Name of person who did exit interview"
                                 name="Exit Interviewer"
                                 v-model="model.name_of_person_who_did_exit_interview"
                                 :rules="{required : true}"
                                 :disabled="loading"/>
            </div>
            <div class="col">
                <validated-vue-select label="Performance review summaries"
                                      name="Performance review summaries"
                                      v-model="model.performance_review_summaries"
                                      :rules="{required : true}" :disabled="loading"
                                      :options="performanceOptions"/>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <validated-date-picker label="Dates of closed membership"
                                       name="Closed membership date"
                                       v-model="model.dates_of_closed_membership"
                                       :disabled="loading" :rules="{required : true}"/>
            </div>
            <div class="col">
                <validated-vue-select label="Membership closure occurred training stage"
                                      name="Membership closure occurred training stage"
                                      v-model="model.stage_of_training_when_membership_closure_occurred"
                                      :rules="{required : true}" :disabled="loading"
                                      :options="stageOptions"/>
            </div>
            <div class="col">
                <textarea-input label="Reason for membership closure/s"
                                name="Reason for membership closure/s"
                                v-model="model.reason_for_membership_closure"
                                :disabled="loading" :rules="{required : true}"/>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <validated-date-picker label="Date of GMC registration"
                                       name="GMC registration date"
                                       v-model="model.date_of_gmc_registration"
                                       :disabled="loading" :rules="{required : true}"/>
            </div>
            <div class="col">
                <validated-vue-select label="Exit professional destination"
                                      name="Exit professional date"
                                      v-model="model.exit_professional_destination"
                                      :rules="{required : true}" :disabled="loading"
                                      :options="professionalDestinationOption"/>
            </div>
            <div class="col">
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ReachInfoForm',
    data () {
        return {
            loading: false,
            stageOptions: [
                { value: 'Stage 1', label: 'Stage 1' },
                { value: 'Stage 2', label: 'Stage 2' },
                { value: 'Stage 3', label: 'Stage 3' }
            ],
            healthCareOptions: [
                { value: 'Doctor', label: 'Doctor' },
                { value: 'Nurse', label: 'Nurse' },
                { value: 'Midwives', label: 'Midwives' },
                { value: 'Pharmacists', label: 'Pharmacists' },
                { value: 'lab Scientists', label: 'lab Scientists' },
                { value: 'Dentist', label: 'Dentist' },
                { value: 'Others', label: 'Others' }
            ],
            durationOptions: [
                { value: '6 month', label: '6 month' },
                { value: '1 year', label: '1 year' },
                { value: '2 year', label: '2 year' },
                { value: '3 year', label: '3 year' },
                { value: '4 year', label: '4 year' }
            ],
            performanceOptions: [
                { value: 'OET', label: 'OET' },
                { value: 'PLAB 1', label: 'PLAB 1' },
                { value: 'PLAB 2', label: 'PLAB 2' },
                { value: 'CPT feedback sections', label: 'CPT feedback sections' }
            ],
            englishLevelEntryOptions: [
                { value: 'Foundation', label: 'Foundation' },
                { value: 'Intermediate', label: 'Intermediate' },
                { value: 'Advanced', label: 'Advanced' },
                { value: 'Medical English', label: 'Medical English' }
            ],
            professionalDestinationOption: [
                { value: 'Foundation doctor', label: 'Foundation doctor' },
                { value: 'Speciality training', label: 'Speciality training' },
                { value: 'GP training', label: 'GP training' }
            ],
            booleanOptions: [
                { value: 0, label: 'Yes' },
                { value: 1, label: 'No' }
            ],
            model: {}
        };
    },
    methods: {
        getModalValues () {
            return this.model;
        }
    }
};
</script>

<style>
.full-page {
    height: 100vh;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.image {
    background: url("../../../../../../assets/img/banner-blurred.png");
}

.link {
    cursor: pointer;
}
</style>
