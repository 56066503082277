<template>
    <main>
        <div class="container">
            <div class="row py-5 px-0">
                <div class="col-12 col-lg-8">
                    <h2 class="text-uppercase poppins-bold mb-4">CONSULTANTS</h2>
                    <h3 class="mb-3">WEDNESDAY TEACHING</h3>
                    <article class="mb-5 pb-4">
                        We invite guest teachers to deliver a session on our programme of medical teaching that takes
                        place at 1000-1200hrs or 1330-1530hrs on Wednesdays. This is at the level of the foundation
                        programme (FY1/2) to help our member doctors prepare for their PLAB medical requalification
                        examinations and subsequent work as a doctor in the UK. Can you spare two hours one Wednesday?
                        <a target="_blank" href="https://reache.xeoscript.com/pdf/teaching-for-plab1-guide-for-tutors-2015.pdf" class="text-danger" download>Teaching Guide for Tutors</a>
                    </article>
                    <h3 class="mb-3">CLINICAL SUPERVISORS</h3>
                    <article class="mb-5 pb-4">
                        Can you offer a clinical attachment to one of our doctors for at least one month? We arrange
                        this for our doctors at an appropriate stage in their clinical retraining and it provides an
                        invaluable opportunity to experience clinical work in the NHS. Our doctor usually holds observer
                        status as a clinical attachee but if he/she has achieved GMC registration we like to offer to
                        him/her a Clinical Practical Training (CPT) scheme post as an honorary FY1 doctor to help in the
                        final stage becoming a safe and effective practitioner in the NHS. The tutors at Reache North
                        West provide a weekly review meeting to encourage our doctor to complete reflective logbooks and
                        to maximise the experience on placement. The REACHE North West administrative staff liaise with
                        your HR and Occupational Health departments to make the necessary arrangements.
                        <a target="_blank" href="https://reache.xeoscript.com/pdf/cpt-scheme-guide-for-consultants-2014.pdf" class="text-danger" download>CPT Scheme Guide</a>
                    </article>
                    <h3 class="mb-3">PRACTICE INTERVIEWS</h3>
                    <article class="mb-5 bb-1 b-dark pb-4">
                        Could you be a panel member for approximately two hours, helping REACHE North West members to
                        experience the UK interview process?
                    </article>
                    <article class="mb-5">
                        If you think you can help with any of these, or have other ideas for supporting our members,
                        <router-link to="/Contact/" class="text-danger">please contact us.</router-link> Many thanks.
                    </article>
                    <share-this></share-this>
                </div>
                <div class="col-12 col-lg-4">
                    <location-and-info></location-and-info>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import LocationAndInfo from './components/support-pages/LocationAndInfo.vue';

export default {
    name: 'Consultants',
    components: {
        LocationAndInfo
    }
};
</script>

<style scoped>

</style>
