<template>
    <div>
        <loading-animation v-if="loading"/>
        <div v-if="details">
            <div class="fl-eq mb-4 mt-3">
                <div class="p-3 w-25r bs-4">
                    <h4 class="mb-3">Profile</h4>
                    <div style="min-height: 23rem; background-position: center center; background-size:cover;"
                         :style="image">
                    </div>
                </div>
                <basic-info :data="details"/>
            </div>
            <qualification-info :data="details" v-if="details.role!==1" class="mb-4"/>
            <reache-info :data="details" v-if="details.role!==1" class="mt-0"/>
        </div>
    </div>
</template>

<script>
import BasicInfo from './Components/DetailsComponents/BasicInfo';
import QualificationInfo from './Components/DetailsComponents/StaffQualificationInfo';
import ReacheInfo from './Components/DetailsComponents/StaffReachInfo';
import axios from 'secure-axios';
import urls from '../../../../data/urls';
import { mapGetters } from 'vuex';

export default {
    name: 'AdminStaffDetailsComponent',
    components: { ReacheInfo, QualificationInfo, BasicInfo },
    computed: {
        image () {
            return { backgroundImage: 'url(/upload/app/' + this.details.profile_image + ')' };
        },
        ...mapGetters(['currentUser'])
    },
    data () {
        return {
            id: this.$route.params.id,
            loading: false,
            details: ''
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods: {
        async loadDetails () {
            const that = this;
            if (!that.id) {
                that.id = that.currentUser.id;
            }
            that.loading = true;
            const response = await axios.form(urls.admin.directorFunction.staff.details, { id: that.id });
            if (response.data.error === false) {
                that.details = response.data.object;
                that.loading = false;
            }
            that.loading = false;
        }

    }
};
</script>

<style scoped>

</style>
