<template>
    <div class="card bs-4">
        <h4 class="mb-3">Reache Info</h4>
        <div class="row">
            <div class="col">
                <label>Time since arrival to UK and joining REACHE</label>
                <div class="form-control round-1 text-muted">92 days</div>
            </div>
            <div class="col">
                <label>Time from date of last practice to joining REACHE</label>
                <div class="form-control round-1 text-muted">2 Month</div>
            </div>
            <div class="col">
                <label>Healthcare Profession</label>
                <div class="form-control round-1 text-muted">Pharmacist</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>Breaks in professional practice</label>
                <div class="form-control round-1 text-muted">2 months</div>
            </div>
            <div class="col">
                <label>How did you hear about REACHE</label>
                <div class="form-control round-1 text-muted">Online Promotion</div>
            </div>
            <div class="col">
                <label>Date of application to REACHE</label>
                <div class="form-control round-1 text-muted">02-10-2021</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>Date of joining REACHE</label>
                <div class="form-control round-1 text-muted">92 days</div>
            </div>
            <div class="col">
                <label>Date of induction to REACHE</label>
                <div class="form-control round-1 text-muted">2 Month</div>
            </div>
            <div class="col">
                <label>Jobs </label>
                <div class="form-control round-1 text-muted">Yes</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>English level on entry</label>
                <div class="form-control round-1 text-muted">Foundation</div>
            </div>
            <div class="col">
                <label>Date of GMC registration</label>
                <div class="form-control round-1 text-muted">02-10-2021</div>
            </div>
            <div class="col">
                <label>Exit professional destination</label>
                <div class="form-control round-1 text-muted">speciality training</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>Time taken to pass OET</label>
                <div class="form-control round-1 text-muted">92 days</div>
            </div>
            <div class="col">
                <label>Named REACHE English tutor</label>
                <div class="form-control round-1 text-muted">2 Month</div>
            </div>
            <div class="col">
                <label>OET pass</label>
                <div class="form-control round-1 text-muted">Yes</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>PLAB 1 pass</label>
                <div class="form-control round-1 text-muted">Yes</div>
            </div>
            <div class="col">
                <label>Named REACHE Clinical supervisor</label>
                <div class="form-control round-1 text-muted">No</div>
            </div>
            <div class="col">
                <label>Time taken to pass PLAB 1 with REACHE</label>
                <div class="form-control round-1 text-muted"> 6 month</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>Time taken to pass PLAB 2 with REACHE</label>
                <div class="form-control round-1 text-muted"> 6 month</div>
            </div>
            <div class="col">
                <label>PLAB 2</label>
                <div class="form-control round-1 text-muted">Yes</div>
            </div>
            <div class="col">
                <label>CPT ward</label>
                <div class="form-control round-1 text-muted">Pharmacist</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>CPT named supervisor</label>
                <div class="form-control round-1 text-muted">No</div>
            </div>
            <div class="col">
                <label>CPT report completed by supervisor</label>
                <div class="form-control round-1 text-muted">Yes</div>
            </div>
            <div class="col">
                <label>GMC registration achieved</label>
                <div class="form-control round-1 text-muted">No</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>Cause for concern</label>
                <div class="form-control round-1 text-muted">Nil</div>
            </div>
            <div class="col">
                <label>Early exit</label>
                <div class="form-control round-1 text-muted">date/s reason</div>
            </div>
            <div class="col">
                <label>Exit interview completed</label>
                <div class="form-control round-1 text-muted">Yes</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>Name of person who did exit interview</label>
                <div class="form-control round-1 text-muted">Nicholas</div>
            </div>
            <div class="col">
                <label>Performance review summaries</label>
                <div class="form-control round-1 text-muted">OET, PLAB 1 , PLAB 2 CPT feedback sections</div>
            </div>
            <div class="col">
                <label>Dates of closed membership</label>
                <div class="form-control round-1 text-muted">Level 1</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>Stage of training when membership closure occurred</label>
                <div class="form-control round-1 text-muted">Stage 1</div>
            </div>
            <div class="col">
                <label>Reason for membership closure/s</label>
                <div class="form-control round-1 text-muted">No Reason</div>
            </div>
            <div class="col">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ReacheInfo'
};
</script>
