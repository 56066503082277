<template>
    <div class="row mb-5">
        <div class="col-12 contact-form">
            <transition name="fade" mode="out-in">
                <div class="text-center p-4 col-12 mt-4 fl-j-c fl-x-c" v-if="state === 'S'" key="'S">
                    <div class="text-success">
                        <div class="text-center mb-4">
                            <i class="fa fa-check-square-o  fa-3x" aria-hidden="true"></i>
                        </div>
                        <div class="text-center">
                            <p class="fs-4">Thank you <strong>{{ name }}</strong> for Joining us us</p>
                            We have received your request and will respond to you within 24 hours. For
                            urgent enquiries please call us on the telephone numbers provided.
                        </div>
                    </div>
                </div>

                <div class="text-center p-4 col-12 mt-4 fl-j-c fl-x-c" v-else-if="state === 'F'" key="'F">
                    <div class="text-danger">
                        <div class="text-center mb-4">
                            <i class="fa fa-window-close  fa-3x" aria-hidden="true"></i>
                        </div>
                        <div class="text-center">
                            <p class="fs-4"><strong>OOPS!</strong></p>
                            Something has gone wrong, Please try again.
                        </div>
                    </div>
                </div>
                <div class="text-center p-4 col-12 mt-4 fl-j-c fl-x-c" v-else-if="state === 'L'" key="'L">
                        <div class="text-center p-4">
                            <loading-animation/>
                            <p class="c-font-19 mb-4 proxim-light">Please wait while communicate with
                                the
                                server</p>
                        </div>
                </div>
                <s-form class="contact-form row" v-else role="form" @submit="onSubmit"
                        autocomplete="off">
                    <div class="col-12">
                        <validated-input name="Name" v-model="name" shadow="0" placeholder=""
                                         label="Name(required)" :rules="rules.first_name" required/>
                    </div>
                    <div class="col-12">
                        <validated-input name="City / Town" v-model="city" shadow="0" placeholder=""
                                         label="City or town(required)" :rules="rules.city" required/>
                    </div>
                    <div class="col-12">
                        <validated-input type="email" name="Email" v-model="email" shadow="0"
                                         placeholder=""
                                         label="Email(required)" :rules="rules.email_id"/>
                    </div>
                    <div class="col-12">
                        <validated-text-area label="Message" v-model="message" :rules="rules.message1"
                                             shadow="0"/>
                    </div>
                    <div class="col-12 mt-4">
                        <button class="lego-btn w-50 bg-primary round-1" type="submit">
                            Submit
                        </button>
                    </div>
                </s-form>
            </transition>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import LoadingAnimation from '../../components/LoadingAnimation';

export default {
    name: 'contact',
    props: {},
    components: {
        LoadingAnimation
    },
    data () {
        return {
            state: 'D',
            name: '',
            city: '',
            email: '',
            message: '',
            rules: {
                first_name: {
                    required: true
                },
                city: {
                    required: true
                },
                email_id: {
                    required: true
                },
                message1: {
                    required: true
                }
            }
        };
    },
    methods: {
        onSubmit () {
            const bodyFormData = new FormData();
            bodyFormData.append('name', this.name);
            bodyFormData.append('city', this.city);
            bodyFormData.append('email', this.email);
            bodyFormData.append('message', this.message);

            const that = this;
            this.state = 'L';

            axios({
                method: 'post',
                url: 'https://reache.xeoscript.com/join_mail.php',
                data: bodyFormData,
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(function (response) {
                that.onSubmitSuccess(response);
            }).catch(function (response) {
                that.onSubmitFailed(response);
            });
        },

        onSubmitSuccess () {
            this.state = 'S';
        },

        onSubmitFailed () {
            this.state = 'F';
        }
    }
};
</script>
