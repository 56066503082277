<template>
    <div>
        <AdminHome/>
        <div class="row">
            <div class="col p-0">
                <batches-listing class="bs-4"/>
            </div>
            <div class="col p-0">
                <country-listing class="bs-4"/>
            </div>
        </div>
        <div class="row">
            <div class="col p-0">
                <professionals-listing class="bs-4"/>
            </div>
            <div class="col p-0">
                <language-listing class="bs-4"/>
            </div>
        </div>
        <div class="row">
            <div class="col p-0">
                <ReligionListing class="bs-4"/>
            </div>
            <div class="col p-0">
                <leaving-status-listing class="bs-4"/>
            </div>
        </div>
        <div class="row">
            <div class="col p-0">
                <user-types-listing class="bs-4"/>
            </div>
            <div class="col p-0">
                <member-status-listing class="bs-4"/>
            </div>
        </div>
    </div>
</template>

<script>
import BatchesListing from './Batches';
import LanguageListing from './Language';
import CountryListing from './Country';
import ReligionListing from './Religion';
import ProfessionalsListing from './Professionals';
import UserTypesListing from './UserType';
import MemberStatusListing from './MemberStatus';
import LeavingStatusListing from './LeavingStatus';
import AdminHome from '../../../Dashboards/CustomHomePages/AdminHome';

export default {
    name: 'MasterDataViewPage',
    components: {
        LeavingStatusListing,
        MemberStatusListing,
        UserTypesListing,
        ProfessionalsListing,
        ReligionListing,
        CountryListing,
        LanguageListing,
        BatchesListing,
        AdminHome
    }
};
</script>

<style scoped>

</style>
