<template>
    <div class="fl-eq p-0 of-h bs-4 mb-3 w-65r">
        <div class="p-4">
            <h5 class="mb-3">My Schedule</h5>
            <perfect-scrollbar  id="scroll1">
                <h6 class="text-primary">22 October</h6>
                <div class="row m-2">
                    <div v-bind:style="getColor('1')" class="col m-2">10:30 AM</div>
                    <div class="col m-2">Event number 1</div>
                </div>
                <div class="row m-2">
                    <div v-bind:style="getColor('2')" class="col m-2">11:30 AM</div>
                    <div class="col m-2">Event number 2</div>
                </div>
                <div class="row m-2">
                    <div v-bind:style="getColor('2')" class="col m-2">11:30 AM</div>
                    <div class="col m-2">Event number 2</div>
                </div>
                <h6 class="text-primary">22 October</h6>
                <div class="row m-2">
                    <div v-bind:style="getColor('1')" class="col m-2">10:30 AM</div>
                    <div class="col m-2">Event number 1</div>
                </div>
                <div class="row m-2">
                    <div v-bind:style="getColor('2')" class="col m-2">11:30 AM</div>
                    <div class="col m-2">Event number 2</div>
                </div>
                <div class="row m-2">
                    <div v-bind:style="getColor('2')" class="col m-2">11:30 AM</div>
                    <div class="col m-2">Event number 2</div>
                </div>
                <h6 class="text-primary">22 October</h6>
                <div class="row m-2">
                    <div v-bind:style="getColor('1')" class="col m-2">10:30 AM</div>
                    <div class="col m-2">Event number 1</div>
                </div>
                <div class="row m-2">
                    <div v-bind:style="getColor('2')" class="col m-2">11:30 AM</div>
                    <div class="col m-2">Event number 2</div>
                </div>
                <div class="row m-2">
                    <div v-bind:style="getColor('2')" class="col m-2">11:30 AM</div>
                    <div class="col m-2">Event number 2</div>
                </div>
                <h6 class="text-primary">22 October</h6>
                <div class="row m-2">
                    <div v-bind:style="getColor('1')" class="col m-2">10:30 AM</div>
                    <div class="col m-2">Event number 1</div>
                </div>
                <div class="row m-2">
                    <div v-bind:style="getColor('2')" class="col m-2">11:30 AM</div>
                    <div class="col m-2">Event number 2</div>
                </div>
                <div class="row m-2">
                    <div v-bind:style="getColor('2')" class="col m-2">11:30 AM</div>
                    <div class="col m-2">Event number 2</div>
                </div>
                <h6 class="text-primary">22 October</h6>
                <div class="row m-2">
                    <div v-bind:style="getColor('1')" class="col m-2">10:30 AM</div>
                    <div class="col m-2">Event number 1</div>
                </div>
                <div class="row m-2">
                    <div v-bind:style="getColor('2')" class="col m-2">11:30 AM</div>
                    <div class="col m-2">Event number 2</div>
                </div>
                <div class="row m-2">
                    <div v-bind:style="getColor('2')" class="col m-2">11:30 AM</div>
                    <div class="col m-2">Event number 2</div>
                </div>
                <h6 class="text-primary">22 October</h6>
                <div class="row m-2">
                    <div v-bind:style="getColor('1')" class="col m-2">10:30 AM</div>
                    <div class="col m-2">Event number 1</div>
                </div>
                <div class="row m-2">
                    <div v-bind:style="getColor('2')" class="col m-2">11:30 AM</div>
                    <div class="col m-2">Event number 2</div>
                </div>
                <div class="row m-2">
                    <div v-bind:style="getColor('2')" class="col m-2">11:30 AM</div>
                    <div class="col m-2">Event number 2</div>
                </div>
            </perfect-scrollbar>
        </div>
        <div class="bgc-primary p-4">
            <div class="fl-te-c">
                <h5 class="text-white">Agendas</h5>
                <validated-date-picker class="text-2 bgc-secondary-100 mr-3" name="Date" placeholder="Select Date"
                                       v-model="date"/>
            </div>
            <perfect-scrollbar id="scroll">
                <div style="background: #f3f8fd;" class="p-3 mb-3 mr-3">
                    <h6>Event 1</h6>
                    <div>- Study Science</div>
                    <div>- Study Chemistry</div>
                    <div>- Study Social</div>
                </div>
                <div style="background: #f3f8fd;" class="p-3 mb-3 mr-3">
                    <h6>Event 2</h6>
                    <div>- Study Science</div>
                    <div>- Study Chemistry</div>
                    <div>- Study Social</div>
                </div>
                <div style="background: #f3f8fd;" class="p-3 mb-3 mr-3">
                    <h6>Event 3</h6>
                    <div>- Study Science</div>
                    <div>- Study Chemistry</div>
                    <div>- Study Social</div>
                </div>
                <div style="background: #f3f8fd;" class="p-3 mb-3 mr-3">
                    <h6>Event 3</h6>
                    <div>- Study Science</div>
                    <div>- Study Chemistry</div>
                    <div>- Study Social</div>
                </div>
            </perfect-scrollbar>
        </div>
    </div>
</template>

<script>
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';

export default {
    name: 'MyScheduleEvents',
    components: { PerfectScrollbar },
    data () {
        return {
            scrollHeight: '500px',
            date: ''
        };
    },
    updated () {
        this.updateScroll();
    },
    methods: {
        updateScroll () {
            const element = document.getElementById('scroll');
            element.scrollTop = element.scrollHeight;
            const element1 = document.getElementById('scroll1');
            element1.scrollTop = element1.scrollHeight;
        },
        getColor (value) {
            if (value === '1') {
                const data = { background: '#d3e4f5' };
                return data;
            } else {
                const data = { background: '#f5d3d9' };
                return data;
            }
        }
    }
};
</script>

<style>
.ps {
    height: 500px;
}

.ps__rail-x {
    display: none !important;
}
</style>
