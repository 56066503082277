<template>
    <div>
        <h5 class="mt-4 mb-4">Reach Info</h5>
        <div class="row">
            <div class="col-6">
                <validated-date-picker label="Date of application to REACHE"
                                       name="Date of application to REACHE" v-model="model.date_of_joining_reache"
                                       :disabled="loading" :rules="{required : true}"/>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ReachInfo',
    data () {
        return {
            loading: false,
            model: {}
        };
    },
    methods: {
        getModel () {
            this.$emit('model', this.model);
        }
    }
};
</script>

<style>
.full-page {
    height: 100vh;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.image {
    background: url("../../../../../../assets/img/banner-blurred.png");
}

.link {
    cursor: pointer;
}
</style>
