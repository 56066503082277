<template>
    <div>
        <AdminHome/>
        <div class="mt-3 p-4 bs-4">
            <h4 class="mb-3">Membership Statistics</h4>
            <div class="row">
                <div class="col">
                    <label>Current Member</label>
                    <div class="form-control round-1 text-muted">100</div>
                </div>
                <div class="col">
                    <label>Completed</label>
                    <div class="form-control round-1 text-muted">122</div>
                </div>
                <div class="col">
                    <label>Alternative NHS Post</label>
                    <div class="form-control round-1 text-muted">33</div>
                </div>
                <div class="col">
                    <label>Closed or Left</label>
                    <div class="form-control round-1 text-muted">10</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AdminHome from '../../Dashboards/CustomHomePages/AdminHome';

export default {
    name: 'MemberStatistics',
    components: { AdminHome }
};
</script>

<style scoped>

</style>
