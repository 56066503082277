<template>
    <div class="p-3 mt-0 w-70r ml-4 bs-4">
        <div class="fl-te-c mb-3">
            <h4 class="mb-3">Basic Info</h4>
            <div class="btn-group">
<!--                <btn size="sm" text="Back" @click="$router.push('/enrollment-function/pending-member-management/')"/>-->
                <btn size="sm" text="History" @click="$router.push('/enrollment-function/user-management/' +details.id + '/history/')"/>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>DOB</label>
                <div class="form-control round-1 text-muted">{{ details.date_of_birth }}</div>
            </div>
            <div class="col">
                <label>Age</label>
                <div class="form-control round-1 text-muted">{{ details.age }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>Address</label>
                <div class="form-control round-1 text-muted">{{ details.address }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>Gender</label>
                <div class="form-control round-1 text-muted">{{ details.gender }}</div>
            </div>
            <div class="col">
                <label>Religion</label>
                <div class="form-control round-1 text-muted">{{ details.religion.name }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>Country of Origin</label>
                <div class="form-control round-1 text-muted">{{ details.country_of_origin.name }}</div>
            </div>
            <div class="col">
                <label>Language Spoken</label>
                <div class="form-control round-1 text-muted">{{ details.language_spoken.name }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>Dependents</label>
                <div class="form-control round-1 text-muted">{{ details.dependents }}</div>
            </div>
            <div class="col">
                <label>Immigration Status</label>
                <div class="form-control round-1 text-muted">{{ details.immigration_status }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>Date of Arrival in UK</label>
                <div class="form-control round-1 text-muted">{{ details.date_of_arrival }}</div>
            </div>
            <div class="col">
                <label>Branch</label>
                <div class="form-control round-1 text-muted">{{ details.branch }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: { details: { type: Object } },
    name: 'BasicInfo'
};
</script>

<style scoped>

</style>
