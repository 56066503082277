<template>
    <section>
        <h3 class="text-uppercase mb-3">Location</h3>
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9495.742058868083!2d-2.323895!3d53.487482!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf431c32fbf1abb0d!2sSalford%20Royal%20NHS%20Foundation%20Trust!5e0!3m2!1sen!2sin!4v1635766992743!5m2!1sen!2sin"
            style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        <a href="https://maps.google.com/maps?z=16&amp;q=salford%2Broyal%2Bhospital%2Bstott%2Blane%2Bsalford%2Bm6%2B8hd"
           class="text-danger"
           target="_blank" rel="noopener noreferrer">
            <address>
                Salford Royal Hospital
                <br>Stott Lane
                <br>Salford
                <br>M6 8HD
            </address>
        </a>
        0161 2064201<br>
        8.30 - 5.00
        <br>
        Monday to Friday

        <h3 class="text-uppercase mb-3">Follow us on Twitter</h3>
        <twitter-post></twitter-post>
        If you would like to donate to support REACHE NW, please look at our Donations page: <br>
        <router-link to="../help/donation.html" class="text-italic text-danger">https://reache.com/donations</router-link>
        <!--        <scrolling-content>-->
        <!--            <template #header>-->
        <!--                <h2>Header</h2>-->
        <!--            </template>-->
        <!--            <template #content>-->
        <!--                <p>-->
        <!--                    Some text to enable scrolling.. Lorem ipsum dolor sit amet, illum definitiones no quo, maluisset-->
        <!--                    concludaturque et eum, altera fabulas ut quo. Atqui causae gloriatur ius te, id agam omnis evertitur-->
        <!--                    eum. Affert laboramus repudiandae nec et. Inciderint efficiantur his ad. Eum no molestiae-->
        <!--                    voluptatibus.-->

        <!--                    Some text to enable scrolling.. Lorem ipsum dolor sit amet, illum definitiones no quo, maluisset-->
        <!--                    concludaturque et eum, altera fabulas ut quo. Atqui causae gloriatur ius te, id agam omnis evertitur-->
        <!--                    eum. Affert laboramus repudiandae nec et. Inciderint efficiantur his ad. Eum no molestiae-->
        <!--                    voluptatibus.-->

        <!--                    Some text to enable scrolling.. Lorem ipsum dolor sit amet, illum definitiones no quo, maluisset-->
        <!--                    concludaturque et eum, altera fabulas ut quo. Atqui causae gloriatur ius te, id agam omnis evertitur-->
        <!--                    eum. Affert laboramus repudiandae nec et. Inciderint efficiantur his ad. Eum no molestiae-->
        <!--                    voluptatibus.-->

        <!--                    Some text to enable scrolling.. Lorem ipsum dolor sit amet, illum definitiones no quo, maluisset-->
        <!--                    concludaturque et eum, altera fabulas ut quo. Atqui causae gloriatur ius te, id agam omnis evertitur-->
        <!--                    eum. Affert laboramus repudiandae nec et. Inciderint efficiantur his ad. Eum no molestiae-->
        <!--                    voluptatibus.-->

        <!--                    Some text to enable scrolling.. Lorem ipsum dolor sit amet, illum definitiones no quo, maluisset-->
        <!--                    concludaturque et eum, altera fabulas ut quo. Atqui causae gloriatur ius te, id agam omnis evertitur-->
        <!--                    eum. Affert laboramus repudiandae nec et. Inciderint efficiantur his ad. Eum no molestiae-->
        <!--                    voluptatibus.-->
        <!--                </p>-->
        <!--            </template>-->
        <!--        </scrolling-content>-->
    </section>
</template>
<script>
import TwitterPost from '../TwitterPost.vue';
// import ScrollingContent from '../UI/ScrollingContent.vue';

export default {
    components: {
        TwitterPost
        // ScrollingContent
    }
};
</script>
<style lang="scss" scoped>

</style>
