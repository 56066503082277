<template>
    <custom-vue-table :showSearchBox="false" class="m-3 c-table" title="Pending Members" :fields="fields" :url="listUrl"
                      :per-page="10" ref="table" :extra-params="{branch, is_declined}">
        <template #buttons>
            <drop-btn text="Filters" icon="fa fa-filter" size="sm" dropdown-width="30r">
                <div class="m-3">
                    <validated-vue-select label="Status" name="Status" v-model="branch" :options="branchOptions"
                                          :disabled="loading"/>
                    <validated-vue-select label="Request Declined" name="Request Declined" v-model="is_declined"
                                          :options="declinedOptions"
                                          :disabled="loading"/>
                </div>
            </drop-btn>
        </template>
        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <btn size="xs" v-if="rowData.is_declined===0" text="View" @click="viewDetails(rowData.id)"/>
                <span v-else>Request Declined</span>
            </div>
        </template>
    </custom-vue-table>
</template>

<script>
import urls from '@/data/urls';

export default {
    name: 'PendingMembersListing',
    data () {
        return {
            is_declined: '',
            branch: '',
            loading: false,
            branchOptions: [
                { value: 'Clinical', label: 'Clinical' },
                { value: 'OET', label: 'OET' }
            ],
            declinedOptions: [
                { value: 0, label: 'Not Declined' },
                { value: 1, label: 'Declined' }
            ],
            listUrl: urls.admin.userList.pendingMemberList,
            fields: [
                {
                    name: 'name',
                    sortField: 'name',
                    title: 'Name'
                },
                {
                    name: 'email',
                    sortField: 'email',
                    title: 'Email'
                },
                {
                    name: 'branch',
                    sortField: 'branch',
                    title: 'Branch'
                },
                {
                    name: '__slot:actions',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right',
                    title: 'Actions'
                }
            ],
            model: {
                name: ''
            }
        };
    },
    methods: {
        viewDetails (id) {
            this.$router.push('/enrollment-function/user-management/' + id + '/details/');
        }
    }
};
</script>

<style scoped>

</style>
