<template>
    <div>
        <admin-home v-if="currentUser.role && currentUser.role===1"/>
        <staff-home v-if="currentUser.role && (currentUser.role===2 || currentUser.role===3)"/>
        <volunteer-home v-if="currentUser.branch==='Volunteer'"/>
        <member-home v-if="currentUser.branch==='Clinical' || currentUser.branch==='OET'"/>
    </div>
</template>

<script>
import AdminHome from './CustomHomePages/AdminHome';
import MemberHome from './CustomHomePages/MemberHome';
import StaffHome from './CustomHomePages/StaffHome';
import VolunteerHome from './CustomHomePages/VolunteerHome';
import { mapGetters } from 'vuex';

export default {
    name: 'HomePage',
    components: {
        VolunteerHome,
        StaffHome,
        MemberHome,
        AdminHome
    },
    computed: {
        ...mapGetters(['currentUser'])
    }
};
</script>

<style scoped>

</style>
