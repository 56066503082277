<template>
    <custom-vue-table class="bs-4 m-3 c-table" title="Folders" :fields="fields" :url="listUrl" :per-page="10"
                    ref="table">
        <template #buttons>
            <btn icon="fa fa-plus" size="sm" @click="$refs.addModal.show()" text="Add"/>
        </template>
        <template #actions="{rowData}">
            <div class="btn-group">
                <btn size="xs" color="success" icon="fa fa-download" @click="downloadFolder(rowData.id)"/>
                <btn size="xs" color="warning" icon="fa fa-pencil" @click="editFolder(rowData.id)"/>
            </div>
        </template>

        <template #footer>

            <modal  class="c-modal no-close"  title="Add Folder" ref="addModal" width="30r" header-color="">
                <s-form @submit="submitClicked" ref="form">
                    <div class="row">
                        <div class="col">
                            <validated-input label="Folder Name" name="Folder Name" v-model="model.name"
                                             :rules="{required : true}" :disabled="loading"/>
                        </div>
                    </div>
                    <btn size="sm" text="Save" :loading="loading" loading-text="Saving..."/>
                </s-form>
            </modal>
            <modal  class="c-modal no-close"  title="Edit Folder" ref="editModal" width="30r" header-color="">
                <s-form @submit="submitClicked" ref="form">
                    <div class="row">
                        <div class="col">
                            <validated-input label="Folder Name" name="Folder Name" v-model="model.name"
                                             :rules="{required : true}" :disabled="loading"/>
                        </div>
                    </div>
                    <btn size="sm" text="Update" :loading="loading" loading-text="Updating..."/>
                </s-form>
            </modal>
            <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteSuccess">
                <p>You are about to delete the Folder. Are you sure?</p>
                <template #loading>
                    <delete-animation/>
                    Please wait while we delete the Folder.
                </template>
            </delete-modal>
        </template>

    </custom-vue-table>
</template>

<script>

export default {
    name: 'FolderList',
    data () {
        return {
            loading: false,
            fields: [
                {
                    name: 'title',
                    sortField: 'title',
                    title: 'Title'
                },
                {
                    name: 'type',
                    sortField: 'type',
                    title: 'Type'
                },
                {
                    name: 'date',
                    sortField: 'date',
                    title: 'Updated Date'
                },
                {
                    name: '__slot:actions',
                    title: '',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right'
                }
            ],
            deleteUrl: '',
            addEditUrl: '',
            deletingItem: {
                id: ''
            },
            model: {
                name: ''
            }
        };
    },
    methods: {
        async submitClicked () {
            // const that = this;
            // that.loading = true;
            // const response = await axios.form(urls.admin.policy.add, that.model);
            // const json = response.data;
            // if (json.error === false) {
            //     that.formSuccess();
            //     that.loading = false;
            // } else {
            //     that.formError(json);
            //     that.loading = false;
            // }
            // that.loading = false;
        },
        setDelete (item) {
            this.deletingItem.id = item.id;
            this.$refs.deleteModal.show();
        },
        deleteSuccess () {
            this.$refs.deleteModal.close();
            this.deletingItem.id = '';
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
        },
        editFolder () {
            this.$refs.editModal.show();
        },
        async downloadFolder () {
        }
    }
};
</script>

<style>
.ps {
    height: 500px;
}

.ps__rail-x {
    display: none !important;
}
</style>
