<template>
    <div>
        <AdminHome/>
        <div class="p-0 bs-4 m-3">
            <div>
                <h5 class="p-4 bb-1">Members Count</h5>
                <div class="fl-eq p-3 text-center mb-4 pb-5">
                    <div class="br-3">
                        <span class="text-3x text-primary">107</span><br>
                        <b class="font-weight-bold">PASSED</b>
                    </div>
                    <div class="br-3">
                        <span class="text-3x text-primary">11</span><br>
                        <b class="font-weight-bold">FAILED</b>
                    </div>
                    <div>
                        <span class="text-3x text-primary">118</span><br>
                        <b class="font-weight-bold">TOTAL</b>
                    </div>
                </div>
            </div>
        </div>
        <scheduled-meetings/>
    </div>
</template>

<script>
import ScheduledMeetings from '../ClinicalAndOetComponents/ScheduledTeamMeetings/ListingComponent';
import AdminHome from '../../Dashboards/CustomHomePages/AdminHome';

export default {
    name: 'DashBoard',
    components: { AdminHome, ScheduledMeetings }
};
</script>

<style scoped>

</style>
