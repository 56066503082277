<template>
    <div class="p-0 mt-3 bs-4">
        <div class="p-3 fl-te-c">
            <h4 class="mb-3">Profile History</h4>
            <btn size="sm" text="Back" @click="$router.push('/enrollment-function/user-management/' + id + '/details/')"/>
        </div>
        <div>
            <div class="p-4" style="background: #e8f1fb;">
                <h5>07 November 2021</h5>
                <div class="row">
                    <div class="col">
                        <label>Certificate Name</label>
                        <div class="form-control round-1 text-muted bg-2">Basic Life Support</div>
                    </div>
                    <div class="col">
                        <label>Document</label>
                        <div class="fl-te-c">
                            <div class="form-control round-1 text-muted">Document</div>
                            <btn size="sm" class="ml-2" icon="fa fa-eye" @click="window.open(details.url)"/>
                        </div>
                    </div>
                    <div class="col">
                        <label>Remarks</label>
                        <div class="form-control round-1 text-muted">Remarks entered here</div>
                    </div>
                </div>
            </div>
            <div class="p-4">
                <h5>17 November 2021</h5>
                <div class="row p-4">
                    <div class="col">
                        <label>Exam Name</label>
                        <div class="form-control round-1 text-muted bg-2">Plab 1</div>
                    </div>
                    <div class="col">
                        <label>Document</label>
                        <div class="fl-te-c">
                            <div class="form-control round-1 text-muted">Document</div>
                            <btn size="sm" class="ml-2" icon="fa fa-eye" @click="window.open(details.url)"/>
                        </div>
                    </div>
                    <div class="col">
                        <label>Remarks</label>
                        <div class="form-control round-1 text-muted">Remarks entered here</div>
                    </div>
                </div>
            </div>
            <div class="p-4" style="background: #e8f1fb;">
                <h5>27 November 2021</h5>
                <div class="row p-4">
                    <div class="col">
                        <label>Certificate Name</label>
                        <div class="form-control round-1 text-muted bg-2">Basic Life Support</div>
                    </div>
                    <div class="col">
                        <label>Document</label>
                        <div class="fl-te-c">
                            <div class="form-control round-1 text-muted">Document</div>
                            <btn size="sm" class="ml-2" icon="fa fa-eye" @click="window.open(details.url)"/>
                        </div>
                    </div>
                    <div class="col">
                        <label>Remarks</label>
                        <div class="form-control round-1 text-muted">Remarks entered here</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProfileHistoryDetails',
    data () {
        return {
            id: this.$route.params.id,
            details: ''
        };
    }
};
</script>

<style scoped>

</style>
