<template>
    <div>
        <AdminHome/>
        <custom-vue-table :showSearchBox="false" class="bs-4 m-3 c-table" title="Alumni Details" :fields="fields" :url="listUrl"
                          :per-page="10"
                          ref="table">
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <btn size="xs" text="View" @click="viewDetails(rowData)"/>
                </div>
            </template>
        </custom-vue-table>
    </div>
</template>

<script>
import urls from '@/data/urls';
import AdminHome from '../../Dashboards/CustomHomePages/AdminHome';

export default {
    name: 'AlumniDetailsList',
    components: { AdminHome },
    data () {
        return {
            loading: false,
            listUrl: urls.admin.masterData.pendingMembers.list,
            fields: [
                {
                    name: 'name',
                    sortField: 'name',
                    title: 'Name'
                },
                {
                    name: 'email',
                    sortField: 'email',
                    title: 'Email'
                },
                {
                    name: 'batch',
                    sortField: 'batch',
                    title: 'Batch Name'
                },
                {
                    name: 'j_date',
                    sortField: 'j_date',
                    title: 'Joining Date'
                },
                {
                    name: 'e_date',
                    sortField: 'e_date',
                    title: 'Exit Date'
                },
                {
                    name: '__slot:actions',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right',
                    title: 'Actions'
                }
            ],
            model: {
                name: ''
            }
        };
    },
    methods: {
        viewDetails () {
            this.$router.push('');
        }
    }
};
</script>

<style scoped>

</style>
