<template>
    <div class="fl-eq mb-4">
        <my-schedule-events/>
        <quick-links/>
    </div>
</template>

<script>
import QuickLinks from './QuickLinks';
import MyScheduleEvents from './MyScheduleAndAgendas';

export default {
    name: 'MySchedule',
    components: { MyScheduleEvents, QuickLinks },
    methods: {
        getColor (value) {
            if (value === '1') {
                const data = { background: '#d3e4f5' };
                return data;
            } else {
                const data = { background: '#f5d3d9' };
                return data;
            }
        }
    }
};
</script>

<style scoped>

</style>
