<template>
    <main>
        <div class="container">
            <div class="row py-5 px-0">
                <div class="col-12 col-lg-8">
                    <h2 class="text-uppercase poppins-bold mb-4">MENTORING</h2>
                    <div class="row">
                        <div class="col-lg-8">
                            <article>
                                REACHE North West are looking for doctors who are working in the NHS to act as mentors
                                to refugee health professionals. Ideally, you will have started work in the past few
                                years, or are used to supporting new starters.
                                <br>
                                <br>
                                <i class="m-0 fs--1">What we are hoping to achieve through the mentoring scheme?</i>
                                <br>
                                <br>
                                To help the refugee healthcare professional to:
                                <br>

                                <ul class="ml-4">
                                    <li>
                                        improve understanding of life as a working health professional in the UK
                                    </li>
                                    <li>
                                        reduce social and professional isolation
                                    </li>
                                </ul>
                            </article>
                        </div>
                        <div class="col-lg-4">
                            <img src="../assets/img/mentoring-1.png" class="img-fluid" alt="#">
                        </div>
                    </div>
                    <br>
                    <br>
                    <div class="row">
                        <div class="col-lg-4">
                            <img src="../assets/img/mentoring-2.png" class="img-fluid" alt="#">
                        </div>
                        <div class="col-lg-8">
                            <article>
                                We are looking for mentors who are:
                                <br>
                                <ul class="ml-4">
                                    <li>
                                        Responsible
                                    </li>
                                    <li>
                                        Dependable
                                    </li>
                                    <li>
                                        Friendly
                                    </li>
                                    <li>
                                        CRB checked
                                    </li>
                                </ul>
                            </article>
                        </div>
                    </div>
                    <br>
                    <br>
                    <article class="mb-5 pb-4">
                        and we would like an initial commitment to six face-to-face meetings with your mentee in six
                        months.
                        <br>
                        <br>
                        If you are interested in mentoring, please get in touch through our
                        <router-link to="/Contact/" class="text-danger">contact page.</router-link>
                        <br>
                        Many thanks.
                    </article>

                    <share-this></share-this>
                </div>
                <div class="col-12 col-lg-4">
                    <location-and-info></location-and-info>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import LocationAndInfo from './components/support-pages/LocationAndInfo.vue';

export default {
    name: 'Juniordoctors',
    components: {
        LocationAndInfo
    }
};
</script>

<style scoped>

</style>
