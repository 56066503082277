<template>
    <div class="card mt-3 p-4 bs-4">
        <h4 class="mb-3">Policy Details</h4>
        <div class="row">
            <div class="col">
                <label>Branch</label>
                <div class="form-control round-1 text-muted">Branch</div>
            </div>
            <div class="col">
                <label>Batch</label>
                <div class="form-control round-1 text-muted">Batch</div>
            </div>
            <div class="col">
                <label>Role</label>
                <div class="form-control round-1 text-muted">Role</div>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label>Policy Name</label>
                <div class="form-control round-1 text-muted">Policy</div>
            </div>
            <div class="col-4">
                <label>File</label>
                <div class="fl-te-c">
                    <div class="form-control round-1 text-muted">File</div>
                    <btn size="sm" class="ml-2" icon="fa fa-eye" @click="window.open(details.url)"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Course Details',
    data () {
        return {
            details: ''
        };
    }
};
</script>

<style scoped>

</style>
