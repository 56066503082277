<template>
    <custom-vue-table  class="m-3 c-table" title="Members" :fields="fields" :url="listUrl" :per-page="10"
                    ref="table" :extra-params="{status, branch}">
        <template #buttons>
            <drop-btn text="Filters" icon="fa fa-filter" size="sm" dropdown-width="30r">
                <div class="m-3">
                    <validated-vue-select label="Branch" name="Branch" v-model="branch" :options="branchOptions"
                                          :disabled="loading"/>
                    <validated-vue-select label="Status" name="Status" v-model="status" :options="statusOptions"
                                      :disabled="loading"/>
                </div>
            </drop-btn>
            <btn icon="fa fa-plus" size="sm" class="ml-2" @click="$refs.addModal.show()" text="Add"/>
        </template>
        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <btn size="xs" color="success" v-if="rowData.is_suspended===0" text="Active" @click="suspendUser(rowData.id)"/>
                <btn size="xs" color="danger" v-else text="Suspended" @click="activateUser(rowData.id)"/>
                <btn size="xs" text="View" @click="viewDetails(rowData.id)"/>
            </div>
        </template>
        <template #status="{rowData}">
            <div v-if="rowData.is_suspended===0">Active</div>
            <div v-else>Suspend</div>
        </template>

        <template #footer>
            <modal  class="c-modal no-close" ref="userSuccessModal" width="40r">
                <div class="success-modal">
                    <img src="../../../../../assets/img/success.png" class="mb-4" alt="#">
                    <p class="fs-2 poppins-semibold mb-1">User Created Successfully</p>
                    <p class="mb-0">
                        A mail containing Login Credentials will be sent to the registered Email ID
                    </p>
                </div>
            </modal>
            <modal  class="c-modal" no-close-on-backdrop  title="Add Users" ref="addModal" width="60r" header-color="">
                <s-form ref="form">
                    <RegistrationBasicForm @continue="changeScreen" :data="data" v-show="basicSection" ref="basicInfoRegistration"/>
                    <RegistrationQualificationForm @continue="changeScreen1" @back="backClicked" :data="data" v-show="qualificationSection" ref="qualificationInfoRegistration"/>
                    <registration-reach-info-form @back="backClicked1" :data="data" v-show="reacheSection" ref="reacheInfoRegistration"/>
                </s-form>
            </modal>
        </template>

    </custom-vue-table>
</template>

<script>
import urls from '@/data/urls';
import RegistrationBasicForm from '../../../../Auth/Registration/Components/BasicDetails';
import RegistrationQualificationForm from '../../../../Auth/Registration/Components/QualifIcation';
import RegistrationReachInfoForm from '../../../../Auth/Registration/Components/ReachInfo';
import axios from 'secure-axios';

export default {
    name: 'UsersListingComponent',
    components: { RegistrationReachInfoForm, RegistrationQualificationForm, RegistrationBasicForm },
    data () {
        return {
            status: '',
            branch: '',
            branchOptions: [
                { value: 'OET', label: 'OET' },
                { value: 'Clinical', label: 'Clinical' },
                { value: 'Volunteer', label: 'Volunteer' }
            ],
            // statusOptions: [
            //     { value: 'Current Members', label: 'Current Members' },
            //     { value: 'Completed', label: 'Completed' },
            //     { value: 'Alternative NHS Post', label: 'Alternative NHS Post' },
            //     { value: 'Closed/Left', label: 'Closed/Left' }
            // ],
            statusOptions: [
                { value: 0, label: 'Active' },
                { value: 1, label: 'Suspended' }
            ],
            loading: false,
            listUrl: urls.admin.userList.memberList,
            fields: [
                {
                    name: 'name',
                    sortField: 'name',
                    title: 'Name'
                },
                {
                    name: 'branch',
                    sortField: 'branch',
                    title: 'Branch'
                },
                {
                    name: 'email',
                    sortField: 'email',
                    title: 'Email'
                },
                {
                    name: '__slot:status',
                    sortField: 'status',
                    title: 'Status'
                },
                {
                    name: '__slot:actions',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right',
                    title: 'Actions'
                }
            ],
            countryOptions: '',
            languageOptions: '',
            religionOptions: '',
            basicSection: true,
            data: '',
            qualificationSection: false,
            reacheSection: false,
            model: {}
        };
    },
    methods: {
        async suspendUser (id) {
            const that = this;
            that.loadings1 = true;
            const response = await axios.form(urls.user.suspendUsers, { user_id: id });
            const json = response.data;
            if (json.success === true) {
                that.$notify('User suspended successfully.', 'Success', {
                    type: 'success'
                });
                that.$refs.table.refreshTable();
                that.loadings1 = false;
            } else {
                this.$notify('Something went wrong, Please try again later.', 'Error', {
                    type: 'danger'
                });
            }
            that.loadings1 = false;
        },
        async activateUser (id) {
            const that = this;
            that.loadings1 = true;
            const response = await axios.form(urls.user.activate, { user_id: id });
            const json = response.data;
            if (json.success === true) {
                that.$notify('User activated successfully.', 'Success', {
                    type: 'success'
                });
                that.$refs.table.refreshTable();
                that.loadings1 = false;
            } else {
                this.$notify('Something went wrong, Please try again later.', 'Error', {
                    type: 'danger'
                });
            }
            that.loadings1 = false;
        },
        viewDetails (id) {
            this.$router.push('/enrollment-function/user-management/' + id + '/details/');
        },
        changeScreen (response) {
            this.data = response;
            this.basicSection = false;
            this.qualificationSection = true;
        },
        changeScreen1 (response) {
            this.data = response;
            this.qualificationSection = false;
            this.reacheSection = true;
        },
        backClicked (response) {
            this.data = response;
            this.qualificationSection = false;
            this.basicSection = true;
        },
        backClicked1 (response) {
            this.data = response;
            this.reacheSection = false;
            this.qualificationSection = true;
        }
    }
};
</script>

<style scoped>

</style>
