<template>
    <main>
        <div class="container">
            <div class="row py-5 px-0">
                <div class="col-12 col-lg-8">
                    <h2 class="text-uppercase poppins-bold mb-4">DONATIONS</h2>
                    <article class="mb-5 pb-4">
                        Below is the link to our JustGiving Page. If you would like to donate, first of all thankyou!
                        Second, this is the general page for the hospitals in the Northern Care Alliance, so please
                        could you mention that you are wanting to support REACHE NW, or follow the prompt on JustGiving
                        to ensure your donations comes to REACHE.
                        <br>
                        <a target="_blank" href="https://www.justgiving.com/northcarecharity" class="text-danger">https://www.justgiving.com/northcarecharity</a>
                    </article>
                    <div class="">
                        <img src="../assets/img/thank-you.jpg" class="img-fluid" alt="#">
                    </div>
                    <share-this></share-this>
                </div>
                <div class="col-12 col-lg-4">
                    <location-and-info></location-and-info>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import LocationAndInfo from './components/support-pages/LocationAndInfo.vue';

export default {
    name: 'Donation',
    components: {
        LocationAndInfo
    }
};
</script>

<style scoped>

</style>
