<template>
    <main>
        <div class="container">
            <div class="row py-5 px-0">
                <div class="col-12 col-lg-8">

                    <h2 class="text-uppercase poppins-bold mb-4">
                        GMC RESOURCES FOR DOCTORS
                    </h2>

                    <article class="mb-5">
                        <a target="_blank"
                           href="https://www.gmc-uk.org/about/what-we-do-and-why/learning-and-support/workshops-for-doctors/welcome-to-uk-practice/welcome-to-uk-practice-case-studies">
                            <h4 class="mb-3 text-danger">Welcome to UK Practice self assessment tool</h4>
                        </a>
                        <p>
                            Designed to help doctors assess their knowledge and application of our core guidance
                            Good
                            medical practice and to provide help on finding further information and advice. The tool
                            contains 16 different case studies (grouped under five themes) that explore ethical
                            challenges in a variety of clinical scenarios. By working through the case studies
                            doctors
                            will be able to identify areas where they need to improve their knowledge. The results
                            and
                            feedback on answers can be downloaded and/or printed off and so can be used in 1-1
                            sessions
                            with trainers or in group discussions.
                        </p>
                    </article>
                    <article class="mb-5">
                        <a target="_blank" href="https://www.gmc-uk.org/about/what-we-do-and-why/learning-and-support/workshops-for-doctors/welcome-to-uk-practice#Film"
                           class="">
                            <h4 class="mb-3 text-danger">Welcome to UK Practice film</h4>
                        </a>

                        <p>
                            Details the role of the GMC, and features a number of established doctors sharing their
                            experiences of putting the important principles of Good medical practice into action
                        </p>
                    </article>
                    <article class="mb-5">
                        <a target="_blank" href="https://www.gmc-uk.org/ethical-guidance/learning-materials">
                            <h4 class="mb-3 text-danger">Good medical practice learning materials</h4>

                        </a>
                        Includes links to: <br><br>
                        <ul class="pl-lg-5 list-unstyled">
                            <li class="mb-4">Good medical practice in action (GMPiA) which presents a number of ethical
                                scenarios
                            </li>
                            <li>Decision tools/ case studies on confidentiality, end of life care, leadership,
                                management & raising concerns, and child protection
                            </li>
                            <li>Case studies of what happens when doctors don’t follow the guidance</li>
                        </ul>
                    </article>
                    <article class="mb-5">
                        <a target="_blank" href="https://www.gmc-uk.org/gmpinaction/">
                            <h4 class="mb-3 text-danger">
                                Good medical practice in action
                            </h4>
                        </a>

                        Ethical scenarios.
                    </article>
                    <article class="mb-5">
                        <a target="_blank" href="https://www.gmc-uk.org/concerns/information-for-doctors-under-investigation/support-for-doctors/managing-your-health">
                            <h4 class="mb-3 text-danger">Your health matters – Guidance for doctors managing their own
                                health
                            </h4>
                        </a>

                        <p>
                            This sits under the ‘concerns about doctors’ section of our website and was developed
                            primarily to assist doctors with health concerns who have been referred to the GMC. However
                            it is useful for all doctors to be aware of how to deal with health issues that may affect
                            their ability to practise.
                        </p>
                    </article>
                    <share-this></share-this>
                </div>
                <div class="col-12 col-lg-4">
                    <location-and-info></location-and-info>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import LocationAndInfo from './components/support-pages/LocationAndInfo.vue';
// import JoiningForm from './components/support-pages/JoiningForm.vue';

export default {
    components: {
        LocationAndInfo
    }
};
</script>
