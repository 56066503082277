<template>
    <div class="w-35r ml-4 p-3 bs-4 mt-0" style="height: 39.7rem;">
        <h5 class="mb-3">Teaching Links</h5>
        <perfect-scrollbar id="scroll">
            <div class="p-3 mr-4">
                <div class="fl-te-c">
                    <h6 class="text-primary">Link 1</h6>
                    <span class="text-muted">01-10-2021</span>
                </div>
                <a href="https://drive.google.com/drive/folders/1b20oiSjEMta48MY8Ydf4GVTmkyhqr6qhttps://drive.google.com/drive/folders/1b20oiSjEMta48MY8Ydf4GVTmkyhqr6q">
                    <div style="overflow-wrap: break-word;">-
                        https://drive.google.com/drive/folders/1b20oi-SjEMta48MY8Ydf4G
                        VTmkyhqr6qhttps://drive.google.com/drive/folders/1b20oi-SjEMta48MY8Ydf4GVTmkyhqr6q
                    </div>
                </a>
            </div>
            <div class="p-3 mr-4">
                <div class="fl-te-c">
                    <h6 class="text-primary">Link 2</h6>
                    <span class="text-muted">02-10-2021</span>
                </div>
                <a href="https://drive.google.com/drive/folders/1b20oi-SjEMta48MY8Ydf4GVTmkyhqr6q">
                    <div>- https://drive.google.com/drive/folders/1b20oi-SjEMta48MY8Ydf4GVTmkyhqr6q</div>
                </a>
            </div>
            <div class="p-3 mr-4">
                <div class="fl-te-c">
                    <h6 class="text-primary">Link 3</h6>
                    <span class="text-muted">03-10-2021</span>
                </div>
                <a href="https://drive.google.com/drive/folders/1b20oi-SjEMta48MY8Ydf4GVTmkyhqr6q">
                    <div>- https://drive.google.com/drive/folders/1b20oi-SjEMta48MY8Ydf4GVTmkyhqr6q</div>
                </a>
            </div>
            <div class="p-3 mr-4">
                <div class="fl-te-c">
                    <h6 class="text-primary">Link 4</h6>
                    <span class="text-muted">03-10-2021</span>
                </div>
                <a href="https://drive.google.com/drive/folders/1b20oi-SjEMta48MY8Ydf4GVTmkyhqr6q">
                    <div>- https://drive.google.com/drive/folders/1b20oi-SjEMta48MY8Ydf4GVTmkyhqr6q</div>
                </a>
            </div>
            <div class="p-3 mr-4">
                <div class="fl-te-c">
                    <h6 class="text-primary">Link 5</h6>
                    <span class="text-muted">03-10-2021</span>
                </div>
                <a href="https://drive.google.com/drive/folders/1b20oi-SjEMta48MY8Ydf4GVTmkyhqr6q">
                    <div>- https://drive.google.com/drive/folders/1b20oi-SjEMta48MY8Ydf4GVTmkyhqr6q</div>
                </a>
            </div>
        </perfect-scrollbar>
    </div>
</template>

<script>
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';

export default {
    name: 'QuickLinks',
    components: { PerfectScrollbar },
    data () {
        return {
            scrollHeight: '500px'
        };
    },
    updated () {
        this.updateScroll();
    },
    methods: {
        updateScroll () {
            const element = document.getElementById('scroll');
            element.scrollTop = element.scrollHeight;
        }
    }
};
</script>

<style>
.ps {
    height: 500px;
}

.ps__rail-x {
    display: none !important;
}

</style>
