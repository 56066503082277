<template>
    <div class="card bs-4 mt-3 mb-3">
        <div class="fl-te-c mb-3">
            <h4 class="mb-3">Video List</h4>
            <btn text="Add" icon="fa fa-plus" @click="showAddModal" size="sm"/>
        </div>
        <div class="row">
            <div class="col-4">
                <validated-vue-select label="Branch" name="Branch" v-model="model.branch"
                                           :rules="{required : true}" :options="branchOptions"
                                           :disabled="loading"/>
            </div>
            <div class="col-4">
                <validated-ajax-vue-select label="Batch" name="Batch" v-model="model.batch"
                                           :rules="{required : true}" :url="batchOptions"
                                           :disabled="loading"/>
            </div>
        </div>
        <simple-table class="c-table" :data="video" :fields="fields" ref="table">
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <btn size="xs" text="View" @click="viewDetails(rowData.id)"/>
                    <btn size="xs" color="danger" text="Delete" @click="setDelete(rowData.id)"/>
                </div>
            </template>
        </simple-table>
        <modal  class="c-modal no-close"  title="Add Video" ref="addModal" width="40r" header-color="">
            <s-form @submit="submitClicked" ref="form">
                <div class="row">
                    <div class="col">
                        <validated-vue-select label="Branch" name="Branch" v-model="model.branch"
                                                   :rules="{required : true}" :options="branchOptions"
                                                   :disabled="loading"/>
                    </div>
                    <div class="col">
                        <validated-ajax-vue-select label="Batch" name="Batch" v-model="model.batch"
                                                   :rules="{required : true}" :url="batchOptions"
                                                   :disabled="loading"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <validated-input label="Title" name="Title" v-model="model.name"
                                         :rules="{required : true}" :disabled="loading"/>
                    </div>
                    <div class="col">
                        <validated-input label="Video Link" name="Video Link" v-model="model.name"
                                         :rules="{required : true}" :disabled="loading"/>
                    </div>
                </div>
                <btn size="sm" text="Save" :loading="loading" loading-text="Saving..."/>
            </s-form>
        </modal>

        <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteSuccess">
            <p>You are about to delete the link. Are you sure?</p>
            <template #loading>
                <delete-animation/>
                Please wait while we delete the link.
            </template>
        </delete-modal>
    </div>
</template>

<script>
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name: 'VideoComponent',
    data () {
        return {
            video: [],
            batchOptions: urls.vueSelect.batchOptions,
            fields: [
                {
                    name: 'title',
                    sortField: 'title',
                    title: 'Title'
                },
                {
                    name: 'link',
                    sortField: 'link',
                    title: 'Link'
                },
                {
                    name: 'date',
                    sortField: 'date',
                    title: 'Date'
                },
                {
                    name: '__slot:actions',
                    title: '',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right'
                }
            ],
            branchOptions: [
                { value: 'Clinical', label: 'Clinical' },
                { value: 'OET', label: 'OET' },
                { value: 'Volunteer', label: 'Volunteer' }
            ],
            loading: false,
            listUrl: '',
            addUrl: '',
            deleteUrl: '',
            deletingItem: {
                id: ''
            },
            model: {}
        };
    },
    methods: {
        setDelete (item) {
            this.deletingItem.id = item.id;
            this.$refs.deleteModal.show();
        },
        deleteSuccess () {
            this.$refs.deleteModal.close();
            this.deletingItem.id = '';
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        showAddModal () {
            this.model = {};
            this.$refs.addModal.show();
        },
        viewDetails (item) {
            // this.$router.push('/staff/teaching/' + item.id + '/details/');
            window.open(item.video_link);
        },
        async submitClicked () {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.admin.policy.add, that.model);
            const json = response.data;
            if (json.error === false) {
                that.formSuccess();
                that.loading = false;
            } else {
                that.formError(json);
                that.loading = false;
            }
            that.loading = false;
        },
        formSuccess () {
            this.$refs.addModal.close();
            this.$notify('Saved Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        formError (json) {
            this.$refs.form.setErrors(json.errors);
            this.$notify('Please fix the issue.', 'Error', {
                type: 'danger'
            });
        }
    }
};
</script>

<style scoped>

</style>
