<template>
    <div>
        <AdminHome/>
        <custom-vue-table class="mt-3 bs-4 c-table" title="Staff Management" :fields="fields" :url="listUrl"
                          :per-page="10" ref="table" :extra-params="{status}">
            <template #buttons>
                <drop-btn text="Filters" icon="fa fa-filter" size="sm" dropdown-width="30r">
                    <div class="m-3">
                        <validated-vue-select label="Status" name="Status" v-model="status" :options="statusOptions"
                                              :disabled="loading"/>
                    </div>
                </drop-btn>
                <btn icon="fa fa-plus" size="sm" class="ml-2" @click="showAddModal" text="Add"/>
            </template>
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <btn size="xs" color="success" v-if="rowData.is_suspended===0" text="Active" @click="suspendUser(rowData.id)"/>
                    <btn size="xs" color="danger" v-else text="Suspended" @click="activateUser(rowData.id)"/>
                    <btn size="xs" text="View" @click="viewDetails(rowData)"/>
                </div>
            </template>
            <template #role="{rowData}">
                <span v-if="rowData.role===1">Admin</span>
                <span v-if="rowData.role===2">Staff</span>
                <span v-if="rowData.role===3">Managerial Staff</span>
            </template>

            <template #footer>
                <modal class="c-modal c-p-0 no-close no-heading center" ref="userSuccessModal" width="40r">
                    <div class="success-modal">
                        <img src="../../../../assets/img/success.png" class="mb-4" alt="#">
                        <p class="fs-2 poppins-semibold mb-1">User Created Successfully</p>
                        <p class="mb-0">
                            A mail containing Login Credentials will be sent to the registered Email ID
                        </p>
                    </div>
                </modal>
                <modal no-close-on-backdrop class="c-modal" title="Add User" ref="addModal" width="45r" header-color="">
                    <s-form @submit="getModelValue" ref="form">
                        <div v-if="user===''">
                            <basic-details @role="roleChange" @model="mergeDict" ref="basicInfo"/>
                            <qualification-form @model="mergeDict" ref="qualificationInfo" v-if="staffAddForm"/>
                            <reach-info @model="mergeDict" ref="reachInfo" v-if="staffAddForm"/>
                            <btn size="sm" class="mt-2" text="Save" :loading="loading"
                                 loading-text="Saving.."/>
                        </div>
                        <div v-else>
                            <qualification-attachment-form :user="user.toString()"/>
                            <btn text="Save" type="button" @click="formSuccess" size="sm"/>
                        </div>
                    </s-form>
                </modal>
            </template>

        </custom-vue-table>
    </div>
</template>

<script>
import urls from '@/data/urls';
import axios from 'secure-axios';
import BasicDetails from './Components/FormComponents/BasicDetails';
import QualificationForm from './Components/FormComponents/QualifIcation';
import ReachInfo from './Components/FormComponents/ReachInfo';
import AdminHome from '../../../Dashboards/CustomHomePages/AdminHome';
import QualificationAttachmentForm from './Components/FormComponents/Attachments';

export default {
    name: 'StaffsListingComponent',
    components: { QualificationAttachmentForm, AdminHome, ReachInfo, QualificationForm, BasicDetails },
    data () {
        return {
            staffAddForm: false,
            status: '',
            action: '',
            user: '',
            statusOptions: [
                { value: 0, label: 'Active' },
                { value: 1, label: 'Suspended' }
            ],
            loading: false,
            listUrl: urls.admin.userList.staffList,
            fields: [
                {
                    name: 'name',
                    sortField: 'name',
                    title: 'Name'
                },
                {
                    name: '__slot:role',
                    sortField: 'role',
                    title: 'Role'
                },
                {
                    name: 'created_by.name',
                    sortField: 'created_by.name',
                    title: 'Created By'
                },
                {
                    name: 'email',
                    sortField: 'email',
                    title: 'Email'
                },
                {
                    name: 'created_date',
                    sortField: 'created_date',
                    title: 'Created Date'
                },
                {
                    name: '__slot:actions',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right',
                    title: 'Actions'
                }
            ],
            model: {}
        };
    },
    methods: {
        async suspendUser (id) {
            const that = this;
            that.loadings1 = true;
            const response = await axios.form(urls.user.suspendUsers, { user_id: id });
            const json = response.data;
            if (json.success === true) {
                that.$notify('User suspended successfully.', 'Success', {
                    type: 'success'
                });
                that.$refs.table.refreshTable();
                that.loadings1 = false;
            } else {
                this.$notify('Something went wrong, Please try again later.', 'Error', {
                    type: 'danger'
                });
            }
            that.loadings1 = false;
        },
        async activateUser (id) {
            const that = this;
            that.loadings1 = true;
            const response = await axios.form(urls.user.activate, { user_id: id });
            const json = response.data;
            if (json.success === true) {
                that.$notify('User activated successfully.', 'Success', {
                    type: 'success'
                });
                that.$refs.table.refreshTable();
                that.loadings1 = false;
            } else {
                this.$notify('Something went wrong, Please try again later.', 'Error', {
                    type: 'danger'
                });
            }
            that.loadings1 = false;
        },
        viewDetails (item) {
            this.$router.push('/director-function/user-management/' + item.id + '/details/');
        },
        showAddModal () {
            this.$refs.addModal.show();
            this.user = '';
        },
        roleChange (response) {
            if (response === '1') {
                this.staffAddForm = false;
                this.action = urls.admin.directorFunction.admin.add;
            } else {
                this.staffAddForm = true;
                this.action = urls.admin.directorFunction.staff.add;
            }
        },
        mergeDict (item) {
            this.model = { ...this.model, ...item };
        },
        getModelValue () {
            if (this.staffAddForm) {
                this.$refs.qualificationInfo.getModel();
                this.$refs.reachInfo.getModel();
            }
            this.$refs.basicInfo.getModel();
            this.submitClicked();
        },
        async submitClicked () {
            const that = this;
            that.loading = true;
            const response = await axios.form(that.action, that.model);
            const json = response.data;
            if (json.error === false) {
                if (json.data.role !== '1') {
                    that.addAttachments(json);
                } else {
                    that.formSuccess();
                }
                that.loading = false;
            } else {
                that.formError(json);
                that.loading = false;
            }
            that.loading = false;
        },
        addAttachments (json) {
            this.user = json.data.id;
        },
        formSuccess () {
            this.$refs.userSuccessModal.show();
            this.$refs.addModal.close();
            this.$refs.table.refreshTable();
        },
        formError (json) {
            this.$refs.form.setErrors(json.errors);
            this.$notify('Please fix the issue.', 'Error', {
                type: 'danger'
            });
        }
    }
};
</script>

<style scoped>

</style>
