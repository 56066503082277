<template>
    <div>
        <AdminHome/>
        <pending-members-listing class="bs-4"/>
        <volunteer-listing class="bs-4"/>
    </div>
</template>

<script>
import PendingMembersListing from './Components/PendingMembers';
import VolunteerListing from './Components/Volunteers';
import AdminHome from '../../../Dashboards/CustomHomePages/AdminHome';

export default {
    name: 'ViewPage',
    components: { AdminHome, VolunteerListing, PendingMembersListing }
};
</script>

<style scoped>

</style>
