<template>

    <div class="twitter-feeds">
        <div class="panel-body">
            <a class="twitter-timeline" data-chrome="noheader nofooter noborders transparent noscrollbar" data-limit="2"
               data-width="100%"
               href="https://twitter.com/reachenw"></a>
        </div>
    </div>

</template>

<script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>

<script>
export default {
    mounted () {
        this.$nextTick(() => {
            twttr.widgets.load();
        });
    }
};
</script>
<style>

</style>
