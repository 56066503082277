<template>
    <div class="p-3 bs-4">
        <h4 class="mb-3">Reache Info</h4>
        <div class="row">
            <div class="col-4">
                <label>Joining Date</label>
                <div class="form-control round-1 text-muted">{{ data.date_of_joining_reache }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StaffReachInfo',
    props: { data: { type: Object } }
};
</script>
