<template>
    <div>
        <AdminHome/>
        <div class="fl-eq p-0 of-h bs-4 mb-3">
            <div class="p-4 w-65r br-1">
                <h5 class="mb-3">Select Date</h5>
                <FullCalendar ref="fullCalendar" :options="calendarOptions"/>
            </div>
            <div class="p-4 w-30r bg-primary">
                <div class="fl-te-c mb-3">
                    <h5 class="text-white">Time Table</h5>
                    <span>08-09-2021</span>
                </div>
                <perfect-scrollbar>
                    <div class="mr-3" v-for="i in 10" v-bind:key="i">
                        <span>9:10 AM</span>
                        <div class="fl-te-c text-black-50 p-3" style="background: #eaf3fc;">
                            <span style="color: black;">Class Name, Batch</span>
                            <span style="color: black;">9:10 AM - 10:10 AM</span>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>
    </div>
</template>

<script>
import '@fullcalendar/core/vdom';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import AdminHome from '../../Dashboards/CustomHomePages/AdminHome';

export default {
    name: 'TimeTable',
    components: { AdminHome, FullCalendar, PerfectScrollbar },
    data () {
        return {
            calendarOptions: {
                plugins: [interactionPlugin, dayGridPlugin],
                initialView: 'dayGridMonth',
                selectable: false,
                height: '600px',
                headerToolbar: {
                    center: 'title',
                    left: ''
                },
                events: [],
                scrollHeight: window.innerHeight
            }
        };
    },
    updated () {
        this.updateScroll();
    },
    methods: {
        updateScroll () {
            const element = document.getElementById('scroll');
            element.scrollTop = element.scrollHeight;
        }
    }
};
</script>

<style scoped>
.ps {
    height: 630px;
}

.ps__rail-x {
    display: none !important;
}

</style>
