<template>
    <main>
        <div class="container">
            <div class="row py-5 px-0">
                <div class="col-12 col-lg-8">

                    <h2 class="text-uppercase poppins-bold mb-4">CONTACT US</h2>

                    <article class="mb-4 pb-4">
                        REACHE North West <br>
                        Salford Royal Hospital <br>
                        Stott Lane <br>
                        Salford <br>
                        M6 8HD <br>

                        Tel: 0161 206 4201 <br>
                        Fax: 0161 206 0902 <br>
                        Email: <a href="mailto:reache@manchester.ac.uk" class="text-danger">reache@manchester.ac.uk</a>
                        <br>
                    </article>

                    <article class="mb-4 pb-4">
                        Please enter your message below – we will do our best to respond quickly. Occasionally there
                        have been messages that have gone missing. Please contact us by email if you do not get a reply
                        within a week.
                        <br>
                        <br>
                        Only the staff at REACHE NW will be able to see your message, it will not be displayed on the
                        web site. We will only use your name and email address to reply to your message, your details
                        will not be passed to anyone else.
                    </article>

                    <div class="row mb-5">
                        <div class="col-12 contact-form">
                            <transition name="fade" mode="out-in">
                                <div class="text-center p-4 col-12 mt-4 fl-j-c fl-x-c" v-if="state === 'S'" key="'S">
                                    <div class="text-success">
                                        <div class="text-center mb-4">
                                            <i class="fa fa-check-square-o  fa-3x" aria-hidden="true"></i>
                                        </div>
                                        <div class="text-center">
                                            <p class="fs-4">Thank you <strong>{{ name }}</strong> for Contacting us us</p>
                                            We have received your request and will respond to you within 24 hours. For
                                            urgent enquiries please call us on the telephone numbers provided.
                                        </div>
                                    </div>
                                </div>

                                <div class="text-center p-4 col-12 mt-4 fl-j-c fl-x-c" v-else-if="state === 'F'"
                                     key="'F">
                                    <div class="text-danger">
                                        <div class="text-center mb-4">
                                            <i class="fa fa-window-close  fa-3x" aria-hidden="true"></i>
                                        </div>
                                        <div class="text-center">
                                            <p class="fs-4"><strong>OOPS!</strong></p>
                                            Something has gone wrong, Please try again.
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center p-4 col-12 mt-4 fl-j-c fl-x-c" v-else-if="state === 'L'"
                                     key="'L">
                                    <div class="text-center p-4">
                                        <loading-animation/>
                                        <p class="c-font-19 mb-4 proxim-light">Please wait while communicate with
                                            the
                                            server</p>
                                    </div>
                                </div>
                                <s-form class="contact-form row" v-else role="form" @submit="onSubmit"
                                        autocomplete="off">
                                    <div class="col-12">
                                        <validated-input name="Name" v-model="name" shadow="0" placeholder=""
                                                         label="Name(required)" :rules="rules.first_name" required/>
                                    </div>
                                    <div class="col-12">
                                        <validated-input type="email" name="Email" v-model="email" shadow="0"
                                                         placeholder=""
                                                         label="Email(required)" :rules="rules.email_id"/>
                                    </div>
                                    <div class="col-12">
                                        <validated-text-area label="Message" v-model="message" :rules="rules.message1"
                                                             shadow="0"/>
                                    </div>
                                    <div class="col-12 mt-4">
                                        <button class="lego-btn w-50 bg-primary round-1" type="submit">
                                            Submit
                                        </button>
                                    </div>
                                </s-form>
                            </transition>
                        </div>
                    </div>

                    <share-this></share-this>
                </div>
                <div class="col-12 col-lg-4">
                    <location-and-info></location-and-info>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import LocationAndInfo from './components/support-pages/LocationAndInfo.vue';
import axios from 'axios';
import LoadingAnimation from './components/LoadingAnimation';

export default {
    name: 'contact',
    props: {},
    components: {
        LoadingAnimation,
        LocationAndInfo
    },
    data () {
        return {
            state: 'D',
            name: '',
            email: '',
            message: '',
            rules: {
                first_name: {
                    required: true
                },
                email_id: {
                    required: true
                },
                message1: {
                    required: true
                }
            }
        };
    },
    methods: {
        onSubmit () {
            const bodyFormData = new FormData();
            bodyFormData.append('name', this.name);
            bodyFormData.append('email', this.email);
            bodyFormData.append('message', this.message);

            const that = this;
            this.state = 'L';

            axios({
                method: 'post',
                url: 'https://reache.xeoscript.com/mail.php',
                data: bodyFormData,
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(function (response) {
                that.onSubmitSuccess(response);
            }).catch(function (response) {
                that.onSubmitFailed(response);
            });
        },

        onSubmitSuccess () {
            this.state = 'S';
        },

        onSubmitFailed () {
            this.state = 'F';
        }
    }
};
</script>

<style scoped>

</style>
