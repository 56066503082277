<template>
    <main>
        <div class="container">
            <div class="row py-5 px-0">
                <div class="col-12 col-lg-8">
                    <h2 class="text-uppercase poppins-bold mb-4">LINKS TO RESOURCES</h2>
                    <article class="mb-5">
                        <a target="_blank" href="https://new-learning.bmj.com/" class="text-danger">BMJ Learning</a>
                        <br><br>
                        <a target="_blank" href="https://learning.bmj.com/learning/info/BMJ_Portfolio.html"
                           class="text-danger">BMJ Portfolio</a>
                    </article>
                    <article class="mb-5">
                        <h3 class="mb-3">RECENT PRESENTATIONS FROM MEDICAL UPDATE:</h3>
                        Ayman Jundi, Consultant in Emergency Medicine in Preston, came on 24 February 2016. The slides
                        from his session are below:
                        <br><br>

                        <a target="_blank" href="https://reache.xeoscript.com/pdf/ayman-jundi-burns-2016-02.pptx" class="text-danger" download> Ayman Jundi – Burns</a>
                        <br><br>
                        Lesley Brisoe, from Edge Hill University in Ormskirk, near Liverpool, came on 17 February 2016
                        and shared some teaching. Links from this session are below:
                        <br><br>
                        <a href="https://reache.xeoscript.com/pdf/huda-badri-lung-cancer-jan16.pptx" class="text-danger" target="_blank" download>Lesley Briscoe – Normal Birth</a>
                        <br><br>
                        <a href="https://reache.xeoscript.com/pdf/lesley-briscoe-mechanism-of-oa.doc" class="text-danger" download>Lesley Briscoe – Mechanism of OA</a>
                        <br><br>
                        <a href="https://pathways.nice.org.uk/pathways/intrapartum-care" class="text-danger">Link to
                            NICE Clinical Guideline on intrapartum care</a>
                    </article>
                    <article class="mb-3">
                        <h3 class="mb-3">REACHE ATTENDANCE POLICY AND FORM</h3>
                        <a href="https://reache.xeoscript.com/pdf/reache-membership-attendance-policy-issued-version-jan-2015.doc" target="_blank">
                            <img src="../assets/img/worddoc.gif" class="mb-3 word-icon-img" alt="">
                        </a>
                        <br>
                        Reache Membership Attendance Policy Issued Version Jan 2015
                    </article>
                    <share-this></share-this>
                </div>
                <div class="col-12 col-lg-4">
                    <location-and-info></location-and-info>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import LocationAndInfo from './components/support-pages/LocationAndInfo.vue';
// import JoiningForm from './components/support-pages/JoiningForm.vue';

export default {
    name: 'About',
    components: {
        LocationAndInfo
    }
};
</script>

<style scoped>
.word-icon-img {
    width: 60px;
}
</style>
