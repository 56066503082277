<template>
    <div class="card mt-3 p-4 bs-4">
        <h4 class="mb-3">Update info</h4>
        <div class="row">
            <div class="col-4">
                <validated-vue-select label="Profile info to Update" name="Profile info Update" v-model="model.branch"
                                          :rules="{required : true}" :options="branchOptions"
                                          :disabled="loading"/>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <validated-date-picker label="Date" name="Date" v-model="model.date"
                                                   :disabled="loading" :rules="{required : true}"/>
            </div>
            <div class="col">
                <validated-file-input label="File Upload" name="File Upload" v-model="model.file"
                                          :disabled="loading"/>
            </div>
            <div class="col">
                <validated-input label="Remarks" name="Remarks" v-model="model.last_name"
                                             :rules="{required : true}"
                                             :disabled="loading"/>
            </div>
        </div>
        <div class="row">
            <div class="col-4 btn-group">
                <btn size="sm" class="btn-basic-b" text="Add" :loading="loading" loading-text="Processing.."/>
                <btn size="sm" class="btn-basic-b" text="Cancel" :loading="loading" loading-text="Processing.."/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProfileHistory',
    data () {
        return {
            loading: false,
            branchOptions: [
                { value: 'Clinical', label: 'Clinical' },
                { value: 'OET', label: 'OET' },
                { value: 'Volunteer', label: 'Volunteer' }
            ],
            batchOptions: '',
            model: ''
        };
    }
};
</script>

<style scoped>

</style>
