<template>
    <custom-vue-table class="bs-4 m-3 c-table" title="Scheduled Meetings" :fields="fields" :url="listUrl" :per-page="10"
                    ref="table">
        <template #buttons>
            <drop-btn text="Filters" icon="fa fa-filter" size="sm" dropdown-width="30r">
                <div class="m-3">
                    <validated-ajax-vue-select label="Batch" name="Batch" v-model="batch" :url="batchOptions"
                                      :disabled="loading"/>
                </div>
            </drop-btn>
            <btn icon="fa fa-plus" class="ml-2" size="sm" @click="$refs.addModal.show()" text="Add"/>
        </template>
        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <btn size="xs" text="View" @click="viewLink(rowData.link)"/>
            </div>
        </template>

        <template #footer>
            <modal  class="c-modal no-close"  title="Add Meeting" ref="addModal" width="40r" header-color="">
                <s-form @submit="submitClicked" ref="form">
                    <div class="row">
                        <div class="col">
                            <validated-vue-select label="Branch" name="Branch" v-model="model.branch"
                                                       :rules="{required : true}" :options="branchOptions"
                                                       :disabled="loading"/>
                        </div>
                        <div class="col">
                            <validated-ajax-vue-select label="Batch" name="Batch" v-model="model.batch"
                                                       :rules="{required : true}" :url="batchOptions"
                                                       :disabled="loading"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <validated-input label="Title" name="Title" v-model="model.name"
                                             :rules="{required : true}" :disabled="loading"/>
                        </div>
                        <div class="col">
                            <validated-input label="Link" name="Link" v-model="model.name"
                                             :rules="{required : true}" :disabled="loading"/>
                        </div>
                    </div>
                    <btn size="sm" text="Save" :loading="loading" loading-text="Saving..."/>
                </s-form>
            </modal>
        </template>

    </custom-vue-table>
</template>

<script>
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name: 'ScheduledMeetings',
    data () {
        return {
            batch: '',
            branchOptions: [
                { value: 'Clinical', label: 'Clinical' },
                { value: 'OET', label: 'OET' },
                { value: 'Volunteer', label: 'Volunteer' }
            ],
            batchOptions: '',
            loading: false,
            listUrl: urls.admin.masterData.country.list,
            addUrl: urls.admin.masterData.country.add,
            fields: [
                {
                    name: 'sl_no',
                    sortField: 'sl_no',
                    title: 'Sl No'
                },
                {
                    name: 'title',
                    sortField: 'title',
                    title: 'Meeting Title'
                },
                {
                    name: 'date',
                    sortField: 'date',
                    title: 'Date & Time'
                },
                {
                    name: 'batch',
                    sortField: 'batch',
                    title: 'Batch Name'
                },
                {
                    name: '__slot:actions',
                    title: '',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right'
                }
            ],
            model: {
                name: ''
            }
        };
    },
    methods: {
        viewLink (id) {

        },
        async submitClicked () {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.admin.policy.add, that.model);
            const json = response.data;
            if (json.error === false) {
                that.formSuccess();
                that.loading = false;
            } else {
                that.formError(json);
                that.loading = false;
            }
            that.loading = false;
        },
        formSuccess () {
            this.$refs.addModal.close();
            this.$notify('Saved Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        formError (json) {
            this.$refs.form.setErrors(json.errors);
            this.$notify('Please fix the issue.', 'Error', {
                type: 'danger'
            });
        }
    }
};
</script>

<style scoped>

</style>
