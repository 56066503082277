<template>
    <volunteer-chat-box/>
</template>

<script>
import VolunteerChatBox from '../Components/VolunteerChatBox';
export default {
    name: 'VolunteerHome',
    components: { VolunteerChatBox }
};
</script>

<style scoped>

</style>
