<template>
    <main>
        <div class="container">
            <div class="row py-5 px-0">
                <div class="col-12 col-lg-8">
                    <h2 class="text-uppercase poppins-bold mb-4">SUPPORT AVAILABLE</h2>
                    <article class="mb-5">
                        REACHE North West carries out training and offers pastoral support, provides facilities for
                        study and runs additional projects for Refugee Healthcare Professionals (RHPs). Follow the links
                        below for more information.
                        <br><br>
                        All classes are free for REACHE North West members. In addition, members have access to a number
                        of resources to support their studies:
                        <br><br>
                        <ul class="pl-5">
                            <li><a target="_blank" class="text-danger" href="https://www.srft.nhs.uk/for-professionals/library/">Salford
                                Royal NHS Foundation Trust Library (click to visit Library web page)</a></li>
<!--                            <li>Fully-equipped training rooms with audio-visual equipment and video-conferencing-->
<!--                            </li>-->
                            <li>Manikins and equipment for practical examination practice</li>
                        </ul>
                        <div class="lg-img-contain fl-x fl-j-c py-3">
                            <img src="../assets/img/reachecentre12.gif" alt="" class="mb-3">
                        </div>
                        <h3 class="text-uppercase mb-4">PASTORAL SUPPORT</h3>
                        <ul class="pl-5">
                            <li>Tutors available for pastoral support and career guidance</li>
                            <li>Limited assistance with travel costs where appropriate</li>
                            <li>Assistance with appropriate charitable applications</li>
                        </ul>
                        <br><br>
                        Appointments can be made to see tutors by
                        <router-link class="text-danger" to="/Contact/">telephone</router-link>
                        or <a class="text-danger" title="mail to reache@manchester.ac.uk"
                              href="mailto:reache@manchester.ac.uk">email</a>
                    </article>
                    <!--English Support-->
                    <article class="mb-5">
                        <h3 class="text-uppercase mb-4">English Support</h3>
                        <h4 class="mb-2">Occupational English Test (OET) preparation</h4>
                        Through formal weekly courses and intensive sessions, with supporting materials. New students
                        will be given an English language assessment before being allocated to an English class.
                        <br><br>
                        <h4 class="mb-2">English practice in a medical context</h4>
                        The PLAN (Patients – Listening to, Advising and Negotiating with them) meetings provide the
                        opportunity for English practice with UK medical students taking the role of patients.

                    </article>
                    <!--MEDICAL EXAMINATION PREPARATION-->
                    <article class="mb-5">
                        <h3 class="text-uppercase mb-4">MEDICAL EXAMINATION PREPARATION</h3>
                        <div class="row p-0">
                            <div class="col-12 col-lg-4 mb-3 mb-lg-0">
                                <img src="../assets/img/plabteaching.jpg" alt="">
                            </div>
                            <div class="col-12 col-lg-8">
                                <h4 class="mb-2">ECK: Essential Clinical Knowledge</h4>
                                <p>
                                    Half-day study sessions based around clinical cases developed specifically for
                                    Reache North West doctors to help adapt clinical reasoning to UK practice in the
                                    context
                                    of British culture and the National Health Service (NHS).
                                </p>
                                <h4 class="mb-2">PLAB Medical Update</h4>
                                <p>
                                    Preparatory sessions on medical topics given by visiting hospital clinicians and
                                    Reache North West tutors, with practice of PLAB 1 style questions related to the
                                    topic.
                                </p>
                                <h4 class="mb-2">PLAB 2</h4>
                                <p>
                                    The Reache North West centre has manikins and other materials needed to practice for
                                    PLAB 2. Reache members may be funded to attend an external preparatory PLAB 2
                                    course.
                                </p>
                                <h4 class="mb-2">PLAN</h4>
                                <p>
                                    (Patients – Listening to, Advising and Negotiating with them). Includes PLAB 2 style
                                    communication stations, practising with UK medical students.
                                </p>
                            </div>
                        </div>
                    </article>
                    <!--Getting into work-->
                    <article class="mb-5">
                        <h3 class="text-uppercase mb-4">GETTING INTO WORK</h3>
                        <h4 class="mb-2">Tutor support</h4>
                        <p>
                            Assistance with CV preparation, job applications and interview skills. <a href=""
                                                                                                      class="text-danger">CV
                            resources here</a>.
                            <br><br>
                            If you make an appointment to review your CV, please bring an electronic copy with you, or
                            <a href="mailto:reache@manchester.ac.uk" class="text-danger">email</a> it in advance.

                        </p>
                        <h4 class="mb-2">Preparing for the NHS</h4>
                        <p>
                            Through many of the classes, a consistent aim is to give an insight into the context of
                            healthcare delivery in the NHS and differences between the UK and other countries.

                        </p>
                        <h4 class="mb-2">Work Placements</h4>
                        <p>
                            Opportunities to gain skills, confidence and UK references from observerships and supported
                            employment.

                        </p>
                    </article>
                    <share-this></share-this>
                </div>
                <div class="col-12 col-lg-4">
                    <location-and-info></location-and-info>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import LocationAndInfo from './components/support-pages/LocationAndInfo.vue';

export default {
    components: {
        LocationAndInfo
    }
};
</script>
