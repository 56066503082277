<template>
    <main>
        <div class="container">
            <div class="row py-5 px-0">
                <div class="col-12 col-lg-8">
                    <h2 class="text-uppercase poppins-bold mb-4">CLINICAL COMMUNICATION</h2>
                    <article class="mb-5">
                        <h3 class="mb-3">EFFECTIVE HANDOVER</h3>
                        <div class="pl-5">
                            <a target="_blank" href="https://www.nottingham.ac.uk/nmp/sonet/rlos/patientsafety/sbar/"
                               class="text-danger">SBAR interactive overview</a>
                        </div>
                    </article>
                    <article class="mb-5">
                        <h3 class="mb-3">PORTFOLIOS AND CONTINUING PROFESSIONAL DEVELOPMENT</h3>
                        <ul class="pl-lg-5 list-unstyled">
                            <li class="mb-4">
                                <a target="_blank" href="http://portfolio.bmj.com/portfolio/login.html"
                                   class="text-danger">BMJ
                                    Portfolio</a> – useful for doctors who are not in training posts to develop an
                                online log of
                                reflections on clinical practice and learning. By adjusting the settings, you can tag
                                every
                                entry with one of the GMC Good Medical Practice Domains. We have prepared some
                                additional
                                tips on what should be in a portfolio for our Reache North West members: see
                                <router-link to="/links-to-resources/portfolio-cv-and-pdp/" class="text-danger" >here</router-link>. The
                                University of Nottingham has some information on <a
                                target="_blank"
                                href="https://www.nottingham.ac.uk/nmp/sonet/rlos/placs/critical_reflection/"
                                class="text-danger">reflective writing</a>.
                            </li>
                            <li class="mb-4">
                                <a target="_blank" href="https://new-learning.bmj.com/" class="text-danger">BMJ
                                    Learning</a> and <a
                                href="https://www.medscape.org/" class="text-danger">Medscape</a> continuing
                                professional development (CPD) resources for
                                learning
                                modules. Note: Medscape is an American resource but still useful (and also free).
                            </li>
                            <li class="mb-4">
                                <a target="_blank"
                                   href="https://www.gmc-uk.org/ethical-guidance/ethical-guidance-for-doctors/good-medical-practice"
                                   class="text-danger">GMC Good Medical Practice</a> – essential reading for every
                                doctor and a resource to keep
                                referring back to! Remember you have signed up to follow this code with your GMC
                                registration. Look particularly at the 29 April 2014 update to the guidance that
                                includes a
                                section about knowledge of the English language. You will find guidance and statements
                                contained within GMC Good Medical Practice that help guide you in challenging
                                situations,
                                including ethical dilemmas and and practising in a different cultural setting.
                                <a href="https://www.gmc-uk.org/gmpinaction/" target="_blank" class="text-danger">Good Medical Practice in Action</a> is a collection of interactive case studies: these can be a good
                                learning
                                resource and material for reflection and so don’t forget to add a reflective entry to
                                your
                                portfolio after you complete each case.
                            </li>
                            <li class="mb-4">
                                <a target="_blank"
                                   href="https://www.bma.org.uk/advice-and-support/international-doctors"
                                   class="text-danger">Medical defence and indemnity information</a>

                            </li>
                        </ul>
                    </article>
                    <article class="mb-3">
                        <h3 class="mb-3">COMMUNICATION SKILLS</h3>
                        <ul class="pl-lg-5 list-unstyled">
                            <li class="mb-4">

                                <a target="_blank" href="https://www.skillscascade.com/models.html" class="text-danger">SkillsCascade.com</a>
                                overview of consultation models from the last 40 years.
                            </li>
                            <li class="mb-4">
                                <a target="_blank"
                                   href="https://www.bradfordvts.co.uk/online-resources/0200-consultation/calgary-cambridge/"
                                   class="text-danger">Calgary Cambridge Guide to the Medical Interview</a> on the
                                Bradford VTS website.See in
                                particular the “basic framework”, the “disease-illness model” and the “quick reference
                                guide”
                            </li>
                            <li class="mb-4">
                                Maguire P, Pitceathly C. Key communication skills and how to acquire them. BMJ
                                2002;325:697-700
                            </li>
                            <li class="mb-4">
                                BMJ article available <a target="_blank" href="https://www.bmj.com/content/325/7366/697"
                                                         class="text-danger">on the BMJ website</a> and as a
                                <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC1124224/" class="text-danger">free
                                    PubMed Central resource</a>.
                            </li>
                            <li class="mb-4">
                                <a target="_blank" href="https://new-learning.bmj.com/course/6057021"
                                   class="text-danger">BMJ Learning
                                    module (general skills)</a>
                            </li>
                            <li class="mb-4">
                                <a href="http://sdm.rightcare.nhs.uk/" class="text-danger">Shared Decision Making</a> –
                                working in partnership with your patient includes identifying
                                the patient’s ideas, concerns and expections (ICE) and the effect of the illness on the
                                patient and then using this to enter a meaningful discussion with the patient to decide
                                on a course of action. Shared Decision Making training, resources and tools can help you
                                apply these principles to your interactions with each patient. There is further
                                information on the <a target="_blank"
                                                      href="https://www.england.nhs.uk/ourwork/pe/sdm/tools-sdm/"
                                                      class="text-danger">NHS England Tools for Shared Decision
                                Making</a> and the <a target="_blank"
                                                      href="http://www.advancingqualityalliance.nhs.uk/sdm/"
                                                      class="text-danger">AQUA website</a>.
                            </li>
                            <li class="mb-4">
                                The <a
                                target="_blank"
                                href="http://www.cetl.org.uk/learning/feedback_opportunities/data/downloads/breaking_bad_news.pdf"
                                class="text-danger">SPIKES model</a> for breaking bad news – apply the same skills of
                                active listening and
                                principles of understanding the patient’s ICE with this tool adapted to the situation of
                                breaking bad news. Here is a link to the <a
                                target="_blank" href="https://theoncologist.alphamedpress.org/content/5/4/302.full"
                                class="text-danger">original
                                paper</a> and
                                <a target="_blank" href="https://www.youtube.com/watch?v=ftgNapAfV6Q"
                                   class="text-danger">this video</a>
                                helps explain it
                                further:
                            </li>
                            <li class="mb-4">
                                <iframe src="https://www.youtube.com/embed/ftgNapAfV6Q"
                                        title="YouTube video player" frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen></iframe>
                            </li>
                            <li class="mb-4">
                                The australian website
                                <a target="_blank"
                                   href="http://www.doctorsspeakup.com/content/pronunciation"
                                   class="text-danger">Doctors Speak Up</a> has some
                                well-presented resources for
                                professionals from non-English speaking backgrounds. We have a document about the usage
                                of certain modal verbs in English that you may also find useful:
                                <a target="_blank"
                                   href="https://reache.xeoscript.com/pdf/english-modal-verbs-table.docx"
                                   class="text-danger" download>English
                                    Modal Verbs
                                    Table.</a>
                            </li>
                        </ul>

                    </article>
                    <share-this></share-this>
                </div>
                <div class="col-12 col-lg-4">
                    <location-and-info></location-and-info>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import LocationAndInfo from './components/support-pages/LocationAndInfo.vue';
// import JoiningForm from './components/support-pages/JoiningForm.vue';

export default {
    components: {
        LocationAndInfo
    }
};
</script>
<style lang="scss" scoped>
iframe {
    width: 100%;
    height: 210px;
}

@media (min-width: 992px) {
    iframe {
        height: 380px;
    }
}
</style>
