<template>
    <main>
        <div class="container">
            <div class="row py-5 px-0">
                <div class="col-12 col-lg-8">
                    <h2 class="text-uppercase poppins-bold mb-4">ABOUT</h2>
                    <article class="mb-5">
                        Reache (Refugee and Asylum Seekers Centre for Healthcare Professionals Education) North West
                        has been set up to assist Refugee and asylum seeking Healthcare Professionals (RHPs) to
                        register their qualifications in the UK. It has an education centre in Salford Royal NHS
                        Foundation Trust. We also assist RHPs who have permission to work in seeking professional
                        employment in the UK National Health Service (NHS).
                        <br><br>
                        <div class="lg-img-contain fl-x fl-j-c py-3">
                            <img src="../assets/img/englandnorthwest.gif" alt="Map of England Northwest"
                                 class="mb-3">
                        </div>
                        <br><br>
                        Reache works with local and national organisations in the NHS, higher education and refugee
                        support, and with agencies such as JobCentre Plus and Manchester City Council.
                        <br><br>
                        The centre and its activities are funded by <a href="http://nw.hee.nhs.uk/" target="_blank"
                                                                       class="text-danger">NHS Health Education
                        North West</a>, and receives
                        much appreciated support from <a href="https://www.srft.nhs.uk/" target="_blank"
                                                         class="text-danger">Salford Royal NHS Foundation Trust</a>.
                    </article>
                    <article class="moto mb-5">
                        <h2 class="font-weight-bold mb-4 text-uppercase pb-3 head text-primary">
                            Our Moto
                        </h2>
                        <div class=" fs-1 fl-x fl-j-c font-weight-bold ">
                            With respect and compassion, we use a holistic approach to provide the highest quality
                            education, pastoral and communication skills program, which supports refugee and asylum
                            seeker healthcare professionals to work in the NHS.
                        </div>
                    </article>
                    <article class="mb-3">
                        <h3 class="mb-3">
                            AIMS AND OBJECTIVES
                        </h3>
                        <ul class="pl-5 mb-3">
                            <li>Increase the number of refugee healthcare professionals joining the NHS workforce
                            </li>
                            <li>Manage and co-ordinate this work across the NHS North West area</li>
                            <li>Develop partnership working with stakeholders locally and nationally</li>
                            <li>Seek further funding to develop the provision and scope of the service</li>
                            <li>Deliver advice training and support to refugee healthcare professionals on an
                                individual and a group basis
                            </li>
                            <li>Provision of a friendly mutually supportive environment at the centre, encouraging
                                refugee healthcare professionals to participate actively in contributing to the
                                service as they progress
                            </li>
                        </ul>
                    </article>
                    <share-this></share-this>
                </div>
                <div class="col-12 col-lg-4">
                    <location-and-info></location-and-info>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import LocationAndInfo from './components/support-pages/LocationAndInfo.vue';
// import JoiningForm from './components/support-pages/JoiningForm.vue';

export default {
    name: 'About',
    components: {
        LocationAndInfo
    }
};
</script>

<style scoped lang="scss">
.moto {
    background-color: #e5eff9;
    text-align: center;
    padding: 40px 30px;

    .head {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            height: 1px;
            width: 80%;
            background: var(--color-primary);
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }
}
</style>
