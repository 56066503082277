<template>
    <div class="fl-eq p-0 of-h bs-4 mb-3">
        <div class="w-30r br-1">
            <h5 class="p-4 bb-2 mb-0">Message Board</h5>
            <div class="ml-3 mr-3">
                <div class="fl-x bb-1">
                    <div class="image1 mt-3 p-3 bg-3"></div>
                    <div style="overflow-wrap: break-word;" class="p-3 mb-1 w-45r fl-eqh">
                        <div>
                            <b>Bibin</b>
                            <btn class="ml-2 pl-3 pr-3" size="xs" text="new"/>
                        </div>
                        <span>32 sec ago 4:51 PM, 12 June 21</span>
                    </div>
                </div>
                <div class="fl-x bb-1">
                    <div class="image1 mt-3 p-3 bg-3"></div>
                    <div style="overflow-wrap: break-word;" class="p-3 mb-1 w-45r fl-eqh">
                        <div>
                            <b>Sanjay</b>
                            <btn class="ml-2 pl-3 pr-3" size="xs" text="new"/>
                        </div>
                        <span>32 sec ago 4:51 PM, 12 June 21</span>
                    </div>
                </div>
                <div class="fl-x bb-1">
                    <div class="image1 mt-3 p-3 bg-3"></div>
                    <div style="overflow-wrap: break-word;" class="p-3 mb-1 w-45r fl-eqh">
                        <div>
                            <b>Vivek</b>
                            <btn class="ml-2 pl-3 pr-3" size="xs" text="new"/>
                        </div>
                        <span>32 sec ago 4:51 PM, 12 June 21</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-4 w-65r">
            <perfect-scrollbar id="scroll">
                <div class="w-50r">
                    <div class="fl-x">
                        <div class="image p-3 bg-3"></div>
                        <div style="overflow-wrap: break-word;" class="p-3 bg-3 ml-3 mb-3 w-45r">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            versions of Lorem Ipsum.
                        </div>
                    </div>
                    <div class="fl-x">
                        <div class="image p-3 bg-3"></div>
                        <div style="overflow-wrap: break-word;" class="p-3 bg-3 ml-3 mb-3 w-45r">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            versions of Lorem Ipsum.
                        </div>
                    </div>
                </div>
                <div style="float: right;" class="w-50r mr-3">
                    <div class="fl-x">
                        <div style="overflow-wrap: break-word;" class="p-3 bg-3 mr-3 mb-3 w-45r">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            versions of Lorem Ipsum.
                        </div>
                        <div class="image p-3 bg-3"></div>
                    </div>
                    <div class="fl-x">
                        <div style="overflow-wrap: break-word;" class="p-3 bg-3 mr-3 mb-3 w-45r">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            versions of Lorem Ipsum.
                        </div>
                        <div class="image p-3 bg-3"></div>
                    </div>
                </div>
            </perfect-scrollbar>
            <div>
                <b-form :save-url="addUrl" ref="form" v-slot="{model}">
                    <div class="row mt-3">
                        <div class="col-11">
                            <textarea-input v-model="model.message" placeholder="Send Message"
                                            :rules="{required:false}"/>
                        </div>
                        <div class="col-1">
                            <save-btn text="" class="mt-2 bg-primary" color="" size="md"
                                      icon="fa fa-paper-plane"/>
                        </div>
                    </div>
                </b-form>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'VolunteerChatBox',
    data () {
        return {
            addUrl: ''
        };
    }
};
</script>

<style scoped>
.image {
    display: inline-block;
    background: url(https://www.facebook.com/images/fb_icon_325x325.png);
    background-position: 50% 50%;
    background-size: cover;
    height: 60px;
    width: 60px;
    border-radius: 60px;
}

.image1 {
    display: inline-block;
    width: 85px;
    height: 57px;
    background: url(https://www.facebook.com/images/fb_icon_325x325.png);
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 50%;
}
</style>
