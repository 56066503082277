<template>
    <main>
        <div class="container">
            <div class="row py-5 px-0">
                <div class="col-12 col-lg-8">
                    <h2 class="text-uppercase poppins-bold mb-4">How To Join</h2>
                    <article class="mb-5">
                        Reache Northwest has been set up to help you if you are: <br>
                        <ul class="pl-5">
                            <li>a doctor or a nurse; and</li>
                            <li>a refugee or asylum-seeker; and</li>
                            <li>you are living in the North West of England.</li>
                        </ul>
                        <br>
                        We may also be able to offer help to refugee health professionals living outside the North West
                        who can travel to the centre for regular classes.
                        <br><br>
                        If you are eligible and would like to apply, please contact us by post, email, or through the
                        Contact form below and ask for our Application form.
                        <joining-form></joining-form>
                    </article>
                    <share-this></share-this>
                </div>
                <div class="col-12 col-lg-4">
                    <location-and-info></location-and-info>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import LocationAndInfo from './components/support-pages/LocationAndInfo.vue';
import JoiningForm from './components/support-pages/JoiningForm.vue';

export default {
    components: {
        LocationAndInfo,
        JoiningForm
    }
};
</script>
