<template>
    <div>
        <h5 class="mt-4 mb-4">Qualification Info</h5>
        <div class="row">
            <div class="col">
                <validated-vue-select :options="professionOptions" label="Professional Qualifications"
                                           name="Professional Qualifications"
                                           v-model="model.professional_qualifications" :rules="{required : true}"
                                           :disabled="loading"/>
            </div>
            <div class="col" style="margin-top: 2rem;">
                <btn text="Add" type="button" @click="$refs.addQualificationModal.show()" icon="fa fa-plus" size="sm"/>
            </div>
        </div>
        <modal no-close-on-backdrop class="c-modal no-close" title="Add Qualification" ref="addQualificationModal" width="30r"
               header-color="">
            <s-form @submit="submitQualification" ref="form">
                <validated-input label="Profession" name="Profession" v-model="model1.name"
                                 :rules="{required : true}"
                                 :disabled="loading"/>

                    <div class="fl-x-bc mt-5 mb-3">
                        <btn size="sm" text="Save" :loading="loading" loading-text="Saving.."/>
                        <btn size="sm" color="danger" class="ml-2" @click="$refs.addQualificationModal.close()" text="Cancel" type="button"/>
                    </div>

            </s-form>
        </modal>
    </div>
</template>

<script>

import axios from 'secure-axios';
import urls from '../../../../../../data/urls';

export default {
    name: 'QualificationForm',
    data () {
        return {
            loading: false,
            professionOptions: [],
            durationOptions: [
                { value: '6 month', label: '6 month' },
                { value: '1 year', label: '1 year' },
                { value: '2 year', label: '2 year' },
                { value: '3 year', label: '3 year' },
                { value: '4 year', label: '4 year' }
            ],
            booleanOptions: [
                { value: 'True', label: 'Yes' },
                { value: 'False', label: 'No' }
            ],
            model: {},
            model1: {}
        };
    },
    mounted () {
        this.loadQualification();
    },
    methods: {
        getModel () {
            this.$emit('model', this.model);
        },
        loadQualification () {
            const that = this;
            axios.post(urls.vueSelect.qualificationsOptions).then(function (response) {
                that.professionOptions = response.data.data;
            });
        },
        async submitQualification () {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.admin.masterData.profession.add, that.model1);
            const json = response.data;
            if (json.error === false) {
                that.formSuccess();
                that.loading = false;
            } else {
                that.formError(json);
                that.loading = false;
            }
            that.loading = false;
        },
        formSuccess () {
            this.$refs.addQualificationModal.close();
            this.$notify('Added Successfully', 'Success', {
                type: 'success'
            });
            this.loadQualification();
        },
        formError (json) {
            this.$refs.form.setErrors(json.errors);
            this.$notify('Please fix the issue.', 'Error', {
                type: 'danger'
            });
        }
    }
};
</script>

<style>
.full-page {
    height: 100vh;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.image {
    background: url("../../../../../../assets/img/banner-blurred.png");
}

.link {
    cursor: pointer;
}
</style>
