<template>
    <custom-vue-table :showSearchBox="false" class="m-3 c-table" title="Country" :fields="fields" :url="listUrl" :per-page="10"
                    ref="table">
        <template #buttons>
            <btn icon="fa fa-plus" size="sm" @click="showAddModal" text="Add"/>
        </template>
        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <delete-btn color="primary" text="Edit" icon="" size="xs" @click="setEdit(rowData)"/>
                <delete-btn icon="" size="xs" @click="setDelete(rowData)"/>
            </div>
        </template>

        <template #footer>
            <modal  no-close-on-backdrop class="c-modal no-close"  title="Add Country" ref="addModal" width="30r" header-color="">
                <s-form @submit="submitClicked('add')" ref="form">
                    <validated-input label="Country" name="Country" v-model="model.name" :rules="{required : true}"
                                     :disabled="loading"/>

                    <div class="fl-x-bc mt-5 mb-3">
                        <btn size="sm" text="Save" :loading="loading" loading-text="Saving.."/>
                        <btn size="sm" class="ml-2 btn-basic-b" @click="$refs.addModal.close()" text="Cancel" type="button"/>
                    </div>

                </s-form>
            </modal>

            <modal  no-close-on-backdrop class="c-modal no-close"  title="Edit Country" ref="editModal" width="30r" header-color="">
                <s-form @submit="submitClicked('edit')" ref="form">
                    <validated-input label="Country" name="Country" v-model="model.name" :rules="{required : true}"
                                     :disabled="loading"/>

                    <div class="fl-x-bc mt-5 mb-3">
                        <btn size="sm" text="Save" :loading="loading" loading-text="Saving.."/>
                        <btn size="sm" class="ml-2 btn-basic-b" @click="$refs.editModal.close()" text="Cancel" type="button"/>
                    </div>

                </s-form>
            </modal>

            <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteSuccess">
                <p>You are about to delete the country. Are you sure?</p>
                <template #loading>
                    <delete-animation/>
                    Please wait while we delete the country.
                </template>
            </delete-modal>
        </template>

    </custom-vue-table>
</template>

<script>
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name: 'CountryListing',
    data () {
        return {
            loading: false,
            listUrl: urls.admin.masterData.country.list,
            deleteUrl: urls.admin.masterData.country.delete,
            addUrl: urls.admin.masterData.country.add,
            editUrl: urls.admin.masterData.country.edit,
            deletingItem: {
                id: ''
            },
            fields: [
                {
                    name: 'id',
                    sortField: 'id',
                    title: 'ID'
                },
                {
                    name: 'name',
                    sortField: 'name',
                    title: 'Country'
                },
                {
                    name: '__slot:actions',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right',
                    title: 'Actions'
                }
            ],
            model: {
                name: ''
            }
        };
    },
    methods: {
        showAddModal () {
            this.model = {};
            this.$refs.addModal.show();
        },
        setEdit (item) {
            this.model = { ...item };
            this.$refs.editModal.show();
        },
        setDelete (item) {
            this.deletingItem.id = item.id;
            this.$refs.deleteModal.show();
        },
        deleteSuccess () {
            this.$refs.deleteModal.close();
            this.deletingItem.id = '';
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        async submitClicked (action) {
            const that = this;
            that.loading = true;
            if (action === 'add') {
                that.action = that.addUrl;
            }
            if (action === 'edit') {
                that.action = that.editUrl;
            }
            const response = await axios.form(that.action, that.model);
            const json = response.data;
            if (json.error === false) {
                that.formSuccess();
                that.loading = false;
            } else {
                that.formError(json);
                that.loading = false;
            }
            that.loading = false;
        },
        formSuccess () {
            this.$refs.addModal.close();
            this.$refs.editModal.close();
            this.$notify('Saved Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        formError (json) {
            this.$refs.form.setErrors(json.errors);
            this.$notify('Please fix the issue.', 'Error', {
                type: 'danger'
            });
        }
    }
};
</script>

<style scoped>

</style>
