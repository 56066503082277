<template>
    <main>
        <div class="container">
            <div class="row py-5 px-0">
                <div class="col-12 col-lg-8">
                    <h2 class="text-uppercase poppins-bold mb-4">PLAB 2</h2>

                    <article class="mb-5">
                        <a target="_blank" href="https://meded.ucsd.edu/clinicalmed/introduction.html">
                            <div class="lg-img-contain-sm fl-y fl-j-c fl-a-s py-3">
                                <img src="../assets/img/plab-2/plab-2-clinical-skills-calif.png" alt="Medscape website"
                                     class="mb-3">
                                <span class="text-center">Clinical Skills</span>
                            </div>
                        </a>
                        <a target="_blank" href="https://www.medistudents.com/">
                            <div class="lg-img-contain-sm fl-y fl-j-c fl-a-s py-3">
                                <img src="../assets/img/plab-2/plab-2-clinical-skills-oscesf.png"
                                     alt="BMJ Learning website website"
                                     class="mb-3">
                                <span class="text-center">Clinical Skills</span>
                            </div>
                        </a>
                        <a target="_blank" href="https://cmftugme.wordpress.com/clinical-skills/">
                            <div class="lg-img-contain-sm fl-y fl-j-c fl-a-s py-3">
                                <img src="../assets/img/plab-2/plab-2-cmft.jpg" alt=""
                                     class="mb-3">
                                <span class="text-center">Central Manchester Clinical Skills</span>
                            </div>
                        </a>
                        <a target="_blank" href="https://neurologicexam.med.utah.edu/adult/html/home_exam.html">
                            <div class="lg-img-contain-sm fl-y fl-j-c fl-a-s py-3">
                                <img src="../assets/img/plab-2/plab-2-neuro-exam.png" alt=""
                                     class="mb-3">
                                <span class="text-center">Neurological examination</span>
                            </div>
                        </a>
                        <a target="_blank" href="https://cim.ucdmc.ucdavis.edu/EyeRelease/Interface/TopFrame.html">
                            <div class="lg-img-contain-sm fl-y fl-j-c fl-a-s py-3">
                                <img src="../assets/img/plab-2/eye-simulator.jpg" alt=""
                                     class="mb-3">
                                <span class="text-center">Neurological eye simulator</span>
                            </div>
                        </a>
                        <a target="_blank" href="http://www.clinicalexam.com/pda/index.html">
                            <div class="lg-img-contain-sm fl-y fl-j-c fl-a-s py-3">
                                <img src="../assets/img/plab-2/plab-2-clinical-skills-pda.png" alt=""
                                     class="mb-3">
                                <span class="text-center">Clinical skills (mobile)</span>
                            </div>
                        </a>
                        <a target="_blank" href="https://www.gmc-uk.org/ethical-guidance/learning-materials">
                            <div class="lg-img-contain-sm fl-y fl-j-c fl-a-s py-3">
                                <img src="../assets/img/plab-2/plab-2-gmc-case-studies.png" alt=""
                                     class="mb-3">
                                <span class="text-center">GMC case studies</span>
                            </div>
                        </a>
                        <a target="_blank" href="https://www.wilkes.med.ucla.edu/inex.html">
                            <div class="lg-img-contain-sm fl-y fl-j-c fl-a-s py-3">
                                <img src="../assets/img/plab-2/plab-2-heart-sounds.png" alt=""
                                     class="mb-3">
                                <span class="text-center">Heart sounds</span>
                            </div>
                        </a>
                        <a target="_blank" href="https://www.nottingham.ac.uk/nmp/sonet/rlos/patientsafety/sbar/">
                            <div class="lg-img-contain-sm fl-y fl-j-c fl-a-s py-3">
                                <img src="../assets/img/plab-2/plab-2-sbar.png" alt=""
                                     class="mb-3">
                                <span class="text-center">SBAR</span>
                            </div>
                        </a>

                        <a target="_blank" href="https://sites.google.com/a/umich.edu/bluelink/curricula">
                            <div class="lg-img-contain-sm fl-y fl-j-c fl-a-s py-3">
                                <img src="../assets/img/plab-2/plab-2-surface-anatomy.png" alt=""
                                     class="mb-3">
                                <span class="text-center">Surface anatomy</span>
                            </div>
                        </a>
                        <a target="_blank" href="https://www.nottingham.ac.uk/nmp/sonet/rlos/placs/antt/index.html">
                            <div class="lg-img-contain-sm fl-y fl-j-c fl-a-s py-3">
                                <img src="../assets/img/plab-2/pfw-antt.png" alt=""
                                     class="mb-3">
                                <span class="text-center">ANTT</span>
                            </div>
                        </a>
                        For REACHE NW members, there is access to specially-developed Safe Prescribing online modules:
                        <a href="https://reache3.safeprescriber.org/login" target="_blank" class="text-danger">https://reache3.safeprescriber.org/login</a>
                        <br><br>
                        In addition to these website links, there is also the list of resources that we prepared for
                        PLAB1: <br> <a target="_blank" href="https://reache.xeoscript.com/pdf/plab1-resources.pdf"
                                       class="text-danger mr-2" download>PLAB1 Resources (PDF File)</a>
                        <a href="https://reache.xeoscript.com/pdf/plab1-resources.doc" class="text-danger mr-2"
                           target="_blank" download>PLAB1 Resources (Word Document)</a>
                        <a href="https://reache.xeoscript.com/pdf/plab-2-practice-at-reache.docx" target="_blank"
                           class="text-danger"> Practicing at REACHE</a>

                    </article>

                    <share-this></share-this>
                </div>
                <div class="col-12 col-lg-4">
                    <location-and-info></location-and-info>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import LocationAndInfo from './components/support-pages/LocationAndInfo.vue';
// import JoiningForm from './components/support-pages/JoiningForm.vue';

export default {
    components: {
        LocationAndInfo
    }
};
</script>
<style lang="scss" scoped>
.word-icon-img {
    width: 60px;
}
</style>
