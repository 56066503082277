<template>
    <div class="fl-eq p-0 of-h bs-4 mb-3">
        <div class="p-4 w-65r br-1">
            <h5 class="mb-3">Reache Events</h5>
            <FullCalendar ref="fullCalendar" :options="calendarOptions"/>
        </div>
        <div class="p-4 w-30r">
            <perfect-scrollbar>
                <h5 class="mb-3 text-primary">Today's Events</h5>
                <div class="row m-2">
                    <div v-bind:style="getColor('1')" class="col m-2">10:30 AM</div>
                    <div class="col m-2">Event number 1</div>
                </div>
                <div class="row m-2">
                    <div v-bind:style="getColor('2')" class="col m-2">11:30 AM</div>
                    <div class="col m-2">Event number 2</div>
                </div>
                <div class="row m-2">
                    <div v-bind:style="getColor('2')" class="col m-2">11:30 AM</div>
                    <div class="col m-2">Event number 2</div>
                </div>
                <h5 class="mb-3 text-primary">Upcoming Events</h5>
                <div class="row m-2">
                    <div v-bind:style="getColor('1')" class="col m-2">10:30 AM</div>
                    <div class="col m-2">Event number 1</div>
                </div>
                <div class="row m-2">
                    <div v-bind:style="getColor('2')" class="col m-2">11:30 AM</div>
                    <div class="col m-2">Event number 2</div>
                </div>
                <div class="row m-2">
                    <div v-bind:style="getColor('2')" class="col m-2">11:30 AM</div>
                    <div class="col m-2">Event number 2</div>
                </div>
                <h5 class="mb-3 text-primary">Remainders</h5>
                <div class="row m-2">
                    <div v-bind:style="getColor('1')" class="col m-2">10:30 AM</div>
                    <div class="col m-2">Event number 1</div>
                </div>
                <div class="row m-2">
                    <div v-bind:style="getColor('2')" class="col m-2">11:30 AM</div>
                    <div class="col m-2">Event number 2</div>
                </div>
                <div class="row m-2">
                    <div v-bind:style="getColor('2')" class="col m-2">11:30 AM</div>
                    <div class="col m-2">Event number 2</div>
                </div>
                <h5 class="mb-3 text-primary">Remainders</h5>
                <div class="row m-2">
                    <div v-bind:style="getColor('1')" class="col m-2">10:30 AM</div>
                    <div class="col m-2">Event number 1</div>
                </div>
                <div class="row m-2">
                    <div v-bind:style="getColor('2')" class="col m-2">11:30 AM</div>
                    <div class="col m-2">Event number 2</div>
                </div>
                <div class="row m-2">
                    <div v-bind:style="getColor('2')" class="col m-2">11:30 AM</div>
                    <div class="col m-2">Event number 2</div>
                </div>
                <h5 class="mb-3 text-primary">Remainders</h5>
                <div class="row m-2">
                    <div v-bind:style="getColor('1')" class="col m-2">10:30 AM</div>
                    <div class="col m-2">Event number 1</div>
                </div>
                <div class="row m-2">
                    <div v-bind:style="getColor('2')" class="col m-2">11:30 AM</div>
                    <div class="col m-2">Event number 2</div>
                </div>
                <div class="row m-2">
                    <div v-bind:style="getColor('2')" class="col m-2">11:30 AM</div>
                    <div class="col m-2">Event number 2</div>
                </div>
            </perfect-scrollbar>
        </div>

        <modal  class="c-modal no-close"  title="Add Event" ref="addModal" width="45r" header-color="primary">
            <b-form ref="form" :save-url="addEventUrl" @success="formSuccess" v-slot="{loading}"
                    :save-params="model">
                <validated-input label="Title" v-model="model.title" :rules="{required:true}"
                                :disabled="loading"/>
                <validated-input label="Time" v-model="model.time" :rules="{required:true}"
                                 :disabled="loading"/>
                <textarea-input label="Description" v-model="model.description" :rules="{required:true}"
                                :disabled="loading"/>
                <save-btn text="Save" icon="" color="primary" size="sm" :disabled="loading">
                    <span v-if="loading"><loading-animation/><span
                        style="vertical-align: super"> Saving...</span></span>
                </save-btn>
            </b-form>
        </modal>

        <modal  class="c-modal no-close"  title="Edit Event" ref="editModal" width="45r" header-color="primary">
            <b-form ref="form" :save-url="addEventUrl" @success="formSuccess" v-slot="{loading}"
                    :save-params="model">
                <validated-input label="Title" v-model="model.title" :rules="{required:true}"
                                :disabled="loading"/>
                <validated-input label="Time" v-model="model.time" :rules="{required:true}"
                                 :disabled="loading"/>
                <textarea-input label="Description" v-model="model.description" :rules="{required:true}"
                                :disabled="loading"/>
                <div class="btn-group">
                    <save-btn text="Save" icon="" color="primary" size="sm" :disabled="loading">
                            <span v-if="loading"><loading-animation/><span
                                style="vertical-align: super"> Saving...</span></span>
                    </save-btn>
                    <delete-btn @click="deleteEvent" type="button" text="Delete" icon="" size="sm"
                                :disabled="loading"/>
                </div>
            </b-form>
        </modal>

        <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="formSuccess">
            <p>You are about to delete the Event. Are you
                sure ?</p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.title"></b>.
            </template>
        </delete-modal>
    </div>
</template>

<script>
import '@fullcalendar/core/vdom';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
// import urls from '../../../data/urls';
// import axios from 'secure-axios';
import { mapGetters } from 'vuex';

export default {
    name: 'StaffEvents',
    components: { FullCalendar, PerfectScrollbar },
    data () {
        const that = this;
        return {
            date: null,
            details: '',
            loading: false,
            saving: false,
            calendarOptions: {
                plugins: [interactionPlugin, dayGridPlugin, listPlugin],
                initialView: 'dayGridMonth',
                selectable: true,
                dateClick: function (info) {
                    that.handleDateClick(info);
                },
                eventClick: function (info) {
                    that.handleEventClick(info);
                },
                editable: true,
                headerToolbar: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,listMonth'
                },
                events: []
            },
            model: {
                id: '',
                title: '',
                time: '',
                description: '',
                date: ''
            },
            deletingItem: {
                id: '',
                title: ''
            },
            detailsUrl: '',
            addEventUrl: '',
            deleteUrl: ''
        };
    },
    updated () {
        this.updateScroll();
    },
    mounted () {
        this.loadDetails();
    },
    computed: {
        ...mapGetters(['currentUser'])
    },
    methods: {
        getColor (value) {
            if (value === '1') {
                const data = { background: '#d3e4f5' };
                return data;
            } else {
                const data = { background: '#f5d3d9' };
                return data;
            }
        },
        updateScroll () {
            const element = document.getElementById('scroll');
            element.scrollTop = element.scrollHeight;
        },
        loadDetails () {
            this.calendarOptions.events = [
                {
                    id: 35,
                    title: 'title 1',
                    description: 'description 1',
                    time: '10:02 AM',
                    date: '2021-11-04'
                },
                {
                    id: 36,
                    title: 'title 2',
                    description: 'description 2',
                    time: '12:02 AM',
                    date: '2021-11-09'
                },
                {
                    id: 37,
                    title: 'title 3',
                    description: 'description 3',
                    time: '09:02 AM',
                    date: '2021-11-11'
                }
            ];
            // const that = this;
            // that.loading = true;
            // axios.get(urls.Admin.DashBoard.details).then(function (response) {
            //     const json = response.data;
            //     if (json.error === false) {
            //         that.details = json;
            //         that.calendarOptions.events = json.events.data;
            //         that.loading = false;
            //     }
            // });
        },
        handleEventClick (item) {
            this.model = {};
            this.model.id = item.event.id;
            this.model.title = item.event.title;
            this.model.time = item.event.extendedProps.time;
            this.model.description = item.event.extendedProps.description;
            this.model.date = item.event.startStr;
            this.deletingItem.id = item.event.id;
            this.deletingItem.title = item.event.title;
            this.$refs.editModal.show();
        },
        handleDateClick (item) {
            this.model = {};
            this.model.date = item.dateStr;
            this.$refs.addModal.show();
        },
        deleteEvent () {
            this.$refs.editModal.close();
            this.$refs.deleteModal.show();
            this.model = {};
        },
        formSuccess (response) {
            this.model = {};
            this.calendarOptions.events = response.data.events.data;
            this.$refs.addModal.close();
            this.$refs.editModal.close();
            this.$refs.deleteModal.close();
        }
    }
};
</script>

<style scoped>
.ps {
    height: 792px;
}

.ps__rail-x {
    display: none !important;
}

</style>
