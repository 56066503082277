<template>
    <main>
        <div class="container">
            <div class="row py-5 px-0">
                <div class="col-12 col-lg-8">
                    <h2 class="text-uppercase poppins-bold mb-4">PREPARING FOR WORK IN THE NHS</h2>
                    <article class="mb-5">
                        <h3 class="mb-3">SOME USEFUL ONLINE MODULES</h3>
                        Induction for overseas-trained doctors (GMC): <a target="_blank"
                                                                         href="https://www.gmc-uk.org/about/what-we-do-and-why/learning-and-support/workshops-for-doctors/welcome-to-uk-practice/welcome-to-uk-practice-case-studies"
                                                                         class="text-danger">Welcome to UK practice</a>
                        <br><br>

                        Time management (BMJ Learning): <a href="https://new-learning.bmj.com/course/10015565"
                                                           class="text-danger" target="_blank">Time management</a>
                                                <br><br>

                        Ethics case studies (GMC): <a href="https://www.gmc-uk.org/gmpinaction/" target="_blank"
                                                      class="text-danger">Good Medical Practice in Action</a>
                                                <br><br>

                        Handover (Nottingham University): <a
                        href="https://www.nottingham.ac.uk/nmp/sonet/rlos/patientsafety/sbar/" class="text-danger"
                        target="_blank">SBAR</a>
                                                <br><br>

                        ECGs: <a target="_blank" href="https://skillstat.com/tools/ecg-simulator/" class="text-danger mr-2">test
                        yourself</a>;
                        <a target="_blank" href="http://ed.ted.com/on/GVvfmO2r" class="text-danger mr-2">5 min video on the
                            basics</a>;
                        <a target="_blank" href="https://ecg.utah.edu/" class="text-danger mr-2">more extensive resources</a>;
                        <br><br>
                        Radiology: <a target="_blank" href="http://www.idr.med.uni-erlangen.de/compare.htm" class="text-danger mr-2">test
                        yourself</a>;
                        <a target="_blank" href="http://www.swansea-radiology.co.uk/teaching.html" class="text-danger mr-2">a bit more depth</a>;
                        <a target="_blank" href="http://www.med-ed.virginia.edu/courses/rad/cxr/objectives.html" class="text-danger mr-2">step-by-step</a>;
                    </article>
                    <share-this></share-this>
                </div>
                <div class="col-12 col-lg-4">
                    <location-and-info></location-and-info>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import LocationAndInfo from './components/support-pages/LocationAndInfo.vue';
// import JoiningForm from './components/support-pages/JoiningForm.vue';

export default {
    components: {
        LocationAndInfo
    }
};
</script>
