<template>
    <loading-animation class="card bs-4" v-if="DataLoading"></loading-animation>
    <!--    Attendance Details    -->
    <div v-else >
        <AdminHome/>
        <div class="p-3 bs-4">
            <div class="fl-te-c mb-3">
                <h5>My Attendance</h5>
                <div class="fl-eq">
                    <drop-down-button :text="year.toString()" size="sm" enter-animation="zoomIn"
                                      exit-animation="zoomOut"
                                      icon="fa fa-chevron-down">
                        <drop-down-item v-for="year in yearOptions" v-bind:key="year.value"
                                        @click="setYear(year.value)" :text="year.value"/>
                    </drop-down-button>
                    <drop-down-button :text="monthLabel" size="sm" class="ml-2" enter-animation="zoomIn" exit-animation="zoomOut"
                                      icon="fa fa-chevron-down">
                        <drop-down-item v-for="month in monthOptions" v-bind:key="month.value"
                                        @click="setMonth(month)" :text="month.label"/>
                    </drop-down-button>
                    <btn size="sm" class="ml-2" text="Download" icon="fa fa-download"/>
                    <btn size="sm" class="ml-2" text="Create Attendance"
                         @click="$router.push('/clinical/attendance-add/')"/>
                </div>
            </div>
            <div v-if="details">
                <div class="p-2 pb-3 row bg-1">
                    <div v-if="details.working_day" class="col-2"><b>{{ details.working_day }}</b> Working Days</div>
                    <div v-if="!details.working_day" class="col-2"><b>0</b> Working Days</div>
                    <div v-if="details.present_day" class="col-2"><b class="text-primary">{{ details.present_day }}</b>
                        Present Days
                    </div>
                    <div v-if="!details.present_day" class="col-2"><b class="text-primary">0</b> Present Days</div>
                    <div v-if="details.absent_day" class="col-2"><b class="text-danger">{{ details.absent_day }}</b> Absent
                        Days
                    </div>
                    <div v-if="!details.absent_day" class="col-2"><b class="text-danger">0</b> Absent Days</div>
                    <div v-if="details.holiday" class="col-2"><b class="text-success">{{ details.holiday }}</b> Holiday Days
                    </div>
                    <div v-if="!details.holiday" class="col-2"><b class="text-success">0</b> Holiday Days</div>
                </div>
                <div class="row">
                    <div class="col-md-2" v-for="record in details.attendance" :key="record.day">
                        <div class="m-1">
                            <span><strong>{{ record.day }} </strong></span>
                            <btn class="ml-3" color="" v-bind:style="getColor(record.attendance)" size="xs"
                                 :text="record.attendance"/>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!details" class="card bg-primary bs-4 text-center">No attendance available for the staff.</div>
        </div>
    </div>
</template>

<script>

import AdminHome from '../../../Dashboards/CustomHomePages/AdminHome';
function generateArrayOfYears () {
    const date = new Date().getFullYear();
    const min = date - 10;
    const years = [];

    for (let i = min; i <= date; i++) {
        const data = { label: i, value: i };
        years.push(data);
    }
    return years.reverse();
}

export default {
    name: 'Attendance',
    components: { AdminHome },
    data () {
        const Options = generateArrayOfYears();
        return {
            yearOptions: Options,
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            monthLabel: '',
            date: '',
            details: {
                working_day: 10,
                absent_day: 1,
                holiday: 0,
                present_day: 3,
                attendance: [
                    {
                        day: 1,
                        attendance: 'Present'
                    },
                    {
                        day: 2,
                        attendance: 'Absent'
                    },
                    {
                        day: 3,
                        attendance: 'Holiday'
                    },
                    {
                        day: 4,
                        attendance: 'Present'
                    },
                    {
                        day: 5,
                        attendance: 'Absent'
                    },
                    {
                        day: 6,
                        attendance: 'Holiday'
                    },
                    {
                        day: 7,
                        attendance: 'Present'
                    },
                    {
                        day: 8,
                        attendance: 'Present'
                    },
                    {
                        day: 9,
                        attendance: 'Absent'
                    },
                    {
                        day: 10,
                        attendance: 'Holiday'
                    },
                    {
                        day: 11,
                        attendance: 'Present'
                    }
                ]
            },
            monthOptions: [
                { label: 'January', value: 1 },
                { label: 'February', value: 2 },
                { label: 'March', value: 3 },
                { label: 'April', value: 4 },
                { label: 'May', value: 5 },
                { label: 'June', value: 6 },
                { label: 'July', value: 7 },
                { label: 'August', value: 8 },
                { label: 'September', value: 9 },
                { label: 'October', value: 10 },
                { label: 'November', value: 11 },
                { label: 'December', value: 12 }
            ],
            DataLoading: false
        };
    },
    watch: {
        year: {
            handler () {
                // this.loadDetails();
            }
        },
        month: {
            handler () {
                // this.loadDetails();
            }
        }
    },
    mounted () {
        this.setDate();
        this.loadDetails();
    },
    methods: {
        setDate () {
            const monthID = new Date().getMonth();
            this.monthLabel = this.monthOptions[monthID].label;
        },
        setYear (year) {
            this.year = year;
        },
        setMonth (month) {
            this.month = month.value;
            this.monthLabel = month.label;
        },
        async loadDetails () {
        },
        getColor (attendance) {
            if (attendance === 'Holiday') {
                const data = { background: '#008000', color: 'White' };
                return data;
            } else if (attendance === 'Present') {
                const data = { background: '#5dabff', color: 'White' };
                return data;
            } else if (attendance === 'Absent') {
                const data = { background: '#ff6f6f', color: 'White' };
                return data;
            } else {

            }
        }
    }
};
</script>
