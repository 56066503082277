<template>
    <main>
        <div class="container">
            <div class="row py-5 px-0">
                <div class="col-12 col-lg-8">
                    <h2 class="text-uppercase poppins-bold mb-4">ENGLISH (OET)</h2>
                    <article class="mb-5">
                        <h3 class="mb-3">SOME SUGGESTED RESOURCES</h3>
                        <img src="../assets/img/worddoc.gif" class="mb-3 word-icon-img" alt=""> <br>
                        <a href="https://reache.xeoscript.com/pdf/general-english-language-applications.docx"
                           target="_blank" class="text-danger" download>General English Language Applications</a>

                    </article>
                    <article class="mb-5">
                        <h3 class="mb-3">FREE CONVERSATION CLASSES IN MANCHESTER</h3>
                        <a target="_blank"
                           href="http://www.manchestergalleries.org/whats-on/community-programmes-1311243048/adults-learning-english/"
                           class="text-danger">http://www.manchestergalleries.org/whats-on/community-programmes-1311243048/adults-learning-english/

                        </a>

                    </article>
                    <article class="mb-5">
                        <h3 class="mb-3">FREE ONLINE RESOURCES FROM THE BRITISH COUNCIL</h3>
                        <a target="_blank" href="http://learnenglish.britishcouncil.org/en/" class="text-danger">http://learnenglish.britishcouncil.org/en/</a>
                    </article>

                    <share-this></share-this>
                </div>
                <div class="col-12 col-lg-4">
                    <location-and-info></location-and-info>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import LocationAndInfo from './components/support-pages/LocationAndInfo.vue';
// import JoiningForm from './components/support-pages/JoiningForm.vue';

export default {
    components: {
        LocationAndInfo
    }
};
</script>
<style lang="scss" scoped>
.word-icon-img {
    width: 60px;
}
</style>
