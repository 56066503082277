<template>
    <main>
        <div class="container">
            <div class="row py-5 px-0">
                <div class="col-12 col-lg-8">
                    <h2 class="text-uppercase poppins-bold mb-4">ENGLISH PRACTICE</h2>
                    <h2 class="mb-3">FACE TO FACE PRACTICE</h2>
                    <article class="mb-5 pb-4">
                        Conversations with individuals or small groups can take place in the Mayo (teaching) building of
                        the hospital. They can also take place using internet-based services such as Skype and Facetime.
                    </article>
                    <share-this></share-this>
                </div>
                <div class="col-12 col-lg-4">
                    <location-and-info></location-and-info>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import LocationAndInfo from './components/support-pages/LocationAndInfo.vue';

export default {
    name: 'Englishpractice',
    components: {
        LocationAndInfo
    }
};
</script>

<style scoped>

</style>
