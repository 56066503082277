<template>
    <main>
        <div class="container">
            <div class="row py-5 px-0">
                <div class="col-12 col-lg-8">
                    <h2 class="text-uppercase poppins-bold mb-4">CAN YOU HELP?</h2>
                    <article class="mb-5 bb-1 b-dark pb-4">
                        We are looking for people to help us with the teaching and support of our members. We are
                        looking for specific help as listed below, but if you do not fit into one of these categories
                        and feel you still have something to offer, please get in touch through our
                        <a href="#" class="text-danger">Contacts page</a>
                        <br>
                        <br>
                        If you decide to go ahead, we would normally arrange a meeting with one of our tutors to discuss
                        things further and we will also need references.
                        <br>
                        <br>
                        Thankyou, all help is appreciated!
                        <br>
                        <br>
                        <div class="text-center">
                            <a href="#" class="text-danger"><i>Flyer with information about Reache</i></a>
                        </div>
                    </article>
                    <article class="mb-5">
                        <h3 class="mb-3">ENGLISH SUPPORT</h3>
                        Free programmes to learn typing:
                        Our members gain a lot of benefit from practising with native speakers, either one-to-one or in
                        small groups. English teaching qualifications are a bonus, but not essential.
                    </article>
                    <article class="mb-5">
                        <h3 class="mb-3">DOCTORS</h3>
                        The greatest proportion of our members are doctors and the support we have from medical
                        students, junior doctors, consultants and GPs is hugely valuable. In addition to
                        <a href="#" class="text-danger">mentoring</a>,
                        support can range from being a <a href="#" class="text-danger">clinical supervisor</a>, giving a <a href="#" class="text-danger">teaching
                        session</a>, or just joining
                        us for our <a href="#" class="text-danger">OSCE communications skills practice sessions.</a>
                    </article>
                    <article class="mb-5">
                        <h3 class="mb-3">OTHER PROFESSIONALS</h3>
                        Nurses, pharmacists, biomedical & clinical scientists, physiotherapists, radiographers and other
                        health professionals: in similar ways to above, please use our contact form to get in touch if
                        you could help giving support and advice as a <a href="#" class="text-danger">mentor</a> or by offering to take one
                        of our members (including our doctors) on a five-day NHS
                        <a href="#" class="text-danger">taster placement</a> with your team, or if you have a relevant topic to teach at our
                        <a href="#" class="text-danger">Wednesday teaching sessions.</a>
                    </article>
                    <article class="mb-5">
                        <h3 class="mb-3">MENTORING</h3>
                        REACHE North West are looking for people who are working or have recently worked in the NHS to
                        act as a mentor to a refugee health professional, to meet at a mutually convenient time and
                        location on a few occasions over six months initially.
                        <a href="#" class="text-danger">Click here to find out more.</a>
                        <br>
                        <br>
                        <h4>PLEASE USE OUR <a href="#" class="text-danger">CONTACT FORM</a> TO GET IN TOUCH!</h4>
                    </article>
                    <share-this></share-this>
                </div>
                <div class="col-12 col-lg-4">
                    <location-and-info></location-and-info>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import LocationAndInfo from './components/support-pages/LocationAndInfo.vue';

export default {
    name: 'Otherprofessionals',
    components: {
        LocationAndInfo
    }
};
</script>

<style scoped>

</style>
