<template>
    <main>
        <div class="container">
            <div class="row py-5 px-0">
                <div class="col-12 col-lg-8">
                    <h2 class="text-uppercase poppins-bold mb-4">DRUG OF THE WEEK</h2>
                    <article class="mb-5">
                        <h3 class="mb-2 text-uppercase">BRITISH NATIONAL FORMULARY (BNF)</h3>
                        <a target="_blank" href="https://reache.files.wordpress.com/2013/11/bnf-66.jpg">
                            <div class="packet-img py-3">
                                <img src="../assets/img/drug-of-the-week/bnf-66.jpg" alt="Image of BNF 66 Packet" class="mb-3">
                            </div>
                        </a>
                        <br><br>
                        Free registration for online access in the UK at <a href="http://www.bnf.org" target="_blank"
                                                                            class="text-danger">http://www.bnf.org</a>.
                        Or ask for an old one from
                        your GP, or find the most recent in hospital libraries. Good for information about individual
                        drugs; prescribing guidelines.
                        <br>
                    </article>

                    <article class="mb-5">
                        <h3 class="mb-3 text-uppercase">EMC</h3>
                        <a target="_blank" href="https://www.medicines.org.uk/emc">
                            <div class="lg-img-contain py-3">
                                <img src="../assets/img/drug-of-the-week/emc.jpg" alt="Medscape website" class="mb-3">
                            </div>
                        </a>
                        <br><br>
                        <a href="http://www.medicines.org.uk" class="text-danger">http://www.medicines.org.uk</a>
                        Good for a bit more detail about side-effects and the interactions are on the same page, making
                        it a bit easier to find them. It also has photos of the medicines.

                    </article>
                    <article class="mb-5">
                        <h3 class="mb-3 text-uppercase">NICE MEDICINES INFORMATION</h3>
                        <a target="_blank"
                           href="https://www.evidence.nhs.uk/about-evidence-services/content-and-sources/medicines-information">
                            <div class="lg-img-contain py-3">
                                <img src="../assets/img/drug-of-the-week/nice-1.jpg" alt="Medscape website"
                                     class="mb-3">
                            </div>
                        </a>
                        <br><br>
                        <a href="https://www.evidence.nhs.uk/about-evidence-services/content-and-sources/medicines-information"
                           target="_blank" class="text-danger">
                            https://www.evidence.nhs.uk/about-evidence-services/content-and-sources/medicines-information
                        </a><br>
                        Lots of information here, but it is not always simple to use. Searching by the full drug name
                        gives the best results, bringing you to a page where you can access the BNF entry online, NICE
                        (and other) guidance and sometimes a section called ‘Current Awareness’ which gives links to
                        articles on prescribing issues related to that drug.
                        <a target="_blank"
                           href="https://www.evidence.nhs.uk/about-evidence-services/content-and-sources/medicines-information">
                            <div class="lg-img-contain py-3">
                                <img src="../assets/img/drug-of-the-week/nice-2.jpg" alt="Medscape website"
                                     class="mb-3">
                            </div>
                        </a>
                    </article>
                    <article class="mb-5">
                        <h3 class="mb-2">MHRA EDUCATION MODULES</h3>
                        <a href="https://www.gov.uk/government/publications/e-learning-modules-medicines-and-medical-devices"
                           class="text-danger">https://www.gov.uk/government/publications/e-learning-modules-medicines-and-medical-devices</a>
                        <br><br>

                        Free online modules about the use and risks of selected drugs and devices
                        https://reache.files.wordpress.com/2013/11/dotw-mhra.jpg
                        <a target="_blank"
                           href="https://reache.files.wordpress.com/2013/11/dotw-mhra.jpg">
                            <div class="lg-img-contain py-3">
                                <img src="../assets/img/drug-of-the-week/dotw-mhra.jpg"
                                     alt="MHRA Education Modules website"
                                     class="mb-3">
                            </div>
                        </a>
                    </article>
                    <article class="mb-5">
                        <h3 class="mb-2">THE COCHRANE LIBRARY</h3>
                        <a href="http://www.thecochranelibrary.com" class="text-danger">http://www.thecochranelibrary.com</a>
                        <br><br>
                        Produce evidence reviews from medical research for clinicians and NICE.
                        <a target="_blank" href="https://reache.files.wordpress.com/2013/11/dotw-cochrane.jpg">
                            <div class="lg-img-contain fl-y fl-j-c fl-a-s py-3">
                                <img src="../assets/img/drug-of-the-week/dotw-cochrane.jpg"
                                     alt="THE COCHRANE LIBRARY website"
                                     class="mb-3">
                                <span class="text-center">The Cochrane Library</span>
                            </div>
                        </a>
                    </article>

                    <article class="mb-5">
                        <h3 class="mb-3">GENERAL TIPS ON PRESENTATIONS</h3>
                        <br>
                        Here is a short pdf file about giving a presentation:
                        <br><br>
                        <a target="_blank" href="https://reache.xeoscript.com/pdf/giving-a-presentation.pdf"
                           class="text-danger" download>Giving a presentation</a>
                        <br><br>
                        And an interesting TED Talk that might help you with your delivery:

                        <a href="https://www.ted.com/talks/julian_treasure_how_to_speak_so_that_people_want_to_listen"
                           class="text-danger">
                            How to speak so that people want to listen, a TED Talk by Julian Treasure
                        </a>
                    </article>

                    <share-this></share-this>
                </div>
                <div class="col-12 col-lg-4">
                    <location-and-info></location-and-info>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import LocationAndInfo from './components/support-pages/LocationAndInfo.vue';
// import JoiningForm from './components/support-pages/JoiningForm.vue';

export default {
    components: {
        LocationAndInfo
    }
};
</script>
<style lang="scss" scoped>
.word-icon-img {
    width: 60px;
}

.packet-img {
    width: 11.375rem;
    height: 13.1875rem;
}
</style>
