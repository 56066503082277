<template>
    <loading-animation v-if="loading"/>
    <div v-else>
        <div class="fl-eq mb-4">
            <div class="p-3 w-25r bs-4">
                <h4 class="mb-3">My Profile</h4>
                <div style="min-height: 23rem; background-position: center center; background-size:cover;"
                     :style="image">
                </div>
                <label>Name</label>
                <div class="form-control round-1 text-muted">{{ details.name }}</div>
                <label>Email ID</label>
                <div class="form-control round-1 text-muted">{{ details.email }}</div>
            </div>
            <basic-info :details="details"/>
        </div>
        <qualification-info :details="details" class="mb-4"/>
        <reache-info :details="details" class="mt-0"/>
<!--        <course-details/>-->
    </div>
</template>

<script>
import BasicInfo from '../Admin/EnrollmentFunctionalDivison/UserManagement/Components/ProfileDetailsComponent/BasicInfo';
import QualificationInfo from '../Admin/EnrollmentFunctionalDivison/UserManagement/Components/ProfileDetailsComponent/QualificationInfo';
import ReacheInfo from '../Admin/EnrollmentFunctionalDivison/UserManagement/Components/ProfileDetailsComponent/ReacheInfo';
import axios from 'secure-axios';
import urls from '../../data/urls';
import { mapGetters } from 'vuex';

export default {
    name: 'MyProfile',
    computed: {
        image () {
            return { backgroundImage: 'url(/upload/app/' + this.details.profile_image + ')' };
        },
        ...mapGetters(['currentUser'])
    },
    components: { ReacheInfo, QualificationInfo, BasicInfo },
    data () {
        return {
            id: this.$route.params.id,
            loading: false,
            details: ''
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods: {
        async loadDetails () {
            const that = this;
            if (!that.id) {
                that.id = that.currentUser.id;
            }
            that.loading = true;
            const response = await axios.form(urls.member.profileDetails, { id: that.id });
            if (response.data.error === false) {
                that.details = response.data.object;
                that.loading = false;
            }
            that.loading = false;
        }
    }
};
</script>

<style scoped>

</style>
