<template>
    <staff-events/>
</template>

<script>
import StaffEvents from '../Components/StaffEvents';
export default {
    name: 'StaffHome',
    components: { StaffEvents }
};
</script>

<style scoped>

</style>
