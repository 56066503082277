<template>
    <main>
        <div class="container">
            <div class="row py-5 px-0">
                <div class="col-12 col-lg-8">
                    <h2 class="text-uppercase poppins-bold mb-4">MEDICAL STUDENTS</h2>
                    <h3 class="text-uppercase mb-5">OSCE PRACTICE – PLAN SESSIONS</h3>
                    <article class="mb-5">
<!--                        <h3 class="mb-3">WHAT IS PLAN?</h3>-->
                        <p>
                            OSCE and PLAB 2 practice for REACHE North West members and medical students in the Mayo
                            Building, email <a class="text-danger" href="mailto:reache@manchester.ac.uk">reache@manchester.ac.uk</a> if you want to join
                        </p>
                    </article>
<!--                    <article class="mb-5">-->
<!--                        <h3 class="mb-3">HOW CAN I JOIN IN?</h3>-->
<!--                        <div class="row">-->
<!--                            <div class="col-12 col-lg-4 mb-3 mb-lg-0">-->
<!--                                <img src="../assets/img/plan1.jpg" alt="">-->
<!--                                <br>-->
<!--                                PLAN session-->
<!--                            </div>-->
<!--                            <div class="col-12 col-lg-8">-->
<!--                                <p>-->
<!--                                    Email <a href="mailto:reache@manchester.ac.uk" class="text-danger">reache@manchester.ac.uk</a>, or put your head round the tutors’ office door –-->
<!--                                    first on the right as you come into SRFT library in the Mayo Building. Or just come-->
<!--                                    along at 2:00 pm and join the group – we’re very friendly. The room booking will be-->
<!--                                    on the Meeting Room screen by the Mayo Reception. Please note that we don’t meet-->
<!--                                    every Friday of the year, so it may be better to check if you are travelling some-->
<!--                                    distance.-->
<!--                                </p>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </article>-->
                    <share-this></share-this>
                </div>
                <div class="col-12 col-lg-4">
                    <location-and-info></location-and-info>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import LocationAndInfo from './components/support-pages/LocationAndInfo.vue';

export default {
    name: 'Medicalstudents',
    components: {
        LocationAndInfo
    }
};
</script>

<style scoped>

</style>
