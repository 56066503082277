<template>
    <admin-staff-details-component/>
</template>

<script>

import AdminStaffDetailsComponent from '../Admin/DirectorFunctionalDivison/UserManagement/DetailsPage';
export default {
    name: 'MyProfile',
    components: { AdminStaffDetailsComponent }
};
</script>

<style scoped>

</style>
