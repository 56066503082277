<template>
    <div class="fl-eq card p-0 of-h bs-4 mb-3">
        <div class="p-4 w-65r br-1">
            <h5 class="mb-3">Reache Events</h5>
            <FullCalendar ref="fullCalendar" :options="calendarOptions"/>
        </div>
        <div class="p-4 w-30r">
            <perfect-scrollbar>
                <h5 class="mb-3 text-primary">Today's Events</h5>
                <div class="row m-2">
                    <div v-bind:style="getColor('1')" class="col m-2">10:30 AM</div>
                    <div class="col m-2">Event number 1</div>
                </div>
                <div class="row m-2">
                    <div v-bind:style="getColor('2')" class="col m-2">11:30 AM</div>
                    <div class="col m-2">Event number 2</div>
                </div>
                <div class="row m-2">
                    <div v-bind:style="getColor('2')" class="col m-2">11:30 AM</div>
                    <div class="col m-2">Event number 2</div>
                </div>
                <h5 class="mb-3 text-primary">Upcoming Events</h5>
                <div class="row m-2">
                    <div v-bind:style="getColor('1')" class="col m-2">10:30 AM</div>
                    <div class="col m-2">Event number 1</div>
                </div>
                <div class="row m-2">
                    <div v-bind:style="getColor('2')" class="col m-2">11:30 AM</div>
                    <div class="col m-2">Event number 2</div>
                </div>
                <div class="row m-2">
                    <div v-bind:style="getColor('2')" class="col m-2">11:30 AM</div>
                    <div class="col m-2">Event number 2</div>
                </div>
                <h5 class="mb-3 text-primary">Remainders</h5>
                <div class="row m-2">
                    <div v-bind:style="getColor('1')" class="col m-2">10:30 AM</div>
                    <div class="col m-2">Event number 1</div>
                </div>
                <div class="row m-2">
                    <div v-bind:style="getColor('2')" class="col m-2">11:30 AM</div>
                    <div class="col m-2">Event number 2</div>
                </div>
                <div class="row m-2">
                    <div v-bind:style="getColor('2')" class="col m-2">11:30 AM</div>
                    <div class="col m-2">Event number 2</div>
                </div>
                <h5 class="mb-3 text-primary">Remainders</h5>
                <div class="row m-2">
                    <div v-bind:style="getColor('1')" class="col m-2">10:30 AM</div>
                    <div class="col m-2">Event number 1</div>
                </div>
                <div class="row m-2">
                    <div v-bind:style="getColor('2')" class="col m-2">11:30 AM</div>
                    <div class="col m-2">Event number 2</div>
                </div>
                <div class="row m-2">
                    <div v-bind:style="getColor('2')" class="col m-2">11:30 AM</div>
                    <div class="col m-2">Event number 2</div>
                </div>
                <h5 class="mb-3 text-primary">Remainders</h5>
                <div class="row m-2">
                    <div v-bind:style="getColor('1')" class="col m-2">10:30 AM</div>
                    <div class="col m-2">Event number 1</div>
                </div>
                <div class="row m-2">
                    <div v-bind:style="getColor('2')" class="col m-2">11:30 AM</div>
                    <div class="col m-2">Event number 2</div>
                </div>
                <div class="row m-2">
                    <div v-bind:style="getColor('2')" class="col m-2">11:30 AM</div>
                    <div class="col m-2">Event number 2</div>
                </div>
            </perfect-scrollbar>
        </div>
    </div>
</template>

<script>
import '@fullcalendar/core/vdom';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';

export default {
    name: 'Events',
    components: { FullCalendar, PerfectScrollbar },
    data () {
        return {
            calendarOptions: {
                plugins: [interactionPlugin, dayGridPlugin],
                initialView: 'dayGridMonth',
                selectable: false,
                height: '600px',
                headerToolbar: {
                    center: 'title',
                    left: ''
                },
                events: [],
                scrollHeight: window.innerHeight
            }
        };
    },
    updated () {
        this.updateScroll();
    },
    methods: {
        getColor (value) {
            if (value === '1') {
                const data = { background: '#d3e4f5' };
                return data;
            } else {
                const data = { background: '#f5d3d9' };
                return data;
            }
        },
        updateScroll () {
            const element = document.getElementById('scroll');
            element.scrollTop = element.scrollHeight;
        }
    }
};
</script>

<style scoped>
.ps {
    height: 630px;
}

.ps__rail-x {
    display: none !important;
}

</style>
