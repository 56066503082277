<template>
    <main>
        <div class="container">
            <div class="row py-5 px-0">
                <div class="col-12 col-lg-8">
                    <h2 class="text-uppercase poppins-bold mb-4"> PRESENTING OUR WORK
                    </h2>
                    <article class="mb-5">
                        <h3 class="text-uppercase mb-4">Awards</h3>
                        <div>
                            Highly Commended in the Health Service Journal Value Awards, 2019 <br><br>
                            Winners, Health Stream Sanctuary Award, City of Sanctuary, 2014 <br><br>
                            Winners, Adult Learners’ Week National Outstanding Learner Provider in the Healthcare Sector
                            Award 2008
                            <br><br>
                            Winners, Greater Manchester Strategic Healthcare Awards 2005 – for Innovation and Excellence
                            in Teaching and Learning.

                        </div>
                    </article>
                    <article>
                        <h3 class="text-uppercase mb-4">Workshops And Presentations</h3>
                        <div>
                            Smalldridge A, Sykes M and Morowa H; An innovative adaptation of Problem Based Learning
                            (PBL)
                            teaching to improve the clinical reasoning skills of a group of International Medical
                            Graduates
                            (IMGs); 2013; MedEd Published: March, 2013. <br><br>
                            Smalldridge A, Cross D, Sykes M (2012). Tips for helping International Medical Students’
                            communication skills. Workshop given at University of Manchester Community-Based Medical
                            Education Undergraduate Teaching Conference; Bolton, UK. <br><br>
                            Sykes M, Cross D, Smalldridge A (2012). Tackling language related communication issues in
                            supporting International Medical Graduates. Workshop given at the Association of Medical
                            Education in Europe Conference 2012; Lyon, France. <br><br>
                            Cross D, Smalldridge, A (2012). Identifying IMG’s language and communication learning needs
                            using a combined linguistic and clinical approach. Oral presentation given at the European
                            Association for Communication in Healthcare Conference 2012; St Andrews, Scotland. <br><br>
                            Cross D, Keaney M (2012). Safe and Effective Clinical Communication (SECC) – A skills
                            training course for International Medical Graduates (IMGs). Workshop given at the
                            Communication and Medical Ethics Conference 2012; Trondheim, Norway. <br><br>
                            Cross D, Smalldridge A, Sykes M, Keaney M (2012). Safe Communication Skills for
                            International Doctors. Poster presented at Patient Safety Congress 2012; Birmingham, UK.
                            <br><br>
                            Fisher JP, Sykes M. (2009) Helping international medical graduates adapt to the NHS.
                            Workshop given at the Association for the Study of Medical Education (ASME) Annual
                            Scientific Meeting; Edinburgh, UK <br><br>
                            Fisher JP, Sykes M, Rayner C (2008). Fit to practice in a diverse society? – teaching
                            cultural competence and diversity awareness. Workshop given at Manchester Medical Education
                            Conference 2008. Manchester, UK. <br><br>
                            Anderson K, Fisher JP, Sykes M (2006). International medical graduates and home grown
                            medical students learning together. Poster at Association of Medical Education in Europe
                            Conference 2006; Genoa: Italy. <br><br>
                            Sykes M, Fisher JP and Hampson A (2005). Helping overseas doctors adapt to work in the
                            healthcare system. Workshop given at the Association of Medical Education in Europe
                            Conference 2005; Amsterdam, Netherlands. <br><br>
                            Keaney M, Sykes M, Fisher JP (2005). Refugee Health Professionals – part of the solution.
                            Poster at the International Health and Medical Education Centre/Lancet Lecture; London, UK.
                            <br><br>

                        </div>

                    </article>
                    <share-this></share-this>
                </div>
                <div class="col-12 col-lg-4">
                    <location-and-info></location-and-info>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import LocationAndInfo from './components/support-pages/LocationAndInfo.vue';

export default {
    components: {
        LocationAndInfo
    }
};
</script>
