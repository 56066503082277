<template>
    <main>
        <div class="container">
            <div class="row py-5 px-0">
                <div class="col-12 col-lg-8">
                    <h2 class="text-uppercase poppins-bold mb-4">PORTFOLIO, CV AND PDP</h2>
                    <article class="mb-5">
                        Portfolio… Continuing professional development… Curriculum Vitae… Personal development plan…
                        appraisal…
                        <br><br>
                        <span class="poppins-semibold">All this might be confusing, but it is REALLY IMPORTANT!</span>
                        <br><br>
                        We have recorded some video guides to help you.
                        <br><br>

                        <ul class="pl-5 list-unstyled">
                            <li class="mb-3"><a target="_blank"
                                                href="https://www.dropbox.com/s/d3oj89xhguk611g/Video%20guide%20intro.mp4?dl=0"
                                                class="text-danger">Video Guide
                                1: Introduction</a>(ignore and close the sign-in box that appears when you click the
                                link)
                            </li>
                            <li class="mb-3"><a
                                href="https://www.dropbox.com/s/bshqcsiwdg1jx45/Video%20guide%20portfolio.mp4?dl=0"
                                class="text-danger" target="_blank">Video Guide 2: BMJ Portfolio</a>
                            </li>
                            <li class="mb-3"><a
                                href="https://www.dropbox.com/s/clvcy7qgp27o29s/Video%20guide%20PDP.mp4?dl=0"
                                target="_blank" class="text-danger">Video Guide 3: PDP</a>
                            </li>
                            <li class="mb-3"><a
                                href="https://www.dropbox.com/s/ovbqetknjf696rp/Video%20guide%20CV.mp4?dl=0"
                                target="_blank" class="text-danger">Video Guide 4: CV</a>
                            </li>
                        </ul>

                    </article>
                    <article class="mb-5">
                        <h3 class="mb-3 text-uppercase">BMJ PORTFOLIO</h3>
                        We encourage you to use the free <a href="http://portfolio.bmj.com/portfolio/login.html"
                                                            target="_blank" class="text-danger">BMJ Portfolio</a>. Any
                        health professional can register to use the
                        portfolio.
                        <br><br>

                        The BMJ Portfolio helps you keep a record of your Continuous Professional Development (CPD).
                        <br><br>
                        As a health professional you are required to show evidence of ongoing CPD activity throughout
                        your career, and this includes during your time as a member of REACHE North West.
                        <br><br>
                        <ul class="pl-5 list-unstyled">
                            <li class="mb-3"><a target="_blank"
                                                href="https://reache.xeoscript.com/pdf/what-should-be-in-your-portfolio.docx"
                                                class="text-danger" download>What should be in your portfolio</a>–
                                includes a
                                setup guide for
                                your BMJ Portfolio
                            </li>
                            <li class="mb-3"><a
                                href="https://reache.xeoscript.com/pdf/reflection-e28093-bmj-portfolio-format-2014.doc"
                                class="text-danger" target="_blank" download>Reflection – BMJ Portfolio
                                format</a>
                                – a guide to help you use the template in the BMJ Portfolio: this will help you choose
                                what to write in each section of the reflective proforma
                            </li>
                            <li class="mb-3"><a
                                href="https://reache.xeoscript.com/pdf/1-examplesofreflectivewriting.doc"
                                target="_blank" class="text-danger" download>Reflective writing examples</a> and
                                <a href="https://reache.xeoscript.com/pdf/3-reflective-vocab.doc"
                                   target="_blank"
                                   class="text-danger" download>Reflective
                                    vocabulary template</a> – to help you improve your skills of reflection (with thanks
                                to
                                Southampton Solent University).
                            </li>
                        </ul>
                    </article>
                    <article class="mb-5">
                        <h3 class="mb-3 text-uppercase">PERSONAL DEVELOPMENT PLAN (PDP)</h3>
                        If your portfolio is your reflective learning record (a modern logbook), your PDP is your plan
                        for the future.
                        <br><br>
                        All health professionals should have a PDP to show your plan for the next 6-12 months.
                        <br><br>
                        It should be an “active” document: something that you review and update as you learn. The tutors
                        and volunteers at REACHE North West are available by appointment to meet with you to provide
                        further help.
                        <br><br>
                        <ul class="pl-5 list-unstyled">
                            <li class="mb-3"><a target="_blank"
                                                href="https://reache.xeoscript.com/pdf/reache-pdp-template-2014.docx"
                                                class="text-danger" download>Reache PDP Template</a>(doctors please use
                                this one)
                            </li>
                            <li class="mb-3"><a href="https://reache.xeoscript.com/pdf/reache-pdp-example-2014.docx"
                                                class="text-danger" download>Reache PDP Example</a></li>
                            <li class="mb-3"><a
                                href="https://reache.xeoscript.com/pdf/understanding-the-pdp-2014.pdf"
                                class="text-danger" download>Understanding the PDP</a></li>
                        </ul>
                    </article>
                    <article class="mb-5">
                        <h3 class="mb-3 text-uppercase">CURRICULUM VITAE (CV)</h3>
                        The traditional way of presenting your professional background!
                        <br><br>
                        Many job applications now have an online application form, but a CV can still be useful and is
                        something to be proud of!
                        <br><br>
                        <ul class="pl-5 list-unstyled">
                            <li class="mb-3"><a target="_blank"
                                                href="https://reache.xeoscript.com/pdf/curriculum-vitae-useful-notes-at-jan-2011.docx"
                                                class="text-danger">Curriculum Vitae useful
                                notes</a></li>
                            <li class="mb-3"><a target="_blank"
                                                href="https://reache.xeoscript.com/pdf/curriculum-vitae-blank-at-jan-2011.docx"
                                                class="text-danger" download>Curriculum Vitae suggested
                                structure</a></li>
                        </ul>
                    </article>
                    <share-this></share-this>
                </div>
                <div class="col-12 col-lg-4">
                    <location-and-info></location-and-info>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import LocationAndInfo from './components/support-pages/LocationAndInfo.vue';
// import JoiningForm from './components/support-pages/JoiningForm.vue';

export default {
    components: {
        LocationAndInfo
    }
};
</script>
