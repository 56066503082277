<template>
    <div>
        <my-schedule/>
    </div>
</template>

<script>
import MySchedule from '../Components/MySchedule';
export default {
    name: 'MemberHome',
    components: { MySchedule }
};
</script>

<style scoped>

</style>
