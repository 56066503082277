<template>
    <custom-vue-table class="bs-4 m-3 c-table" title="Our Policies" :fields="fields" :url="listUrl" :per-page="10"
                      ref="table">
        <template #is_published="{rowData}">
            <span v-if="rowData.is_published==='1'">Yes</span>
            <span v-else>No</span>
        </template>
        <template #published_date="{rowData}">
            <span v-if="rowData.published_date">{{ rowData.published_date }}</span>
            <span v-else>Not Published</span>
        </template>
        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <btn size="xs" text="View" @click="viewDetails(rowData)"/>
            </div>
        </template>
    </custom-vue-table>
</template>

<script>
import urls from '@/data/urls';

export default {
    name: 'PolicyAddList',
    data () {
        return {
            loading: false,
            listUrl: urls.member.policy.list,
            fields: [
                {
                    name: 'id',
                    sortField: 'id',
                    title: 'ID'
                }, {
                    name: 'name',
                    sortField: 'name',
                    title: 'Policies Name'
                },
                {
                    name: 'created_date',
                    sortField: 'created_date',
                    title: 'Created Date'
                },
                {
                    name: '__slot:published_date',
                    sortField: 'published_date',
                    title: 'Published Date'
                },
                {
                    name: '__slot:is_published',
                    sortField: 'is_published',
                    title: 'Published'
                },
                {
                    name: '__slot:actions',
                    title: '',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right'
                }
            ]
        };
    },
    methods: {
        viewDetails (item) {
            // this.$router.push('/admin/policy/' + item.id + '/details/');
            window.open(item.file);
        }
    }
};
</script>

<style scoped>

</style>
