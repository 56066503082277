<template>
    <div>
        <AdminHome/>
        <div class="p-0 bs-4 mt-3 mb-3">
            <div class="fl-te-c p-3 mb-3 bb-1">
                <h4 class="mb-3">Add Attendance</h4>
                <btn text="Submit" size="sm"/>
            </div>
            <div class="row pl-3 pt-3 pr-3">
                <div class="col-4">
                    <validated-date-picker label="Date" class="text-2" name="Date" placeholder="Select Date" v-model="date"/>
                </div>
                <div class="col-4">
                    <validated-ajax-vue-select label="Batch" name="Batch" v-model="model.batch"
                                               :rules="{required : true}" :url="batchOptions"
                                               :disabled="loading"/>
                </div>
            </div>
            <simple-table class="p-3 c-table" :data="attendance" :fields="fields" ref="table">
                <template #actions="{rowData}">
                    <div class="btn-group-tight">
                        <btn text="Present" color="success" size="xs" @click="changeAttendance(rowData)"/>
                        <btn text="Absent" color="danger" size="xs" @click="changeAttendance(rowData)"/>
                    </div>
                </template>
            </simple-table>
        </div>
    </div>
</template>

<script>
import AdminHome from '../../../Dashboards/CustomHomePages/AdminHome';
export default {
    name: 'AttendanceComponent',
    components: { AdminHome },
    data () {
        return {
            attendance: [
                {
                    id: 939,
                    name: 'October 28 2021',
                    date: 'November 02 2021'
                },
                {
                    id: 936,
                    name: 'October 27 2021',
                    date: 'November 02 2021'
                },
                {
                    id: 933,
                    name: 'October 27 2021',
                    date: 'November 02 2021'
                },
                {
                    id: 931,
                    name: 'October 27 2021',
                    date: 'November 02 2021'
                },
                {
                    id: 928,
                    name: 'October 26 2021',
                    date: 'November 02 2021'
                },
                {
                    id: 922,
                    name: 'October 26 2021',
                    date: 'November 02 2021'
                },
                {
                    id: 921,
                    name: 'October 26 2021',
                    date: 'November 02 2021'
                },
                {
                    id: 914,
                    name: 'October 26 2021',
                    date: 'November 02 2021'
                },
                {
                    id: 909,
                    name: 'October 26 2021',
                    date: 'November 02 2021'
                },
                {
                    id: 907,
                    name: 'October 26 2021',
                    date: 'November 02 2021'
                }
            ],
            fields: [
                {
                    name: 'id',
                    sortField: 'id',
                    title: 'ID'
                }, {
                    name: 'name',
                    sortField: 'name',
                    title: 'Name'
                },
                {
                    name: 'email',
                    sortField: 'email',
                    title: 'Email'
                },
                {
                    name: '__slot:actions',
                    title: '',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right'
                }
            ],
            branchOptions: [
                { value: 'Clinical', label: 'Clinical' },
                { value: 'OET', label: 'OET' },
                { value: 'Volunteer', label: 'Volunteer' }
            ],
            loading: false,
            model: '',
            batchOptions: ''
        };
    },
    methods: {
        changeAttendance () {

        }
    }
};
</script>

<style scoped>

</style>
