<template>
    <div>
        <AdminHome/>
        <custom-vue-table  class="bs-4 m-3 c-table" title="Teaching Links" :fields="fields" :url="listUrl" :per-page="10"
                           ref="table">
            <template #buttons>
                <btn icon="fa fa-plus" size="sm" @click="$refs.addModal.show()" text="Add"/>
            </template>
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <btn size="xs" text="View" @click="viewDetails(rowData.id)"/>
                    <btn size="xs" color="danger" text="Delete" @click="setDelete(rowData.id)"/>
                </div>
            </template>

            <template #footer>
                <modal  class="c-modal no-close"  title="Add Link" ref="addModal" width="40r" header-color="">
                    <s-form @submit="submitClicked" ref="form">
                        <div class="row">
                            <div class="col">
                                <validated-vue-select label="Branch" name="Branch" v-model="model.branch"
                                                      :rules="{required : true}" :url="branchOptions"
                                                      :disabled="loading"/>
                            </div>
                            <div class="col">
                                <validated-ajax-vue-select label="Batch" name="Batch" v-model="model.batch"
                                                           :rules="{required : true}" :url="batchOptions"
                                                           :disabled="loading"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <validated-vue-select label="Role" name="Role" v-model="model.branch"
                                                      :rules="{required : true}" :options="branchOptions"
                                                      :disabled="loading"/>
                            </div>
                            <div class="col">
                                <validated-input label="Title" name="Title" v-model="model.title"
                                                 :rules="{required : true}" :disabled="loading"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <validated-input label="File" name="File" v-model="model.file"
                                                 :rules="{required : true}" :disabled="loading"/>
                            </div>
                            <div class="col">
                                <validated-date-picker label="Date" name="Date" v-model="model.date"
                                                       :disabled="loading" :rules="{required : true}"/>
                            </div>
                        </div>
                        <btn size="sm" text="Save" :loading="loading" loading-text="Saving..."/>
                    </s-form>
                </modal>

                <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteSuccess">
                    <p>You are about to delete the link. Are you sure?</p>
                    <template #loading>
                        <delete-animation/>
                        Please wait while we delete the link.
                    </template>
                </delete-modal>
            </template>

        </custom-vue-table>
    </div>
</template>

<script>
import urls from '@/data/urls';
import axios from 'secure-axios';
import AdminHome from '../../../Dashboards/CustomHomePages/AdminHome';

export default {
    name: 'TeachingLinks',
    components: { AdminHome },
    data () {
        return {
            branchOptions: [
                { value: 'Clinical', label: 'Clinical' },
                { value: 'OET', label: 'OET' },
                { value: 'Volunteer', label: 'Volunteer' }
            ],
            batchOptions: '',
            loading: false,
            listUrl: '',
            addUrl: '',
            deleteUrl: '',
            deletingItem: {
                id: ''
            },
            fields: [
                {
                    name: 'title',
                    sortField: 'title',
                    title: 'Title'
                },
                {
                    name: 'link',
                    sortField: 'link',
                    title: 'Link'
                },
                {
                    name: 'date',
                    sortField: 'date',
                    title: 'Date'
                },
                {
                    name: 'created_by',
                    sortField: 'Added By',
                    title: 'Added By'
                },
                {
                    name: '__slot:actions',
                    title: '',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right'
                }
            ],
            model: {}
        };
    },
    methods: {
        setDelete (item) {
            this.deletingItem.id = item.id;
            this.$refs.deleteModal.show();
        },
        deleteSuccess () {
            this.$refs.deleteModal.close();
            this.deletingItem.id = '';
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        viewDetails (id) {
            this.$router.push('/staff/teaching/' + id + '/details/');
        },
        async submitClicked () {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.admin.policy.add, that.model);
            const json = response.data;
            if (json.error === false) {
                that.formSuccess();
                that.loading = false;
            } else {
                that.formError(json);
                that.loading = false;
            }
            that.loading = false;
        },
        formSuccess () {
            this.$refs.addModal.close();
            this.$notify('Saved Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        formError (json) {
            this.$refs.form.setErrors(json.errors);
            this.$notify('Please fix the issue.', 'Error', {
                type: 'danger'
            });
        }
    }
};
</script>

<style scoped>

</style>
