<template>
    <div>
        <AdminHome/>
        <div class="p-0 bs-4 of-h">
            <div class="fl-te-c p-3">
                <h5>Reassign Batch</h5>
                <validated-input name="search" placeholder="Search" v-model="search"/>
            </div>
            <div class="fl-eq">
                <div class="w-65r">
                    <h5 class="p-3 mb-0" style="background: #81b0e2;">Batch</h5>
                    <div class="fl-eq">
                        <div>
                            <perfect-scrollbar id="scroll">
                                <div v-for="i in 20" v-bind:style="selected(i)" @click="batchSelected(i)" v-bind:key="i"
                                     class="p-2 pl-4 bb-1 br-1">Batch {{ i }}
                                </div>
                            </perfect-scrollbar>
                        </div>
                        <div>
                            <perfect-scrollbar id="scroll1">
                                <div v-for="i in 10" v-bind:style="selectMember(i)" @click="memberSelected(i)"
                                     v-bind:key="i"
                                     class="p-2 pl-4 bb-1 br-1">Name {{ i }}
                                </div>
                            </perfect-scrollbar>
                        </div>
                    </div>
                </div>
                <div style="background: #f3f7fc;" class="w-30r">
                    <div class="p-4">
                        <s-form ref="form">
                            <validated-input name="name" label="Student name" v-model="model.name"
                                             :rules="{required : true}"/>
                            <validated-ajax-vue-select :url="batchOptions" name="batch1" label="Current Batch"
                                                       v-model="model.batch1"
                                                       :rules="{required : true}"/>
                            <validated-ajax-vue-select :url="batchOptions" name="batch2" label="New Batch"
                                                       v-model="model.batch2"
                                                       :rules="{required : true}"/>
                            <btn size="sm" text="Submit" :loading="loading"/>
                        </s-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import AdminHome from '../../Dashboards/CustomHomePages/AdminHome';

export default {
    name: 'ReassignBatch',
    components: { AdminHome, PerfectScrollbar },
    data () {
        return {
            scrollHeight: '500px',
            loading: false,
            search: '',
            selectedBatch: '',
            selectedMember: '',
            batchOptions: '',
            model: {
                name: ''
            }
        };
    },
    updated () {
        this.updateScroll();
    },
    methods: {
        updateScroll () {
            const element = document.getElementById('scroll');
            element.scrollTop = element.scrollHeight;
            const element1 = document.getElementById('scroll1');
            element1.scrollTop = element1.scrollHeight;
        },
        selected (i) {
            if (this.selectedBatch === i) {
                const data = { background: '#d9e7f7' };
                return data;
            }
        },
        batchSelected (i) {
            this.selectedBatch = i;
        },
        selectMember (i) {
            if (this.selectedMember === i) {
                const data = { background: '#d9e7f7' };
                return data;
            }
        },
        memberSelected (i) {
            this.selectedMember = i;
        }
    }
};
</script>

<style>
.ps {
    height: 500px;
}

.ps__rail-x {
    display: none !important;
}
</style>
