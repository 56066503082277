<template>
    <div class="mt-3">
        <div class="fl-eq mb-4">
            <div class="card w-25r bs-4">
                <h4 class="mb-3">My Profile</h4>
                <img style="height: 17rem;" alt="Profile Image" src="../../../assets/img/banner.png">
                <label>Name</label>
                <div class="form-control round-1">Sanjay S Kumar</div>
                <label>Email ID</label>
                <div class="form-control round-1 text-muted">sanjay@mail.com</div>
            </div>
            <basic-info/>
        </div>
        <qualification-info class="mb-4"/>
        <reache-info class="mt-0"/>
        <course-details/>
        <profile-history/>
    </div>
</template>

<script>
import BasicInfo from './ProfileDetailsComponent/BasicInfo';
import QualificationInfo from './ProfileDetailsComponent/QualificationInfo';
import ReacheInfo from './ProfileDetailsComponent/ReacheInfo';
import CourseDetails from './ProfileDetailsComponent/CourseDetails';
import ProfileHistory from './ProfileDetailsComponent/ProfileHistoryForm';

export default {
    name: 'memberDetails',
    components: { ProfileHistory, CourseDetails, ReacheInfo, QualificationInfo, BasicInfo }
};
</script>

<style scoped>

</style>
