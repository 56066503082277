<template>
    <div>
        <div class="fl-eq mb-3 text-center font-weight-bold dash-menu px-5 mb-5">
            <router-link to="/director-function/master-data/" class="card bs-4">Director Functional Division
                <ul class="dash-sub-menu">
                    <li>
                        <router-link class="d-block" to="/director-function/master-data/">
                            Master Data
                        </router-link>
                    </li>
                    <li>
                        <router-link class="d-block" to="/director-function/user-management/">
                            User Management
                        </router-link>
                    </li>
                    <li>
                        <router-link class="d-block" to="/staff/file-management/">
                            File Management
                        </router-link>
                    </li>
                </ul>
            </router-link>
            <router-link to="/enrollment-function/statistics/" class="card bs-4 mt-0 ml-4">Enrollment Functional Division
                <ul class="dash-sub-menu">
                    <li>
                        <router-link class="d-block" to="/enrollment-function/pending-member-management/">
                            Pending Members
                        </router-link>
                    </li>
                    <li>
                        <router-link class="d-block" to="/enrollment-function/user-management/">
                            Member Management
                        </router-link>
                    </li>
                    <li>
                        <router-link class="d-block" to="/enrollment-function/statistics/">
                            Statistics
                        </router-link>
                    </li>
                </ul>
            </router-link>
            <router-link to="/clinical-function/dash/" class="card bs-4 mt-0 ml-4">Clinical Functional Division
                <ul class="dash-sub-menu">
                    <li>
                        <router-link class="d-block" to="/clinical/member-management/">
                            Member Management
                        </router-link>
                    </li>
                    <li>
                        <router-link class="d-block" to="/clinical/teaching-links/">
                            Teaching Links
                        </router-link>
                    </li>
                    <li>
                        <router-link class="d-block" to="/clinical/time-table/">
                            Time Table
                        </router-link>
                    </li>
                    <li>
                        <router-link class="d-block" to="/clinical/attendance/">
                            Attendance
                        </router-link>
                    </li>
                    <li>
                        <router-link class="d-block" to="/clinical/reassign-batch/">
                            Reassign Batch
                        </router-link>
                    </li>
                </ul>
            </router-link>
            <router-link to="/oet-function/dash/" class="card bs-4 mt-0 ml-4">OET Tutor Functional Division
                <ul class="dash-sub-menu">
                    <li>
                        <router-link class="d-block" to="/oet/member-management/">
                            Member Management
                        </router-link>
                    </li>
                    <li>
                        <router-link class="d-block" to="/oet/teaching-links/">
                            Teaching Links
                        </router-link>
                    </li>
                    <li>
                        <router-link class="d-block" to="/oet/time-table/">
                            Time Table
                        </router-link>
                    </li>
                    <li>
                        <router-link class="d-block" to="/oet/attendance/">
                            Attendance
                        </router-link>
                    </li>
                    <li>
                        <router-link class="d-block" to="/oet/reassign-batch/">
                            Reassign Batch
                        </router-link>
                    </li>
                    <li>
                        <router-link class="d-block" to="/oet/alumni-details-list/">
                            Alumni Details
                        </router-link>
                    </li>
                </ul>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AdminHome'
};
</script>
<style lang="scss">
.admin-tab .tabs-header li {
    flex-grow: 1;
    text-align: center;
}

.dash-menu {
    margin-top: -2rem !important;
    margin: 0 -3rem;
    background-color: var(--color-primary) !important;

    .card {
        border-radius: 0;
        background-color: var(--color-primary) !important;
        color: white;
        box-shadow: none;
        line-height: 4rem;
        cursor: pointer;
        position: relative;

        &.router-link-exact-active {
            background-color: #3185df !important;
        }

        .dash-sub-menu {
            position: absolute;
            top: 90px;
            left: 0;
            right: 0;
            padding-top: 15px;
            visibility: hidden;
            opacity: 0;
            z-index: 2;
            transition: visibility 0.2s linear, opacity 0.2s linear;
            list-style: none;

            li {
                background-color: var(--color-primary) !important;
                transition: 0.3s;

                &:hover {
                    background-color: #3185df !important;
                }

                .router-link-exact-active {
                    background-color: #3185df !important;
                }
            }
        }

        &:hover {
            .dash-sub-menu {
                visibility: visible;
                opacity: 1;
            }
        }
    }

}
</style>
