<template>
    <div class="card mt-3 p-4 bs-4">
        <h4 class="mb-3">Course Details</h4>
        <div class="row">
            <div class="col">
                <label>Branch</label>
                <div class="form-control round-1 text-muted">Computer Dept</div>
            </div>
            <div class="col">
                <label>Course</label>
                <div class="form-control round-1 text-muted">computer</div>
            </div>
            <div class="col">
                <label>Batch</label>
                <div class="form-control round-1 text-muted">batch</div>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label>Supervisor Name</label>
                <div class="form-control round-1 text-muted">Sanjay</div>
            </div>
            <div class="col-4">
                <label>Tutor Name</label>
                <div class="form-control round-1 text-muted">Rahul</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CourseDetails'
};
</script>

<style scoped>

</style>
