<template>
    <div class="card bs-4">
        <h4 class="mb-3">Qualification Info</h4>
        <div class="row">
            <div class="col">
                <label>Country of primary medical qualification</label>
                <div class="form-control round-1 text-muted">India</div>
            </div>
            <div class="col">
                <label>Professional Qualification</label>
                <div class="form-control round-1 text-muted">MD</div>
            </div>
            <div class="col">
                <label>Additional Educational Needs</label>
                <div class="form-control round-1 text-muted">Nil</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>Disability</label>
                <div class="form-control round-1 text-muted">None</div>
            </div>
            <div class="col">
                <label>Ethnicity</label>
                <div class="form-control round-1 text-muted">Asian</div>
            </div>
            <div class="col">
                <label>Caring Responsibility</label>
                <div class="form-control round-1 text-muted">Nil</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'QualificationInfo'
};
</script>
