<template>
    <div>
        <AdminHome/>
        <custom-vue-table class="bs-4 mt-3 c-table" title="Members Management" :fields="fields" :url="listUrl"
                          :per-page="10" ref="table">
            <template #buttons>
                <drop-btn text="Filters" icon="fa fa-filter" size="sm" dropdown-width="30r">
                    <div class="m-3">
                        <validated-vue-select label="Status" name="Status" v-model="status" :options="statusOptions"
                                              :disabled="loading"/>
                    </div>
                </drop-btn>
                <btn size="sm" class="ml-2" icon="fa fa-eye" text="Details"
                     @click="$router.push('/clinical/member/1/details/')"/>
            </template>
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <btn size="xs" text="View" @click="viewDetails(rowData)"/>
                </div>
            </template>
        </custom-vue-table>
    </div>
</template>

<script>
import urls from '@/data/urls';
import AdminHome from '../../Dashboards/CustomHomePages/AdminHome';

export default {
    name: 'MembersManagement',
    components: { AdminHome },
    data () {
        return {
            status: '',
            loading: false,
            listUrl: urls.admin.masterData.pendingMembers.list,
            statusOptions: [
                { value: 'Active', label: 'Active' },
                { value: 'Suspended', label: 'Suspended' }
            ],
            fields: [
                {
                    name: 'name',
                    sortField: 'name',
                    title: 'Name'
                },
                {
                    name: 'email',
                    sortField: 'email',
                    title: 'Email'
                },
                {
                    name: 'type',
                    sortField: 'type',
                    title: 'Type'
                },
                {
                    name: '__slot:actions',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right',
                    title: 'Actions'
                }
            ],
            model: {
                name: ''
            }
        };
    },
    methods: {
        viewDetails () {
            this.$router.push('');
        }
    }
};
</script>

<style scoped>

</style>
