<template>
    <div class="card mt-0 w-70r ml-4 bs-4">
        <div class="fl-te-c mb-3">
            <h4 class="mb-3">Basic Info</h4>
            <div>
                <btn size="sm" text="History" @click="$router.push('/clinical/member-profile/:id/history/')"/>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>DOB</label>
                <div class="form-control round-1 text-muted">04-10-1999</div>
            </div>
            <div class="col">
                <label>Age</label>
                <div class="form-control round-1 text-muted">22</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>Address</label>
                <div class="form-control round-1 text-muted">Thaiparambil</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>Gender</label>
                <div class="form-control round-1 text-muted">Male</div>
            </div>
            <div class="col">
                <label>Religion</label>
                <div class="form-control round-1 text-muted">Hindu</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>Country of Origin</label>
                <div class="form-control round-1 text-muted">India</div>
            </div>
            <div class="col">
                <label>Language Spoken</label>
                <div class="form-control round-1 text-muted">English, Malayalam, Hindi</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>Dependents</label>
                <div class="form-control round-1 text-muted">03</div>
            </div>
            <div class="col">
                <label>Immigration Status</label>
                <div class="form-control round-1 text-muted">Residency</div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <label>Date of Arrival in UK</label>
                <div class="form-control round-1 text-muted">01-10-2021</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BasicInfo'
};
</script>

<style scoped>

</style>
