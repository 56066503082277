<template>
    <main>
        <div class="container">
            <div class="row py-5 px-0">
                <div class="col-12 col-lg-8">
                    <h2 class="text-uppercase poppins-bold mb-4">SELF-MANAGEMENT & STUDY SKILLS</h2>
                    <article class="mb-5">
                        Below are links to documents compiled by REACHE North West members in early 2014, with thanks to
                        Alison Featherstone, Volunteer English Tutor.
                        <br><br>
                        <a href="https://reache.xeoscript.com/pdf/plab1-self-management-summary-of-students-contributions-draft-february-2014-and-final-version-april-2014.docx"
                           class="text-danger" download>Self-management ideas</a>
                        <br><br>
                        <a href="https://reache.xeoscript.com/pdf/plab1-study-skills-students-suggestions-draft-february-2014-updated-april-2014.docx" class="text-danger" download>Study Skills</a>
                        <br><br>
                        Strength and resilience of mind and body is an important starting point for effective study and
                        examination preparation. Not all the things written here will be appropriate for everybody, but
                        the suggestions in general are worth considering. Everybody can benefit from improved personal
                        management strategies!
                    </article>
                    <!--IMPROVE YOUR IT SKILLS-->
                    <article class="mb-5">
                        <h3 class="text-uppercase mb-4">IMPROVE YOUR IT SKILLS</h3>
                        Free programmes to learn typing: <a href="http://www.meditype.org/" target="_blank"
                                                            class="text-danger -underline">Meditype</a>;
                        <a href="https://portableapps.com/apps/education/typefaster_portable" target="_blank"
                           class="text-danger">Type Faster</a>;
                        <a href="https://portableapps.com/apps/education/tipp10_portable" target="_blank"
                           class="text-danger">TIPP10</a>
                        <br><br>
                        Learn how to check whether a web resource is trustworthy: <a
                        href="http://www.vtstutorials.ac.uk/detective/index.html" target="_blank" class="text-danger">Internet
                        Detective</a>
                        <br><br>
                        Learn how to check whether a health information article is reliable: <a
                        href="http://www.discern.org.uk/index.php" target="_blank"
                        class="text-danger">Discern</a>
                    </article>
                </div>
                <div class="col-12 col-lg-4">
                    <location-and-info></location-and-info>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import LocationAndInfo from './components/support-pages/LocationAndInfo.vue';

export default {
    components: {
        LocationAndInfo
    }
};
</script>
