<template>
    <div>
        <AdminHome/>
        <custom-vue-table class="bs-4 m-3 c-table" title="Folders" :fields="fields" :url="listUrl" :per-page="10"
                          ref="table">
            <template #buttons>
                <btn icon="fa fa-plus" size="sm" @click="showAddModal" text="Add"/>
            </template>
            <template #actions="{rowData}">
                <div class="btn-group">
                    <btn size="xs" color="success" icon="fa fa-download" @click="downloadFolder(rowData.id)"/>
                    <btn size="xs" color="warning" icon="fa fa-pencil" @click="editFolder(rowData)"/>
                </div>
            </template>
            <template #updated_date="{rowData}">
                <span v-if="rowData.updated_date"/>
                <span v-else>{{rowData.created_date}}</span>
            </template>

            <template #footer>

                <modal class="c-modal no-close" title="Add Folder" ref="addModal" width="30r" header-color="">
                    <s-form @submit="submitClicked('add')" ref="form">
                        <div class="row">
                            <div class="col">
                                <validated-input label="Folder Name" name="Folder Name" v-model="model.name"
                                                 :rules="{required : true}" :disabled="loading"/>
                            </div>
                        </div>
                        <btn size="sm" text="Save" :loading="loading" loading-text="Saving..."/>
                    </s-form>
                </modal>
                <modal class="c-modal no-close" title="Edit Folder" ref="editModal" width="30r" header-color="">
                    <s-form @submit="submitClicked('edit')" ref="form">
                        <div class="row">
                            <div class="col">
                                <validated-input label="Folder Name" name="Folder Name" v-model="model.name"
                                                 :rules="{required : true}" :disabled="loading"/>
                            </div>
                        </div>
                        <btn size="sm" text="Update" :loading="loading" loading-text="Updating..."/>
                    </s-form>
                </modal>
                <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteSuccess">
                    <p>You are about to delete the Folder. Are you sure?</p>
                    <template #loading>
                        <delete-animation/>
                        Please wait while we delete the Folder.
                    </template>
                </delete-modal>
            </template>

        </custom-vue-table>
    </div>
</template>

<script>

import AdminHome from '../../../Dashboards/CustomHomePages/AdminHome';
import urls from '../../../../data/urls';
import axios from 'secure-axios';
export default {
    name: 'FolderList',
    components: { AdminHome },
    data () {
        return {
            loading: false,
            action: '',
            fields: [
                {
                    name: 'name',
                    sortField: 'name',
                    title: 'Title'
                },
                {
                    name: '__slot:updated_date',
                    sortField: 'updated_date',
                    title: 'Updated Date'
                },
                {
                    name: '__slot:actions',
                    title: '',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right'
                }
            ],
            listUrl: urls.admin.directorFunction.fileManagement.folder.list,
            deleteUrl: urls.admin.directorFunction.fileManagement.folder.delete,
            deletingItem: {
                id: ''
            },
            model: {
                name: ''
            }
        };
    },
    methods: {
        async submitClicked (action) {
            const that = this;
            that.loading = true;
            if (action === 'add') {
                that.action = urls.admin.directorFunction.fileManagement.folder.add;
            } else {
                that.action = urls.admin.directorFunction.fileManagement.folder.edit;
            }
            const response = await axios.form(that.action, that.model);
            const json = response.data;
            if (json.error === false) {
                that.formSuccess();
                that.loading = false;
            } else {
                that.formError(json);
                that.loading = false;
            }
            that.loading = false;
        },
        formSuccess () {
            this.$refs.addModal.close();
            this.$refs.editModal.close();
            this.$notify('Saved Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        formError (json) {
            this.$refs.form.setErrors(json.errors);
            this.$notify('Please fix the issue.', 'Error', {
                type: 'danger'
            });
        },
        setDelete (item) {
            this.deletingItem.id = item.id;
            this.$refs.deleteModal.show();
        },
        deleteSuccess () {
            this.$refs.deleteModal.close();
            this.deletingItem.id = '';
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
        },
        editFolder (item) {
            this.model = { ...item };
            this.$refs.editModal.show();
        },
        showAddModal () {
            this.model = {};
            this.$refs.addModal.show();
        },
        async downloadFolder () {
        }
    }
};
</script>

<style>
.ps {
    height: 500px;
}

.ps__rail-x {
    display: none !important;
}
</style>
