<template>
    <main>
        <div class="container">
            <div class="row py-5 px-0">
                <div class="col-12 col-lg-8">
                    <h2 class="text-uppercase poppins-bold mb-4">Getting To Reache</h2>
                    <article class="mb-5">
                        <!--                        <div class="show-images fl-x mb-3">-->
                        <!--                            <img src="../assets/img/bussmall.gif" alt="">-->
                        <!--                            <img src="../assets/img/metrolinksmall.gif" alt="">-->

                        <!--                        </div>-->
                        <div class="row">
                            <div class="col-6">
                                <img src="../assets/img/bussmall.gif" alt="">
                            </div>
                            <div class="col-6">
                                <img src="../assets/img/metrolinksmall.gif" alt="">
                            </div>
                        </div>
                        REACHE North West has a centre in Salford Royal NHS Foundation Hospital Trust, Stott Lane,
                        Salford, M6 8HD. The nearest rail and metrolink (tram) stations are at Eccles, about fifteen
                        minutes walk from the Hospital. There is more information in getting to the hospital on the
                        <a target="_blank" href="https://www.srft.nhs.uk/useful-information/how-to-get-to-the-hospital/"
                           class="text-danger">Trust web site</a>
                        <br><br>
                        At the hospital, REACHE North West is on the First Floor of the Mayo Building. The offices are
                        within the library space.
                        <br><br>
                        The buses which pass Salford Royal Hospital are numbers 10, 13, 67, 100 (frequent) and 27, 29,
                        33, 34, 52, 63, 65, 68, 70, 484 (less frequent).
                        <div class="lg-img-contain fl-y fl-j-c fl-a-s py-3">
                            <a href="https://reache.xeoscript.com/static/img/hospital-map1.e9d0f11b.jpg" target="_blank">
                                <img src="../assets/img/hospital-map1.jpg" alt="Map of Salford Royal" class="mb-3">
                            </a>
                            <span class="text-left">Map of Salford Royal - click to enlarge</span>
                        </div>
                        <br>
                        <br>
                        Photos on this page (c) <a target="_blank" href="" class="text-danger">FreeFoto.com</a>
                    </article>
                    <share-this></share-this>
                </div>
                <div class="col-12 col-lg-4">
                    <location-and-info></location-and-info>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import LocationAndInfo from './components/support-pages/LocationAndInfo.vue';
// import JoiningForm from './components/support-pages/JoiningForm.vue';

export default {
    components: {
        LocationAndInfo
    }
};
</script>
<style lang="scss" scoped>

</style>
